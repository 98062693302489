<div class='page'>
    <div class='leftNav'>
        <side-nav #sideNav>
            <div main>
                <ul>
                    <li *ngFor="let item of sideNav.navdata">
                            <a *ngIf="item.isClick" [class.active]="isActive(item.label.toLowerCase())" 
                            (click)="sideNav.openSub(item.label.toLowerCase())"  [class.disabled]="item?.disable">
                                <img src="assets/icons/{{item.icon + (isActive(item.label.toLowerCase()) ? '-selected' : '')}}.svg" />
                                <span>{{item.label}}</span>
                                <i class="material-icons" [class.active]="sideNav.isIconActive(item.label.toLowerCase())">chevron_right</i>
                            </a>
                            <a *ngIf="!item.isClick" routerLink="{{item.link}}" routerLinkActive="active" 
                                #rla="routerLinkActive" (click)="sideNav.showHideSub=true? sideNav.closeSub(item.label.toLowerCase()): false"   [class.disabled]="item?.disable">
                                    <img src="assets/icons/{{item.icon + (rla.isActive ? '-selected' : '')}}.svg" />
                                    <span>{{item.label}}</span>
                            </a>
                       
                    </li>
                </ul>
            </div>
            <div sub>
             <!-- <div sub *ngIf="programs$ | async as programs">
                <ul *ngIf="sideNav.programs && !sideNav.Reporting && !sideNav.Settings">
                    <li>
                        <a routerLink="/admin/programs" routerLinkActive="active" (click)="sideNav.closeSub()">Programs Dashboard</a>
                    </li>
                    <div eventTitle><span>Upcoming Programs</span></div>
                            <li *ngFor="let item of programs">
                        <a routerLink="/admin/programs/{{item.id}}" routerLinkActive="active" (click)="sideNav.closeSub()">
                            <span [ngClass]="{'active': item?.name==sideNav.isActiveClass && sideNav.isActive}">{{item?.name}}</span>
                        </a>
                        <span>{{item.startDate |dateFormat : 'mm-ddd-yy'}} - {{item.endDate  |dateFormat : 'mm-ddd-yy'}}</span>
                    </li>
                </ul> -->

                <ul *ngIf="!sideNav.programs && sideNav.reporting && !sideNav.settings">
                    <!-- <li><a routerLink="/admin/reporting" routerLinkActive="active">Reporting Dashboard</a></li>
                    <li><a>Event Dashboard</a></li>
                    <li><a>Flights</a></li>
                    <li><a>Hotels</a></li>
                    <li><a>Class Attendance</a></li>
                    <li><a>Classroom Assignments</a></li>
                    <li><a>Wellbeing Check</a></li> -->
                    <li (click)="sideNav.closeSub()"><a routerLink="/admin/reports/arrival">Flight Arrival</a></li>
                    <li (click)="sideNav.closeSub()"><a routerLink="/admin/reports/departure">Flight Departure</a></li>
                </ul>

                <ul *ngIf="!sideNav.programs && !sideNav.reporting && sideNav.settings">
                    <!-- <li><a routerLink="/admin/settings" routerLinkActive="active">Settings Home</a></li>
                    <li><a>User Access</a></li> -->
                    <li  (click)="sideNav.closeSub()"><a routerLink="/admin/settings/notification">Ad-Hoc Notifications</a></li>
                    <li  (click)="sideNav.closeSub()"><a routerLink="/admin/settings/houseandwellbeingevents">House & Well-being events</a></li>
                    <li *ngIf="displayAppeVersion" (click)="sideNav.closeSub()"><a routerLink="/admin/settings/systemSettings">System Settings</a></li>
                    <!-- <li><a>Global Mobile Settings</a></li>
                    <li><a>Content Management</a></li> -->
                </ul>
            </div>
        </side-nav>
    </div>
    <div class='center'>
        <div header>
            <span class="spacer"></span>
            <div class="user-group">
                <span><!--<i class="material-icons">search</i>--></span>
                <span><!--<i class="material-icons"> notifications </i><span></span>--></span>
                <!-- <span class="x">
                    <span class="y">
                        <span></span>
                    </span>
                </span> --> 

                <span class="user">{{(user$ | async)?.firstName}} {{(user$ | async)?.lastName}}</span>
                <span class="caret" (click)="toggleUserRole()"></span>
                <!--<div class="profile-dropdown" *ngIf="isUserShow">
                    <span [attr.isDisabled]="isDisabled=='journey'" *ngIf="showHideParticipant('journey')" (click)="routAdmin('journey')">Journey Portal</span>
                    <span (click)="routAdmin('facilitator')" [attr.isDisabled]="isDisabled=='facilitator'" *ngIf="showHideFacilitator('facilitator')">Facilitator</span>
                    <span [attr.isDisabled]="true" (click)="routAdmin('admin')">Admin Portal</span>
                     <span (click)="routAdmin('signOut')">Sign Out</span>
                </div> -->
                
               <div class="profile-dropdown" *ngIf="isUserShow">
                        <span [attr.isDisabled]="isDisabled === 'journey'" *ngIf="showHideParticipant()" (click)="routAdmin('journey')">Journey Portal</span>
                        <span [attr.isDisabled]="isDisabled === 'facilitator'" (click)="routAdmin('facilitator')" *ngIf="isFacilitator || isAdmin">Facilitator</span>
                        <span [attr.isDisabled]="isDisabled === 'admin'" (click)="routAdmin('admin')" *ngIf="isAdmin">Admin Portal</span>
                         <span (click)="routAdmin('signOut')">Sign Out</span>
                    </div>
            </div>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>