import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { SideNavComponent } from './side-nav/side-nav.component';
import { TabsComponent } from './tabs/tabs.component';
import { GetDays } from './pipes/getdays.pipe';
import { OrderByPipe } from './pipes/orderby.pipe';
import { SearchFilterPipe } from './pipes/searchfilter.pipe';
import { StickyTableHeaderDirective } from './sticky-table-header/sticky-table-header.directive';
import { NotificationPipe } from './pipes/notifications.pipe';
import { BreadCrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { ProgramPipe } from './pipes/programs.pipe';
import { DateFormatPipe } from './pipes/dateformat.pipe';
import { ClassStatusPipe } from './pipes/classstatus.pipe';
import { ClassColorPipe } from './pipes/classcolor.pipe';
import { TimeFormatPipe } from './pipes/timeformat.pipe';
import { StatusColorPipe } from './pipes/statuscolor.pipe';
import { StatusPipe } from './pipes/status.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { GetNights } from './pipes/getnights.pipe';
import { ClassDetailDialogComponent } from './dialog/class-detail-dialog.component';
import { ClassDetailComponent } from '../features/admin/programs/classes/class-detail/classdetail.component';
import { ClassRosterComponent } from '../features/admin/programs/classes/class-detail/class-roster/class-roster.component';
import { SaveBarComponent } from './save-bar/save-bar.component';
import { ValuesPipe } from './pipes/values.pipe';
import { DaysPipe } from './pipes/days.pipe';
import { RemainingDaysPipe } from './pipes/remainingdays.pipe';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { TimeDiff } from './pipes/timeDiff.pipe';
import { TimeTotal } from './pipes/timeTotal.pipe';
import { CardPipe } from './pipes/card.pipe';
import { DateRangePipe } from './pipes/daterange.pipe';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { ClickOutsideDirective } from './click-outide-directive/click-outside.directive';
import { TimeZoneAbbreviationsPipe } from './pipes/time-zone-abbreviations.pipe';
@NgModule({
    declarations: [
        SideNavComponent,
        TabsComponent,
        GetDays,
        OrderByPipe,
        StickyTableHeaderDirective,
        NotificationPipe,
        BreadCrumbsComponent,
        ProgramPipe,
        DateFormatPipe,
        ClassStatusPipe,
        ClassColorPipe,
        TimeFormatPipe,
        StatusColorPipe,
        StatusPipe,
        DurationPipe,
        GetNights,
        ClassDetailComponent,
        ClassRosterComponent,
        SaveBarComponent,
        ConfirmDialogComponent,
        ClassDetailDialogComponent,
        ValuesPipe,
        DaysPipe,
        RemainingDaysPipe,
        TimePickerComponent,
        TimeDiff,
        TimeTotal,
        CardPipe,
        DateRangePipe,
        SearchFilterPipe,
        ErrorMessageComponent,
        DropdownComponent,
        ClickOutsideDirective,
        TimeZoneAbbreviationsPipe
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        SideNavComponent,
        TabsComponent,
        GetDays,
        OrderByPipe,
        StickyTableHeaderDirective,
        NotificationPipe,
        BreadCrumbsComponent,
        ProgramPipe,
        DateFormatPipe,
        ClassStatusPipe,
        ClassColorPipe,
        TimeFormatPipe,
        StatusColorPipe,
        StatusPipe,
        DurationPipe,
        GetNights,
        ConfirmDialogComponent,
        ClassDetailDialogComponent,
        ClassDetailComponent,
        ClassRosterComponent,
        SaveBarComponent,
        ValuesPipe,
        DaysPipe,
        RemainingDaysPipe,
        TimePickerComponent,
        TimeDiff,
        TimeTotal,
        CardPipe,
        DateRangePipe,
        SearchFilterPipe,
        ErrorMessageComponent,
        DropdownComponent,
        ClickOutsideDirective,
        TimeZoneAbbreviationsPipe
    ]
})
export class WidgetsModule { }
