import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({ name: 'classcolor' })
export class ClassColorPipe implements PipeTransform {
    curdate:any=new Date();
    format:string;
    minsValue:number;
    transform(value:any, enddate?: any): any {
        if (value != undefined) {
            if( (moment(this.curdate).format('YYYY-MM-DD HH:mm')) > (moment(enddate).format('YYYY-MM-DD HH:mm')))
            return value='gray';
            else if(((moment(this.curdate).format('YYYY-MM-DD HH:mm')) > (moment(value).format('YYYY-MM-DD HH:mm'))) && ((moment(this.curdate).format('YYYY-MM-DD HH:mm')) < (moment(enddate).format('YYYY-MM-DD HH:mm'))))
            return value= 'active'; 
             else return value=  'alert'; 
        }
    }
}