import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './features/login/login.component';
import { LogoutComponent } from './features/logout/logout.component';
import { LoginGuard } from './services/guard/login.guard';
import { ErrorComponent } from './features/error/error.component';
import { NetworkComponent } from './features/error/network/network.component';
import { ForbiddenComponent } from './features/error/forbidden/forbidden.component';
import { PageNotFoundComponent } from './features/error/pagenotfound/pagenotfound.component';
import { NotAuthorizedComponent } from './features/error/notauthorized/notauthorized.component';
import { JourneyModule } from './features/journey/journey.module';
import { AdminModule } from './features/admin/admin.module';
import {ClassDetailDialogComponent  } from './widgets/dialog/class-detail-dialog.component';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { NgxsModule } from '@ngxs/store';
import { UserState } from './store/user.state';

const routes: Routes = [
    {
        path: '',
        component: AppComponent
    },
    {
        path: 'login',
        canActivate: [LoginGuard],
        component: LoginComponent
    },
     {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: 'error/:id',
        component: ErrorComponent
    },
    {
        path: '**',
        component: ErrorComponent
    }
];

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        LogoutComponent,
        ErrorComponent,
        NetworkComponent,
        ForbiddenComponent,
        PageNotFoundComponent,
        NotAuthorizedComponent,        
       // ClassDetailDialogComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AmplifyAuthenticatorModule,
        NgxsModule.forRoot([UserState]),
        JourneyModule,
        AdminModule,
        RouterModule.forRoot(routes, {})
    ],
    entryComponents: [ClassDetailDialogComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
