import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from '../../services/guard/admin.guard';
import { FacilitatorGuard } from '../../services/guard/facilitator.guard';
import { FlightReportGuard } from '../../services/guard/flighReport.guard';
import { WidgetsModule } from '../../widgets/widgets.module';
import { AdminComponent } from './admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ParticipantsComponent } from './participants/participants.component';
import { SettingsComponent } from './settings/settings.component';
import { NotificationsComponent } from './notification/notifications.component';
import { AdHocComponent } from './notification/ad-hoc/ad-hoc.component';
import { OverviewComponent } from './programs/overview/overview.component';
import { ProgramsComponent } from './programs/programs.component';
import { ProgramComponent } from './programs/program/program.component';
import { ClassesComponent } from './programs/classes/classes.component';
import { SessionsComponent } from './programs/sessions/sessions.component';
import { RegistrationComponent } from './programs/registration/registration.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportArrivalComponent } from './reports/arrival/arrival.component';
import { ReportDepartureComponent } from './reports/departure/departure.component';
import { TimepickerComponent } from './programs/classes/class-detail/timepicker/timepicker.component';
import { ConfirmDialogComponent } from 'src/app/widgets/confirm-dialog/confirm-dialog.component';
import { SessionDetailsComponent } from './programs/sessions/session-details/session-details.component';
import { SessionsService } from './programs/sessions/services/sessions.service';
import { NotificationTemplateComponent } from './notification/notification-template/notification-template.component';
import { NgxsModule } from '@ngxs/store';
import { ProgramState } from './programs/store/program.state';
import { NotificationGuard } from './notification/notification.guard';
import { HouseAndWellBeingEventsComponent } from './house-and-well-being-events/house-and-well-being-events.component';
import { MatNativeDateModule } from '@angular/material/core';
import { SharedModule } from '../../shared/shared.module';
import { NotificationHistoryLandingComponent } from './notification/notification-history/components/notification-history-landing/notification-history-landing.component';
import { HouseAndWellBeingGaurdService } from './house-and-well-being-events/services/house-and-well-being-gaurd.service';
import { NotificationHistoryState } from './notification/notification-history/store/notification-history.state';
import { DialogIdleTimeComponent } from 'src/app/widgets/dialog-idle-time/dialog-idle-time.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SessionDetailsCpeComponent } from './programs/sessions/session-details-cpe/session-details-cpe.component';
import { ProgramDocumentsComponent } from './programs/program-documents/program-documents.component';
import { NgxEditorModule } from 'ngx-editor';
import { UnSavedChangesGuard } from 'src/app/services/guard/un-saved-changes.guard';
import { MobileVersionComponent } from './mobile-version/mobile-version.component';
import { FormsModule } from "@angular/forms";

const routes: Routes = [
    {
        path: 'admin',
        component: AdminComponent,
        children: [
            {
                path: '',
                children: [
                    {
                        path: 'dashboard',
                        canActivate: [AdminGuard],
                        component: DashboardComponent
                    },
                    {
                        path: 'participants',
                        canActivate: [AdminGuard],
                        component: ParticipantsComponent
                    },
                    {
                        path: 'settings',
                        canActivate: [AdminGuard],
                        component: SettingsComponent
                    },
                    {
                        path: 'settings/notification',
                        canActivate: [AdminGuard],
                        canDeactivate: [NotificationGuard],
                        component: NotificationsComponent
                    },
                    {
                        path: 'settings/houseandwellbeingevents',
                        canActivate: [AdminGuard],
                        canDeactivate: [HouseAndWellBeingGaurdService, UnSavedChangesGuard],
                        component: HouseAndWellBeingEventsComponent
                    },
                    {
                        path: 'settings/systemSettings',
                        canActivate: [AdminGuard],
                        canDeactivate: [UnSavedChangesGuard],
                        component: MobileVersionComponent
                    },
                    {
                        path: 'programs',
                        canActivate: [AdminGuard],
                        component: ProgramsComponent
                    },
                    {
                        path: 'programs/:id/:type',
                        canActivate: [AdminGuard],
                        component: ProgramComponent,
                        canDeactivate: [UnSavedChangesGuard]
                    },
                    {
                        path: 'reports',
                        canActivate: [FlightReportGuard],
                        component: ReportsComponent
                    },
                    {
                        path: 'reports/arrival',
                        canActivate: [FlightReportGuard],
                        component: ReportArrivalComponent
                    },
                    {
                        path: 'reports/departure',
                        canActivate: [FlightReportGuard],
                        component: ReportDepartureComponent
                    },
                    {
                        path: 'programs-external',
                        canActivate: [FacilitatorGuard],
                        component: ProgramsComponent
                    },
                    {
                        path: 'programs-external/:id/:type',
                        component: ProgramComponent
                    },
                    {
                        path: 'programs/classes',
                        component: ClassesComponent
                    },
                    {
                        path: '',
                        redirectTo: 'dashboard',
                        pathMatch: 'full'
                    }
                ]
            }
        ]
    },
    {
        path: '',
        redirectTo: 'admin',
        pathMatch: 'full'
    }
];

@NgModule({
    declarations: [

        DashboardComponent,
        AdminComponent,
        ParticipantsComponent,
        SettingsComponent,
        NotificationsComponent,
        AdHocComponent,
        OverviewComponent,
        ProgramsComponent,
        ProgramComponent,
        NotificationTemplateComponent,
        ClassesComponent,
        SessionsComponent,
        RegistrationComponent,
        ReportsComponent,
        ReportArrivalComponent,
        ReportDepartureComponent,
        TimepickerComponent,
        SessionDetailsComponent,
        HouseAndWellBeingEventsComponent,
        NotificationHistoryLandingComponent,
        DialogIdleTimeComponent,
        SessionDetailsCpeComponent,
        ProgramDocumentsComponent,
        MobileVersionComponent
    ],
    entryComponents: [
        ConfirmDialogComponent, DialogIdleTimeComponent
    ],
    imports: [
        MatNativeDateModule,
        CommonModule,
        WidgetsModule,
        RouterModule.forChild(routes),
        NgxsModule.forFeature([ProgramState, NotificationHistoryState]),
        SharedModule,
        FormsModule,
        MatDialogModule,
        NgxEditorModule
    ],
    providers: [
        SessionsService, UnSavedChangesGuard
    ]
})
export class AdminModule { }