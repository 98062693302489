import { Injectable, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication/authentication.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogIdleTimeComponent } from './widgets/dialog-idle-time/dialog-idle-time.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutoLogoutService {

  isLogin = false;
  openDialogInvert = true;
  constructor(
    private router: Router,
    private ngZone: NgZone, private auth: AuthenticationService,
    private dialog: MatDialog
  ) {
    if (this.isUserLoggedIn()) {
      this.isLogin = true;
    }
    this.lastAction(Date.now());
    this.check();
    this.initListener();
    this.initInterval();
  }


  getLastAction() {
    return localStorage.getItem('lastAction');
  }

  lastAction(value) {
    localStorage.setItem('lastAction', JSON.stringify(value))
  }

  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
      document.body.addEventListener('submit', () => this.reset());
    });
  }

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        if (localStorage.hasOwnProperty(`CognitoIdentityServiceProvider.${environment.aws.userPoolWebClientId}.LastAuthUser`)) {
          this.check();
        }
      }, 10000);
    })
  }


  reset() {
    this.lastAction(Date.now());
  }

  check() {
    const now = Date.now();
    const isTimeout = this.calculateIdleTime(30 * 60 * 1000, now) <= 0;
    const isTimeingOutToLogOut = this.calculateIdleTime(25 * 60 * 1000, now) <= 0;

    this.ngZone.run(() => {
      if (isTimeout) {
        this.auth.signOut();
        this.router.navigate(['logout'])
      }
      else if (isTimeingOutToLogOut && this.openDialogInvert) {
        this.openDialog();
        this.openDialogInvert = false;
      }
    });
  }

  isUserLoggedIn(): boolean {
    return true;
  }

  calculateIdleTime(addTimeInMilliSeconds, now) {
    const timeLeft = parseInt(this.getLastAction()) + (addTimeInMilliSeconds); // + minutes to Time
    const diff = timeLeft - now;
    return diff;
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    let response = false;

    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.position = {
      'top': '15%',
      'right': '25%'
    };

    let dialogRef = this.dialog.open(DialogIdleTimeComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result === "false") {
        localStorage.clear();
        sessionStorage.clear();
        this.auth.signOut();
        this.router.navigate(['logout'])
      }
      else if (result === "true") {
        response = result;
      }
      this.openDialogInvert = true;
      this.dialog.closeAll();
    })
  }
}
