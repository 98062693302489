import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, switchMap, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ItineraryService } from '../../../services/aws/itinerary/itinerary.service';
import { IStay } from '../../../models/IStay';
import { ProgramService } from 'src/app/services/aws/program/program.service';
import { Select } from '@ngxs/store';
import { JourneyState } from '../journey.state';
import { IProgram } from 'src/app/models/IProgram';

@Component({
    selector: 'journey-stay',
    templateUrl: './stay.component.html',
    styleUrls: ['./stay.component.scss']
})
export class StayComponent implements OnInit, OnDestroy {
    public destroy$: Subject<boolean> = new Subject<boolean>();
    preRegister: boolean = false;
    registrationUrl: string = "https://cvent.me/E59WNV";
    stay: IStay;
    declinedRoom: boolean = false;

    @Select(JourneyState.getSelectedProgram) itineraryProgram$: Observable<IProgram>;

    constructor(private itinerary: ItineraryService, private program: ProgramService) { }

    ngOnInit() {
        if (this.noDataAvailable()) {
            this.preRegister = true;
        } else {
            this.itineraryProgram$
                .pipe(
                    distinctUntilChanged(),
                    // skipWhile(itineraryProgram => !itineraryProgram?.id),
                    switchMap(itineraryProgram => this.program.get(itineraryProgram?.id)),
                    takeUntil(this.destroy$)
                )
                .subscribe(data => {
                    if (data.programSetting) {
                        this.registrationUrl = data.programSetting.ceventRegistrationUrl;
                    }
                });

            this.itineraryProgram$
                .pipe(
                    distinctUntilChanged(),
                    // skipWhile(itineraryProgram => !itineraryProgram?.id),
                    switchMap(itineraryProgram => this.itinerary.getStay(itineraryProgram?.id)),
                    takeUntil(this.destroy$)
                ).subscribe((stayInformation: IStay) => {
                    this.stay = stayInformation;
                    // this.declinedRoom = ( !!this.stay.confirmationNumber && !!! this.stay.checkInDate && !!!this.stay.checkOutDate );
                    if (this.stay?.confirmationNumber !== undefined && this.stay?.checkInDate === undefined && this.stay?.checkOutDate === undefined) {
                        this.declinedRoom = true;
                    }
                    else {
                        this.declinedRoom = false;
                    }
                    this.preRegister = this.stayAvailable(stayInformation, this.stay);
                },
                    () => {
                        this.preRegister = true;
                    });
        }
    }

    redirectRegistration() {
        window.open(this.registrationUrl, "_blank");
    }

    noDataAvailable(): boolean {
        let isNoDataAvailable = false;
        this.itineraryProgram$.pipe(take(1)).subscribe(
            (data) => {
                isNoDataAvailable = !data;
            });
        return isNoDataAvailable;
    }

    public ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    stayAvailable(stayInformation: IStay, stay: IStay): boolean {
        if (stayInformation.length == 0) {
            return true;
        }
        return stayInformation || stay.length !== undefined ? false : true;
    }

}
