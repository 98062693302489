import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { of } from "rxjs";
import { tap } from "rxjs/operators";
import { ICategoryData } from "src/app/models/INotifications";
import { NotificationHistoryService } from "../services/notification-history.service";
import { NotificationHistoryActions } from "./notification-history.actions";

interface IRecipientsAttribute {
    [key: string]: ICategoryData;
}

export interface INotificationHistoryModel {
    recipientsAttributes: IRecipientsAttribute
}

@State<INotificationHistoryModel>({
    name: 'notifications',
    defaults: {
        recipientsAttributes: {}
    }
})

@Injectable({
    providedIn: 'root'
})
export class NotificationHistoryState {

    constructor(private notificationHistoryService: NotificationHistoryService) {}

    @Action(NotificationHistoryActions.GetRecipientsAttribute)
    public getRecipientsAttribute(ctx: StateContext<INotificationHistoryModel>, notificationId: NotificationHistoryActions.GetRecipientsAttribute) {
        const state = ctx.getState();
        const id = notificationId.notificationId;
        const attributes: ICategoryData = state.recipientsAttributes[id];
        if (attributes) {
            return of(attributes);
        }
        return this.notificationHistoryService.getRecipientsAttribute(id)
        .pipe(
            tap((response: ICategoryData) => {
                ctx.patchState({
                    recipientsAttributes: {
                        [id]: response
                    }
                });
            })
        );
    }
}
