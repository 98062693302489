import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map, switchMap, take, share, finalize } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication/authentication.service';
import { API } from 'aws-amplify';
import { environment } from '../../../../environments/environment';
import { IProgram } from '../../../models/IProgram';
import { ICourse } from '../../../models/ICourse';
import { IFlight } from '../../../models/IFlight';
import { IStay } from '../../../models/IStay';

API.configure({
    endpoints: [
        {
            name: "portal",
            endpoint: environment.aws.apiEndpoint
        }
    ]
});

@Injectable({
    providedIn: 'root'
})
export class ItineraryService {
    stayData: any;
    cacheProgramObservable: Observable<any>;
    constructor(private http: HttpClient, private auth: AuthenticationService) { }

    getPrograms(): Observable<IProgram[]> {
        if (this.cacheProgramObservable) {
            return this.cacheProgramObservable;
        }
        else {
            this.cacheProgramObservable = from(API.get('portal', 'persons/programs', {})).pipe(
                map(data => {
                    return data as IProgram[];
                })
            ),
                map(data => {
                    return data as IProgram[];
                }),
                take(1),
                share(),
                finalize(() => this.cacheProgramObservable = null)
            return this.cacheProgramObservable;
        }
    }

    getProgramCourses(programId): Observable<ICourse[]> {

        return from(API.get('portal', 'persons/programs/' + programId + '/courses', {})).pipe(
            map(data => {
                return data as ICourse[];
            })
        )

    }

    getFlight(programId): Observable<IFlight | any> {

        return from(API.get('portal', 'persons/programs/' + programId + '/travels', {})).pipe(
            map(data => {
                return data as IFlight;
            })
        )

    }

    getStay(programId): Observable<IStay> {
        return from(API.get('portal', 'persons/programs/' + programId + '/stays', {})).pipe(
            map(data => {
                if (data != undefined) {
                    this.stayData = data;
                    if (data.length == 1) {
                        return this.stayData != undefined ? this.stayData.sort(x => x.checkInDate)[0] : {};
                    }
                }
                return data;
            })
        )
    }
}