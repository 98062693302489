<ng-container *ngIf="updatedDetailCache?.length > 0; else displayIsAdded">
    <div class="reason" *ngFor="let missed of updatedDetailCache">
        <div *ngIf="showReason" class="add-reason" invalid="false">
            <span *ngIf="reasonItems === undefined" (click)="shoCommentsMenu()">
                <i class="material-icons">add_circle_outline</i> Add Reason
            </span>
            <div reason-menu [attr.show]="isCommentMenu">
                <div reason-menu-item *ngFor='let items of addReasonItems; let i = index'>
                    <mat-checkbox color="primary" (change)="insertReason(items,missed.id,$event);" name="commentCheckboxes" [checked]="items.selected">
                        {{items.label}}
                    </mat-checkbox>
                    <mat-form-field *ngIf="items.label === 'Other'">
                        <input type="text" [disabled]="isOther" matInput [(ngModel)]="otherCommentText" (focusout)="mouseleave('updateTime')">
                    </mat-form-field>
                </div>
            </div>
            <div class="chip" *ngIf="reasonItems !== undefined">{{reasonItems}}
                <i class="material-icons" (click)="removeReason('update')">cancel</i>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #displayIsAdded>
    <div class="reason" *ngIf="isAdded">
        <div class="title" *ngIf="listText">
            <p>{{listText}} :
                <span> {{this.newAddedDetails.checkInTime | dateFormat :'rosterTime'}}-{{
                        this.newAddedDetails.checkOutTime | dateFormat :'rosterTime'}}(
                        {{getTimeDiff(this.newAddedDetails.checkInTime,this.newAddedDetails.checkOutTime)}}) </span>
            </p>
        </div>
        <div *ngIf="showReason" class="add-reason" invalid="false">
            <span *ngIf="addnewReasonItems === undefined && !listText" (click)="shoCommentsMenu()">
                <i class="material-icons">add_circle_outline</i> Add Reason
            </span>
            <div reason-menu [attr.show]="isCommentMenu">
                <div reason-menu-item *ngFor='let items of addReasonItems; let i = index'>
                    <mat-checkbox color="primary" (change)="insertReason(items,0,$event);" name="commentCheckboxes" [checked]="items.selected">
                        {{items.label}}
                    </mat-checkbox>
                    <mat-form-field *ngIf="items.label === 'Other'">
                        <input type="text" [disabled]="isOther" matInput [(ngModel)]="otherCommentText" (mouseleave)="mouseleave('addTime')">
                    </mat-form-field>
                </div>
            </div>
            <div class="chip" *ngIf="addnewReasonItems !== undefined">{{addnewReasonItems}}
                <i class="material-icons" (click)="removeReason('add')">cancel</i>
            </div>
        </div>
    </div>
</ng-template>

<div class="buttons">
    <button *ngIf="isShowSave" type="button" class="btn" [attr.show]="saveBtn" (click)="cancelTimeMissed()">Cancel</button>
    <button *ngIf="isShowSave" type="button" class="btn btn-primary" [attr.show]="saveBtn" (click)="save()">Save Changes</button>
    <button class="btn btn-success-flat" *ngIf="isSuccess">Saved Successfully
        <i class="material-icons" (click)="closeSuccess(i)">check_circle_outline</i>
    </button>
</div>