import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ICourse } from '../../models/ICourse';

@Injectable({
    providedIn: 'root'
})
export class HeaderService {
    private subject = new Subject<any>();
    setHeader(course: ICourse) {
        this.subject.next({ course: course });
    }
    getHeader(): Observable<any> {
        return this.subject.asObservable();
    }
    clearHeader() {
        this.subject.next(true);
    }
}
