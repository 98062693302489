import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FilterComponent } from './filter/filter.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../widgets/material.module';

@NgModule({
    declarations: [ FilterComponent ],
    imports: [ CommonModule, 
    BrowserModule, 
    FormsModule, 
    ReactiveFormsModule,
    MaterialModule ],
    exports: [ FilterComponent, MaterialModule]
})

export class SharedModule { }
