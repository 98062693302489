import { Directive, ElementRef, Renderer2, OnInit, OnDestroy, Input } from "@angular/core";

@Directive({
    selector: 'table[sticky]'
})
export class StickyTableHeaderDirective implements OnInit, OnDestroy {
    @Input() offset: number;
    percentValue: number;

    constructor(private elementRef: ElementRef, private render2: Renderer2) { }
    
    ngOnInit() {
        if(this.offset) {
            window.addEventListener('scroll', this.onScroll, true);
        }
    }
    
    ngOnDestroy() {
        if (this.offset) {
            window.addEventListener('scroll', this.onScroll, true);
        }
    }
    
    onScroll = ($event): void => {
        const percent = Math.round(($event.srcElement.scrollTop / ($event.srcElement.scrollHeight - $event.srcElement.clientHeight)) * 100);

        if (this.percentValue !== percent) {
            this.percentValue = percent;

            if (this.percentValue >= this.offset) {
                // Adding custom class
                this.render2.addClass(this.elementRef.nativeElement, 'sticky');
                // adding custom attribute
                // this.render2.setAttribute(this.elementRef.nativeElement, 'customAttr', 'true');
            }
            else {
                // Adding custom class
                this.render2.removeClass(this.elementRef.nativeElement, 'sticky');
                //  adding custom attribute
                // this.render2.removeAttribute(this.elementRef.nativeElement, 'customAttr');
            }
        }
    };
}
