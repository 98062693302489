import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class FacilitatorGuard implements CanActivate, CanActivateChild {
    constructor(private auth: AuthenticationService) { }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
            const roles = this.auth.getUserRoles();
            return roles.includes('admin') || roles.includes('facilitator');
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(route, state);
    }
}
