import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styles: []
})
export class ConfirmDialogComponent implements OnInit {
    title: string;
    body: string;
    buttonTextNo: string;
    buttonTextYes: string;

    constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) private data: any) {
    }

    ngOnInit() {
        this.title = this.data.title;
        this.body = this.data.body;
        this.buttonTextNo = this.data.buttonTextNo;
        this.buttonTextYes = this.data.buttonTextYes;
    }

    dialogclose() {
        this.dialog.closeAll();
    }
}
