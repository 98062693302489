import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ProgramService } from '../../../../../services/aws/program/program.service';
import { IClassRoster } from '../../../../../models/IClassRoster';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from "rxjs";
import { ReportService } from 'src/app/services/aws/report/report.service';
import { IErrorMessage } from 'src/app/widgets/error-message/error-message.interface';
import { API_USER_DEFINED_MESSAGE } from 'src/app/widgets/error-message/error-message.const';

@Component({
    selector: 'class-detail',
    templateUrl: './classdetail.component.html',
    styles: []
})
export class ClassDetailComponent implements OnInit, OnDestroy {
    @Input() programId: any;
    @Input() classId: any;
    @Input() facilitatorAttendance: boolean;
    @Output() indexSelected = new EventEmitter<any>();
    
    private destroy$ = new Subject();
    tabOptions = {
        tabs: [
            { label: 'Class Roster' },
        ]
    };
    data: any;
    rosterValue: IClassRoster;
    public classDetailErrorMessage: IErrorMessage = {};
    public programErrorMessage: IErrorMessage = {};
    classDetail: IClassRoster[];
    classDate: string;
    classEndDate: string;
    hidden: boolean = false;
    size: any;
    tableRow: string = "collapsed";
    show: boolean = true;
    today: number = Date.now();
    fm: any;
    curDate: Date;
    daysLeft: any;
    showLoading = false;
    program;
    isDatePicker = false;
    selectedClassDetails: any;
    displayClassId: any;
    displayCourseId: any;
    
    constructor(private programservice: ProgramService, private reportService: ReportService, private route: ActivatedRoute) { }

    ngOnInit() {
        this.getDetail();
    }

    selectedEvent(selectedIndex){
        this.indexSelected.emit(selectedIndex);
    }

    private getDetail() {
        this.classDetailErrorMessage = {};
        this.programservice.getClassDetail(this.programId, this.classId).pipe(
            takeUntil(this.destroy$),
            map(data => data))
        .subscribe((response) => {
                this.classDetailErrorMessage.text = response?.length ? '' : API_USER_DEFINED_MESSAGE.EMPTY_RESPONSE;
                this.classDetail = response;
                this.getProgram(this.programId);
                this.selectedClassDetails = response.find(x => x.id == this.classId);
                this.displayClassId = this.selectedClassDetails.classId;
                this.displayCourseId = this.selectedClassDetails.courseId;
                this.classDate = this.selectedClassDetails.startDate;
                this.classEndDate = this.selectedClassDetails.endDate;
            },
            () => {
                this.classDetail = [];
                this.classDetailErrorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
            });

    }

    getProgram(programId)
    {
        this.programErrorMessage = {};
        this.programservice.get(programId).pipe(
            takeUntil(this.destroy$),
            map(data => data))
        .subscribe((response) => {
                this.programErrorMessage.text = response?.length ? '' : API_USER_DEFINED_MESSAGE.EMPTY_RESPONSE;
                this.program = response;
            },
            () => {
                this.program = [];
                this.programErrorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
        });
    }

    toggleDropdown() {
        this.isDatePicker = !this.isDatePicker;
    }

    selectDate($event, item) {
        $event.stopPropagation(); // Prevent parent click event from being triggered in case both child/parent overlap
        this.classId = item.id;
        this.classDate = item.startDate;
        this.getDetail();
        this.toggleDropdown();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}