    <div class="textgreysmall p-t-xs p-l-md p-r-md text-align-left"  *ngIf="currentProgramsList?.length">
        <p class="text-gray-light font-size-12">CURRENT PROGRAMS</p>
        <div *ngFor="let currentProgram of currentProgramsList; let lastItem = last; let i = index">
            <span *ngIf="currentProgram?.id !== programId">
                <p class="text-cyan-blue cursor-pointer" (click)="setSelectedProgram(currentProgram)">{{currentProgram?.name}} ({{ currentProgram?.dtStart }} - {{ currentProgram?.dtEnd }})</p>
                <hr class="horizontal-line" *ngIf="!lastItem && currentProgramsList[currentProgramsList?.length - 1]?.id !== programId">
            </span>
        </div>
    </div>
    <hr class="horizontal-line" *ngIf="upcomingProgramsList?.length">
    <div class="textgreysmall text-align-left p-t-xs p-l-md p-r-md"  *ngIf="upcomingProgramsList?.length">
        <p class="text-gray-light font-size-12">UPCOMING PROGRAMS</p>
        <div  *ngFor="let upcomingProgram of upcomingProgramsList; let lastItem = last; let i = index">
            <span *ngIf="upcomingProgram?.id !== programId">
                <p class="text-cyan-blue cursor-pointer" (click)="setSelectedProgram(upcomingProgram)">{{upcomingProgram?.name}} ({{ upcomingProgram?.dtStart }} - {{ upcomingProgram?.dtEnd }})</p>
                <hr class="horizontal-line"   *ngIf="!lastItem && upcomingProgramsList[upcomingProgramsList?.length - 1]?.id !== programId">
            </span>
        </div>
    </div>