import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { API } from 'aws-amplify';
import { IHouseEvent, IEvent, IEventId, IHouseEventParticipants, ITaskProgress } from '../../../models/IHouseEvent';
import * as moment from 'moment';
import { UploadHouseEventsResponse } from 'src/app/features/admin/house-and-well-being-events/house-and-well-being-events.interface';

API.configure({
    endpoints: [
        {
            name: "portal",
            endpoint: environment.aws.apiEndpoint
        }
    ]
});

@Injectable({
    providedIn: 'root'
})
export class HouseEventService {
    constructor(private http: HttpClient) { }

    // start house-and-well-being-events.component

    public getHouseEvent() {
        return from(API.get('portal', 'house-events', {})).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map((data: IHouseEvent[]) => {
                data = data.map((s: IHouseEvent) => {
                    s.isEditable = false;
                    s.readMore = false;
                    s.startTime = moment(s.startDate).format('HH:mm');
                    s.endTime = moment(s.endDate).format('HH:mm');
                    return s;
                });
                data.sort((a, b) => {
                    const aStartDate: Date = new Date(a.startDate);
                    const bStartDate: Date = new Date(b.startDate);

                    const aEndDate: Date = new Date(a.endDate);
                    const bEndDate: Date = new Date(b.endDate);
                    const endDateValue = aEndDate < bEndDate ? -1 : aEndDate > bEndDate ? 1 : 0;
                    return aStartDate < bStartDate ? -1 : aStartDate > bStartDate ? 1 : endDateValue;

                });
                return data as IHouseEvent[];
            })
        );
    }

    public addHouseEvent(data: IEvent): Observable<IEvent> {
        return from(API.post('portal', 'house-events', { body: data })).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map((res: string) => {
                return res as any;
            })
        );
    }

    public fileUploadHouseEvent(data): Observable<UploadHouseEventsResponse[]> {
        return from(API.post('portal', 'house-events/file-upload', { body: data })).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map((res: UploadHouseEventsResponse[]) => {
                return res as UploadHouseEventsResponse[];
            })
        );
    }

    public fileUploadEventParticipants(data, activityId): Observable<String> {
        return from(API.post('portal', `house-events/participants/file-upload?eventId=${activityId}`, { body: data })).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map((res: string) => {
                return res as string;
            })
        );
    }

    public updateHouseEvent(id: IEventId, data: IEvent): Observable<IEvent> {
        return from(API.patch('portal', 'house-events', { body: data })).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map((res: string) => {
                return res as any;
            })
        );
    }


    public deleteHouseEvent(id: IEventId): Observable<IEventId> {
        let data = null;
        return from(API.del('portal', 'house-events?id=' + id, {})).pipe(
            map((res: string) => {
                return res as any;
            })
        );
    }

    public getHouseEventParticipants(id: IEventId) {
        return from(API.get('portal', 'house-events?id=' + id, {})).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map((data: IHouseEventParticipants[]) => {
                data = data.map((s: IHouseEventParticipants) => {
                    return s;
                });
                return data as IHouseEventParticipants[];
            })
        );
    }


    public deleteHouseEventParticipant(data): Observable<IEventId> {
        return from(API.del('portal', 'house-events/participants', { body: data })).pipe(
            map((res: string) => {
                return res as any;
            })
        );
    }

    public addHouseEventParticipant(data): Observable<IEventId> {
        return from(API.post('portal', 'house-events/participants', { body: data })).pipe(
            map((res: string) => {
                return res as any;
            })
        );
    }



    //end house-and-well-being-events.component

    public taskProgress(taskId: String): Observable<ITaskProgress> {
        return from(API.get('portal', `task?id=${taskId}`, {})).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map((res: ITaskProgress) => {
                return res ?
                    res as ITaskProgress :
                    {
                        "id": taskId,
                        "status": "InProgress"
                    } as any; //Reading default values when task API returns empty with success code.
            })
        );
    }
}


