import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, shareReplay, skipWhile, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ProgramService } from 'src/app/services/aws/program/program.service';
import { CommunicationsService } from 'src/app/services/aws/communications/communications-service';
import { ICommunications } from 'src/app/models/ICommunications';
import { Select, Store } from '@ngxs/store';
import { JourneyState } from '../journey.state';
import { UserState } from 'src/app/store/user.state';
import { IUser } from 'src/app/models/IUser';
import { IErrorMessage } from 'src/app/widgets/error-message/error-message.interface';
import { API_USER_DEFINED_MESSAGE } from 'src/app/widgets/error-message/error-message.const';
import { IItineraryProgram, IProgram } from 'src/app/models/IProgram';
import { JourneyActions } from '../journey.actions';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'journey-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy
 {
    public destroy$: Subject<boolean> = new Subject<boolean>();
    public showGlobalSpinner: boolean = true;
    @Select(UserState.user) user$: Observable<IUser>;
    programName: string;
    programId: number;
    programStartDate: Date;
    programEndDate: Date;
    public errorMessage: IErrorMessage = {};
    public errorMsg: IErrorMessage = {};
    programs : IProgram;
    upcomingProgramsList: IProgram[] = [];
    currentProgramsList: IProgram[] = [];
    showDropdown: boolean = false;
    communication: ICommunications;
    greeting: string = "Good Afternoon";
    isShow = true;
    currentSection = 'section1';
    image = "";
    href = "";
    section: string;
    programStartEndDate;

    @Select(JourneyState.itineraryProgram) itineraryProgramId$: Observable<IItineraryProgram[]>;
    @Select(JourneyState.getSelectedProgram) itineraryProgram$: Observable<IProgram>;

    constructor(private program: ProgramService, private communications: CommunicationsService, private store: Store,
        private dialog:MatDialog) {
        this.itineraryProgram$
            .pipe(
                distinctUntilChanged(),
                skipWhile(itineraryProgram => !itineraryProgram?.id),
                takeUntil(this.destroy$))
            .subscribe((data: IProgram) => {
                this.programs = data;
                this.programId = +data?.id;
            });
     }

    ngOnInit() {
        this.errorMsg = {};
        this.itineraryProgramId$.pipe(
            tap((programsList) => this.showGlobalSpinner = programsList?.length !== 0),
            skipWhile(programsList => !programsList?.length),
            switchMap(programsList => {
                this.showGlobalSpinner = true;
                return forkJoin(
                    programsList.map(program => {
                        return this.program.get(program.programId).pipe();
                    }));
            }),
            takeUntil(this.destroy$)
          ).subscribe((programs: IProgram[]) => {
              this.upcomingProgramsList = [];
              this.currentProgramsList = [];
              this.errorMsg.text = programs ? '' : API_USER_DEFINED_MESSAGE.EMPTY_RESPONSE;
              const today = new Date();
              programs.map((program: IProgram) => {
                  const programStartDate = new Date(program?.startDate);
                  const programEndDate = new Date(program?.endDate);
                  const startDate =  ("0" +  String(programStartDate.getDate())).slice(-2);
                  const startMonth = String(programStartDate.getMonth() + 1).padStart(2, '0');
                  const startYear = (programStartDate.getFullYear()  % 100);
                  const endDate = ("0" +  String(programEndDate.getDate())).slice(-2);
                  const endMonth = String(programEndDate.getMonth() + 1).padStart(2, '0');
                  const endYear = (programEndDate.getFullYear()  % 100);
                  program.dtStart = `${startMonth}/${startDate}/${startYear}`;
                  program.dtEnd = `${endMonth}/${endDate}/${endYear}`;
                  if (programStartDate > today) {
                    this.upcomingProgramsList.push(program);
                  } else {
                    this.currentProgramsList.push(program);
                  }});
            this.upcomingProgramsList.sort((a: IProgram, b: IProgram) => (new Date(a.startDate)).valueOf() - (new Date(b.startDate)).valueOf());
            this.currentProgramsList.sort((a: IProgram, b: IProgram) => (new Date(a.startDate)).valueOf() - (new Date(b.startDate)).valueOf());
            if (!this.programId) {
                const defaultProgram: IProgram = this.currentProgramsList?.length ? this.currentProgramsList[0] : this.upcomingProgramsList[0];
                this.store.dispatch(new JourneyActions.SetCurrentItineraryPrograms(this.currentProgramsList));
                this.store.dispatch(new JourneyActions.SetUpcomingItineraryPrograms(this.upcomingProgramsList));
                this.store.dispatch(new JourneyActions.SetSelecedProgram(defaultProgram));
                this.setSelectedProgram(defaultProgram);
            } else {
                this.setSelectedProgram(this.programs);
            }
            this.showGlobalSpinner = false;
          },
          () => {
              this.programs = null;
              this.errorMsg.text = API_USER_DEFINED_MESSAGE.FAILED;
              this.showGlobalSpinner = false;
          });
        this.communications.getCommunicationstest().pipe(shareReplay(),
        takeUntil(this.destroy$))
        .subscribe((data:ICommunications)=> {
            this.errorMessage.text = data? '' : API_USER_DEFINED_MESSAGE.EMPTY_RESPONSE;
            this.communication = data;
            this.href = data?.portalCommunicationUrl;
        },
        () => {
           this.communication = null;
           this.errorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
        }
       );
        const time = (new Date()).getHours();
        this.greeting = (time < 12) ? "Good Morning" : (time < 17) ? "Good Afternoon" : "Good Evening";
    }

    public setSelectedProgram(program: IProgram) {
        this.programName = program.name;
        this.programId = <number>program.id;
        this.programStartDate = program.startDate;
        this.programEndDate = program.endDate;
        this.programStartEndDate = {
            "programStartDate" : program.startDate,
            "programEndDate" : program.endDate
        };
        this.programs = program;
        this.showDropdown = false;
        this.store.dispatch(new JourneyActions.SetSelecedProgram(program));
    }

    toogleDropdown(): void {
        this.showDropdown = !this.showDropdown;
    }

    hideShowDropdown(event): void {
        if (event?.target?.id === 'course-list-dropdown') {
            return;
        }
        this.showDropdown = false;
    }
     
    toggleRegister() {
        this.isShow = !this.isShow;
    }

    onSectionChange(sectionId: string) {
        this.currentSection = sectionId.replace('-img', '');
    }

    scrollTo(section) {
        document.querySelector('#' + section)
            .scrollIntoView({ behavior: 'smooth', block: 'start' });
        this.isShow = false;
        this.section = section;
        this.onSectionChange(section);
        this.dialog.closeAll();
    }

    openPage(link) {
        window.open(link, "_blank");
    }

    getLogo(): string {

        return require(this.image);
    }

    redirectRegistration() {
        window.open(this.href, "_blank");
    }

    ngOnDestroy(){
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}