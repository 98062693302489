import { Component, Inject, OnDestroy } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ProgramService } from "src/app/services/aws/program/program.service";
import { IDownloadExcel, IParticipant, IParticipants, ISessionDialogInput, ISessionsMetaData } from "../interface/sessions.interface";
import { SessionsService } from "../services/sessions.service";
import { saveAs } from "file-saver";
import { IProgram } from "src/app/models/IProgram";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SessionDetailsMetadataModel } from "../models/session-details-metadata.model";

@Component({
    selector: 'session-details',
    templateUrl: './session-details.component.html',
    styleUrls: ['./session-details.component.scss']
})
export class SessionDetailsComponent implements OnDestroy {

    public metaData: ISessionsMetaData;

    public sessionMetaData: SessionDetailsMetadataModel;

    public participantsList: IParticipant[] = [];

    public fetchingParticipantsFailed: boolean = false;

    public sortProp: string = 'fullName';

    public sortDirection: number;

    public participantDirection: number;

    public isDatePicker = false;

    public isParticipantEmailAsc: boolean = false;

    public programInfo: IProgram;

    public hideSpinner: boolean = true;

    private unSubscribe: Subject<any> = new Subject();

    constructor(@Inject(MAT_DIALOG_DATA) public data: ISessionDialogInput, private sessionsService: SessionsService, private dialog: MatDialog, private programservice: ProgramService) {
        this.getSessionsMetaData();
        this.getSessionParticipants();
        this.getProgramInfo();
    }

    public getProgramInfo(): void {
        this.programservice.get(this.data.programId)
        .pipe(takeUntil(this.unSubscribe))
        .subscribe((response: IProgram) => {
            this.programInfo = response;
        });
    }

    public getSessionsMetaData(): void {
        this.sessionsService.getSessionMetadata(this.data.programId, this.data.sessionId)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((data: ISessionsMetaData) => {
                this.sessionMetaData = new SessionDetailsMetadataModel(data);
        },
        () => {
            this.sessionMetaData = new SessionDetailsMetadataModel(<ISessionsMetaData>{});
        });
    }

    public getSessionParticipants(): void {
        this.hideSpinner = false;
        this.fetchingParticipantsFailed = false;
        this. sessionsService.getSessionParticipants(this.data.programId, this.data.sessionId)
        .pipe(takeUntil(this.unSubscribe))
        .subscribe((data: IParticipants) => {
            this.hideSpinner = true;
            this.participantsList = data != null ? data.participants.map(p => {
              p.fullName = `${p.lastName}, ${p.firstName}`;
              return p;
            }) : [];
            this.sort('fullName');
        },
        () => {
            this.hideSpinner = true;
            this.fetchingParticipantsFailed = true;
        });
    }

    public dialogclose(): void {
        this.dialog.closeAll();
    }


    public downloadReport(): void {
        this. sessionsService.downloadSessionsReport(this.data.programId, this.data.sessionId)
        .pipe(takeUntil(this.unSubscribe))
        .subscribe((data: IDownloadExcel) => {
            this.save(data.data, 'SessionData');
        });

    }

    public sort(property: string): void {
        // toggle if it matches, or ascending by default
        this.sortDirection = property === this.sortProp  ? (this.sortDirection === 1 ? -1 : 1) : -1;
        this.sortProp = property;
    }

    public toggleDropdown(): void {
        this.isDatePicker = !this.isDatePicker;
    }

    private save(data:string, name:string): void {
        if (data) {
            const blob = new Blob([atob(data)], { type: 'text/csv' });
            saveAs(blob, name + '-' + (new Date()).toLocaleString() + '.csv');
        }
    }

    public ngOnDestroy(): void {
        this.unSubscribe.next(true);
        this.unSubscribe.complete();
    }
}
