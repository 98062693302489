<div left>
    <div class="title">
        <div>
            <img alt="kpmg logo" class="dark-logo" src="assets/images/white.png" />
        </div>
    </div>
    <div class="left-content">
        <p>Error: 404 Page Not Found</p>
        <span>
            Sorry, the page or resource you are looking for cannot be <br />
            found at this time or does not exist.
        </span>
        <br />
        <a href="#">Go to Homepage</a>
    </div>
</div>
<div right>
    <p>4<img src="assets/images/404@2x.png" />4</p>
</div>