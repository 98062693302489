<div *ngIf="showGlobalSpinner">
  <div class="loading">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div breadcrumbs [option]='breadcrumbOptions'></div>

<div class="heading">
  <h3>Manage System Settings</h3>
</div>
<div class="panel">

  <div class="container">
    <div class="form-container">
      <h2 class="app-manage">Change App Version</h2>
      <form [formGroup]="mobileVersionForm" (ngSubmit)="onSubmit()" class="horizontal-form">
        <mat-form-field appearance="outline" class="form-field">
          <mat-label>Select Platform</mat-label>
          <mat-select class="bold-options" formControlName="platform"
            (selectionChange)="onPlatformChange($event.value)">
            <mat-option *ngFor="let platform of appPlatforms" [value]="platform?.platformName">
              {{ platform?.platformName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="form-with-value">
          <p *ngIf="existingVersion" class="version-head">Current Version : {{existingVersion}}</p>
          <mat-form-field appearance="outline" class="form-field">
            <mat-label>New Version</mat-label>
            <input matInput formControlName="currentVersion">
          </mat-form-field>
        </div>
        <mat-checkbox formControlName="forceUpgrade">Force Upgrade</mat-checkbox>
        <div class="button-container">
          <button mat-raised-button color="primary" type="submit" [disabled]="mobileVersionForm.invalid">
            Submit
          </button>
        </div>
      </form>
    </div>
    <div class="platform-cells">
      <div class="cell" *ngFor="let platform of appPlatforms">
        <h3 class="platform-name">{{ platform.platformName }}</h3>
        <p>Current Version: {{ platform.currentVersion }}</p>
      </div>
    </div>
  </div>

</div>


<ng-template #displayErrorMessage>
  <p class="background-white m-t-xs">
    <span class="m-l-lg">
      <error-message [errorMessage]="errorMessage"></error-message>
    </span>
  </p>
</ng-template>

<ng-template #loading>
  <div class="loading" *ngIf="!showLoading">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>