import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { take } from 'rxjs/operators';
import { ItineraryService } from 'src/app/services/aws/itinerary/itinerary.service';
import { JourneyActions } from './journey.actions';
import { IProgram } from 'src/app/models/IProgram';

@Component({
    selector: 'journey',
    templateUrl: './journey.component.html',
    styles: []
})
export class JourneyComponent implements OnInit {
    programs: IProgram[];
    constructor(private store: Store, private itineraryService: ItineraryService) { }
    ngOnInit(): void {
        this.itineraryService.getPrograms().pipe(take(1)).subscribe(program => {
            this.store.dispatch(new JourneyActions.SetItineraryProgram(program));
            this.programs = program ? program : [];
        },
        () => {
            this.programs = [];
        });
    }
}