<div left>
    <div class="title">
        <div>
            <img alt="kpmg logo" class="dark-logo" src="assets/images/white.png" />
        </div>
    </div>
    <div class="left-content">
        <p>No Network Found</p>
        <span>
            Check your connection or try refreshing this page.
        </span>
    </div>
</div>
<div right>
    <p>L<img src="assets/images/network@2x.png" />ST</p>
    <span>Internet Connection</span>
</div>