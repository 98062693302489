import { Injectable } from "@angular/core";
import * as momentTimezone from "moment-timezone";


@Injectable({
    providedIn: "root"
})
export class UtilsService {

    constructor() {}

    public convertToEstFromUTC(date: string): string {
        if (date) {
            return momentTimezone.utc(date).tz('America/New_York').format("YYYY-MM-DD HH:mm:ss.SSS");
        }
         return 'N/A';
    }

    public convertEstToLocal(date: string): Date {
        // Ref https://gist.github.com/mihaipaun/9191226
        // EST - UTC offset: 5 hours
        const offset = 4.0;
        /*
        - calculate the difference between the server date and UTC
        - the value returned by the getTime method is the number of milliseconds since 1 January 1970 00:00:00 UTC.
        - the time-zone offset is the difference, in minutes, between UTC and local time
        - 60000 milliseconds = 60 seconds = 1 minute
      */
        const serverDate = new Date(date);
        const est = serverDate.getTime() - (serverDate.getTimezoneOffset() * 60000);
        /*
        - apply the offset between UTC and EST (5 hours)
        - 3600000 milliseconds = 3600 seconds = 60 minutes = 1 hour
        */
        const clientDate = new Date(est + (3600000 * offset));
        return clientDate;
    }

    public changeTimeZone(date: Date | string, timeZone: string): Date {
        if (typeof date === 'string') {
            return new Date(
              new Date(date).toLocaleString('en-US', {
                timeZone,
              }),
            );
          }
        
          return new Date(
            date.toLocaleString('en-US', {
              timeZone,
            }),
          );
    }
}