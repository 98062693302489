import { Component } from '@angular/core';

@Component({
  selector: 'admin-reports',
  templateUrl: './reports.component.html',
})
export class ReportsComponent {

  constructor() { }

}
