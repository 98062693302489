<span class="dialog-close" (click)="dialogclose()">
    <img src="assets/icons/close-drawer.svg" alt="" />
</span>
<div class-roster-header *ngIf="sessionMetaData; else loading">
    <div breadcrumb>
        <span>
            <img src="assets/icons/programs-drawer-title.svg" alt="">
        </span>
        <p>{{programInfo?.name || 'N/A'}} • {{ programInfo?.startDate | dateFormat :'mm/dd/yy'}} - {{programInfo
            ?.endDate | dateFormat :'mm/dd/yy'}}</p>
    </div>
    <div title>
        <h1>{{ sessionMetaData?.title }} </h1>
        <span
            class="{{sessionMetaData?.startDate |classcolor : sessionMetaData?.endDate }}">{{sessionMetaData?.startDate
            |classstatus : sessionMetaData?.endDate}}</span>
    </div>
    <div items>
        <div content>
            <p>SESSION ID
                <span dblock>{{ sessionMetaData?.sessionId }}</span>
            </p>
        </div>
        <div content>
            <p>CHECK-IN CODE
                <span>{{ sessionMetaData?.checkInCode }}</span>
            </p>
        </div>
        <div date>
            <div>
                <div>
                    <span>{{sessionMetaData?.startDate | dateFormat : 'mmddyyyy'}}</span>
                    <span>{{sessionMetaData?.startDate | dateFormat :'fulldayname'}}</span>
                </div>
            </div>
        </div>
        <div time>
            <div>
                <span>
                    <img src="assets/icons/time-table.svg" alt="">
                </span>
                <div>
                    <div>
                        <span>{{sessionMetaData?.startDate |dateFormat : 'coursetime'}} -</span>
                        <span>{{sessionMetaData?.endDate |dateFormat : 'coursetime'}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div place>
            <span>
                <i>
                    <img src="assets/icons/location-table.svg" alt="">
                </i>
                {{sessionMetaData?.location}}</span>
        </div>
    </div>
</div>
<div class-roster>
    <div class-roster-body>
        <div class-roster-title>
            <h1>Session Attendance</h1>
            <div (click)="downloadReport()" class="export-button">Export to Excel
                <img src="assets/icons/excel.svg" alt="" ml10>
            </div>
        </div>
        <div class="tab-body">
            <ng-container *ngIf="!isConfirmed">
                <div>
                    <div class="notification" *ngIf="isFacilitatorOver==='disable'">
                        <div>
                            <div class="attendanceText-box ">
                                <span class="clockImage"><img src="assets/icons/confirmation-not_due.svg" alt=""></span>
                                <span class="attendanceText">&nbsp;&nbsp;The attendance record as shown is an accurate
                                    record of each participant’s attendance.</span>
                            </div>
                        </div>
                        <button type="button" class="btn-primary" disabled (click)="confirm()">Confirm
                            Attendance</button>
                    </div>
                    <div class="notification" *ngIf="isFacilitatorOver==='allow'">
                        <div>
                            <div class="attendanceText-box ">
                                <span class="clockImage"><img src="assets/icons/confirmation-not_due.svg" alt=""></span>
                                <span class="attendanceText">&nbsp;&nbsp;The attendance record as shown is an accurate
                                    record of each participant’s attendance.</span>
                            </div>
                        </div>
                        <button type="button" class="btn-primary" (click)="confirm()">Confirm Attendance</button>
                    </div>
                    <div class="notification" *ngIf="isFacilitatorOver==='over'">
                        <div>
                            <div class="attendanceText-box ">
                                <span class="clockImage"><img src="assets/icons/confirmation-not_due.svg" alt=""></span>
                                <span class="attendanceText">&nbsp;&nbsp;The attendance record as shown is an accurate
                                    record of each participant’s attendance.</span>
                            </div>
                        </div>
                        <button type="button" class="btn-primary" (click)="confirm()">Confirm Attendance</button>
                    </div>
                    <div *ngIf="doConfirm">
                        <section class="popover">
                            <div>
                                <span>Once you confirm attendance, only Admin can make changes. Do you wish to confirm
                                    and close participant attendance?</span>
                                <article>
                                    <button (click)="cancelConfirm()">Cancel</button>
                                    <button (click)="saveConfirm()">Confirm</button>
                                </article>
                            </div>
                        </section>
                    </div>
                </div>
            </ng-container>
            <div class="notification success" *ngIf="isConfirmed">
                <ng-container *ngIf="confirmationData; else displayConfirmationDataErrorMessage">
                    <div>
                        <p><b>The attendance record as shown is an accurate record of each participant’s attendance.</b>
                        </p>
                        <div class="attendanceText-box ">
                            <span class="clockImage-confirm">
                                <img src="assets/icons/confirmation-confirmed.svg" alt=""></span>
                            <span class="attendanceText-confirm">&nbsp;&nbsp;{{confirmationData?.firstName}} {{confirmationData?.lastName}}
                                •
                                {{confirmationData?.createdDate | dateFormat : 'mmddyyyy'}} •
                                {{confirmationData?.createdDate |dateFormat : 'coursetime'}}
                            </span>
                        </div>
                    </div>
                    <button type="button" class="btn-success">Attendance Confirmed <i
                            class="material-icons">check_circle</i></button>
                </ng-container>
            </div>
            <ng-template #displayConfirmationDataErrorMessage>
                <p class="background-white m-t-xs">
                    <span class="m-l-lg">
                        <error-message [errorMessage]="confirmationDataErrorMessage"></error-message>
                    </span>
                </p>
            </ng-template>

            <div class="panel" *ngIf="participants; else loading">
                <div class="panel-header">
                    <div class="panel-title">
                        <p>Participant Attendance ({{participants.length}})</p>
                    </div>
                    <div class="display-flex">
                        <div class="expand-button" (click)="expandAll('participants')">Expand rows</div>
                        <span class="m-l-sm m-r-sm pipe">|</span>
                        <div class="expand-button m-r-sm" (click)="collapseAll('participants')">Collapse rows</div>
                    </div>
                </div>
                <div class="panel-body">
                    <table class="table participant-table"
                        *ngIf="participants?.length; else displayParticipantsErrorMessage">
                        <thead>
                            <th (click)="sort('fullName','participant')">
                                <span>Name</span>
                                <img src="assets/icons/sort-by-down.svg" [ngClass]="{'active':isParticipantNameAsc}"
                                    alt="">
                            </th>
                            <th (click)="sort('email','participant')">
                                <span>Email</span>
                                <img src="assets/icons/sort-by-down.svg" [ngClass]="{'active':isEmailNameAsc}" alt="">
                            </th>
                            <th>
                                <span>Check-in</span>
                            </th>
                            <th>
                                <span>Check-in By</span>
                            </th>
                            <th>
                                <span>Time Missed</span>
                            </th>
                        </thead>
                        <tbody>
                            <ng-container
                                *ngFor='let data of participants | orderBy: {property: participantColumn, direction: participantDirection}; let i = index'>
                                <tr [attr.edit]="data?.isEdit">
                                    <td>
                                        <div>
                                            <span>{{data?.fullName}}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <span>{{data?.email}}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <mat-checkbox [disabled]="data?.checkIn?.updatedBy == 'Code' || 
                                            data?.checkIn?.updatedBy == 'Beacon'
                                            ? true : false"
                                                [attr.data-updatedBy]="data?.checkIn?.updatedBy" color="primary"
                                                value="{{data?.checkIn?.status}}" [checked]="data?.checkIn?.status"
                                                (change)="checkInStatus($event,data.participantId, data)"></mat-checkbox>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <span>{{data?.checkIn?.updatedBy}}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="time-missed-box">
                                            <label>
                                                <label *ngIf="totalTimeMissed(data?.totalMissed) !== 0"
                                                    class="total-missed-label">
                                                    Total Missed: <div class="pipe bold-text m-l-xs">
                                                        {{totalTimeMissed(data?.totalMissed)}} Mins
                                                    </div>
                                                </label>
                                            </label>
                                            <div class="time-item">
                                                <span></span>
                                                <div class="m-l-sm" (click)="expandCollapse('participants', data, i)">
                                                    <img *ngIf="!data?.isEdit" src="assets/icons/expand-open-down.svg"
                                                        alt="">
                                                    <img *ngIf="data?.isEdit" src="assets/icons/expand-close-up.svg"
                                                        alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <div [ngClass]="{'m-t-lg' : totalTimeMissed(data?.totalMissed) !== 0}"
                                            timeexpanded>
                                            <div class="time-row" *ngFor="let timeMissed of data?.totalMissed">
                                                <div>
                                                    <span class="select-view"
                                                        (click)="!enableCheckbox?togglePicker(data.participantId,timeMissed,'checkIn','participant'):return">{{timeMissed?.checkInTime
                                                        | dateFormat :'rosterTime'}}</span>
                                                    <span class="text-gray">to</span>
                                                    <span class="select-view"
                                                        (click)="!enableCheckbox?togglePicker(data.participantId,timeMissed,'checkOut','participant'):return">{{timeMissed?.checkOutTime
                                                        | dateFormat :'rosterTime'}}</span>
                                                    <span close
                                                        (click)="!enableCheckbox?delete(data.participantId,timeMissed, 'participants', i):return"><i
                                                            class="material-icons">close</i></span>
                                                    <time-picker
                                                        [dateTime]="[selectedType === 'checkIn'? timeMissed?.checkInTime : timeMissed?.checkOutTime, '']"
                                                        *ngIf="selectedPickerId === timeMissed.id && selectedTarget === 'participant'"
                                                        (cancel)="togglePicker()"
                                                        (update)="update($event)"></time-picker>
                                                </div>
                                                <div *ngIf="timeMissed?.comments">
                                                    <div class="comment">
                                                        <span (click)="toggleComment(timeMissed?.id,'participants')">
                                                            <img src="assets/icons/comment.svg" alt="">
                                                        </span>
                                                        <div class="comment-box"
                                                            *ngIf="selectedParticipantBox === timeMissed.id && commentBoxType === 'participants'">
                                                            <div comment-box-header>
                                                                <span><img src="assets/icons/comment.svg"
                                                                        alt="">{{timeMissed?.comments}}</span>
                                                                <i class="material-icons"
                                                                    (click)="closeComment(i,'participants')">close</i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="total-time-count">

                                                        <span class="time-highlight participant-time">
                                                            <i class="material-icons">access_time</i>
                                                            {{timeMissed?.checkInTime | timeDiff :
                                                            timeMissed?.checkOutTime }} Mins
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="time-row">
                                                <div class="position-relative">
                                                    <span class="select-view" blank
                                                        (click)="!enableCheckbox?togglePicker(data.participantId,null,'addCheckIn','participant'):return">
                                                        {{addedParticipantsInfo[i]?.checkInTime | dateFormat
                                                        :'missedStartTime'}}
                                                    </span>
                                                    <span class="text-gray">to</span>
                                                    <span class="select-view participant-endtime" blank
                                                        (click)="!enableCheckbox?togglePicker(data.participantId,null,'addCheckOut','participant'):return">
                                                        {{addedParticipantsInfo[i]?.checkOutTime | dateFormat
                                                        :'missedEndTime'}}
                                                    </span>
                                                    <div class="position-absolute participant-time-picker">
                                                        <time-picker [dateTime]="['','']"
                                                            *ngIf="selectedPickerId === 0 && selectedTarget === 'participant' && participantSelectedIndex === i"
                                                            (cancel)="togglePicker()"
                                                            (update)="update($event)"></time-picker>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="reason-row"
                                    *ngIf="((addedParticipantsInfo[i]?.checkInTime !== 'Add Time' && addedParticipantsInfo[i]?.checkOutTime !== 'Add Time') || (addedParticipantsInfo[i]?.enableSaveCta && isShowSave) ||  (updatedDetailCache?.length > 0)) && participantSelectedIndex === i">
                                    <td colspan="6">
                                        <save-bar [showReason]="true" [newAddedDetails]="newAddedDetails"
                                            [isAdded]="isAdded" [isSuccess]="isSuccess"
                                            [isShowSave]="(addedParticipantsInfo[i]?.checkInTime !== 'Add Time' && addedParticipantsInfo[i]?.checkOutTime !== 'Add Time') || addedParticipantsInfo[i]?.enableSaveCta || updatedDetailCache?.length > 0"
                                            (toggle)="toggleRow(null,'participants')"
                                            [updatedDetailCache]="updatedDetailCache" [isDeleted]="isDeleted"
                                            [type]="'participants'" (updateComment)="updateCommentFacilitator($event)"
                                            [listText]="listText"
                                            (toggleCancelWidget)="keepCancelWidgetEditing('participants')" [index]="i"
                                            [data]="addedParticipantsInfo[i]"
                                            (toggleSave)="getRosterParticipants()"></save-bar>

                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>

                    <ng-template #displayParticipantsErrorMessage>
                        <p class="background-white m-t-xs">
                            <span class="m-l-lg">
                                <error-message [errorMessage]="participantsErrorMessage"></error-message>
                            </span>
                        </p>
                    </ng-template>

                </div>
            </div>
        </div>
    </div>
</div>
<div cancel-widget [attr.showCancelWidget]="cancelModel">
    <div>
        <span>Are you sure you want to exit and lose changes?</span>
        <article>
            <button (click)="cancelWidgetConfirm()">Cancel</button>
            <button (click)="keepCancelWidgetEditing('')">Keep Editing</button>
        </article>
    </div>
</div>
<ng-template #loading>
    <div class="loading" *ngIf="!showLoading">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>