import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
    diffMs: number = 0; diffHrs: number = 0; diffMins: number = 0;
    transform(startTime: any, endTime?: any): any {
        if (startTime != undefined && endTime != undefined) {
            startTime = new Date(startTime);
            endTime = new Date(endTime);
            this.diffMs = (endTime - startTime); // milliseconds
            this.diffHrs = Math.floor(this.diffMs / 3600000); // hours
            this.diffMins = Math.round((this.diffMs % 3600000) / 60000); // minutes
            return this.diffHrs + " hrs, " + this.diffMins + " mins";
        }
    }
}