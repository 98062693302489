import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { API } from 'aws-amplify';
import { IRecipient, ICount} from '../../../models/INotifications';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { from, throwError } from 'rxjs';

API.configure({
    endpoints: [
        {
            name: "portal",
            endpoint: environment.aws.apiEndpoint
        }
    ]
});

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    constructor(private http: HttpClient) { }

    getRecipients(category, categoryValue, dtStart?, dtEnd?) {
        let APICall;

        if (category === "session" || category === "guest") {
            let body = {
                "ParticipantID": categoryValue
            };
            APICall = API.post('portal', 'adhoc/' + category + '/reciepients', { body: body });
        }
        else if (category === "meeting") {
            let filter: string = dtStart + "," + dtEnd;
            const regex = new RegExp('%2C', "g");
            
            APICall = API.get('portal', 'adhoc/' + category + '/reciepients/' +encodeURIComponent(categoryValue).replace(regex, ',') + '/filter/' + filter, {});
        }
        else {
            APICall = API.get('portal', 'adhoc/' + category + '/reciepients/' + categoryValue, {});
        }

        return from(APICall).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map(data => {
                return data as IRecipient[];
            })
        );
    }

    getRecipientCount(category, categoryValues) {
        return from(API.get('portal', 'adhoc/' + category + '/reciepients/' + categoryValues + '/count', {})).pipe(
            map(data => {
                return data as ICount;
            })
        );
    }
    // can bimal make change to not pass comma list.
    getCategoryFilterValues(category, dtStart, dtEnd) {
        let filter: string = dtStart + "," + dtEnd;
        return from(API.get('portal', 'adhoc/' + category + '/filter/' + filter, {})).pipe(
            map(data => {
                return data;
            })
        );
    }

    getCategoryValues(category) {
        return from(API.get('portal', 'adhoc/' + category, {})).pipe(
            map(data => {
                return data;
            })
        );
    }

    public sendNotification(category, messageType, msgTitle, notificationBody, participants, senderEmail, categoryItemNames, alertBody, sendType, scheduleDateTime) {
        const messageData = {
            category,
            categoryId: "999",
            recipients: participants,
            attributes: categoryItemNames,
            message: {
                messageType,
                msgTitle,
                notificationBody,
                alertBody,
                senderEmail
            },
            sendType: sendType,
            scheduleDateTime: sendType !== "schedule" ? undefined : scheduleDateTime,
        };
        const notificationData = {
            body: {
                type: Number(messageType),
                title: '',
                body: alertBody,
                "participant_ids": participants.split(','),
                sendType: sendType,
                scheduleDateTime: sendType !== "schedule" ? undefined : scheduleDateTime,
            }
        };

        return from(API.post('portal', 'adhoc/message', { body: messageData })
            .then(async () => {
                await API.post('portal', 'adhoc/notification', { body: notificationData });
            },
            error => error
            ));
    }

}
