import { Component, Input } from '@angular/core';
import { API_USER_DEFINED_MESSAGE } from './error-message.const';
import { IErrorMessage } from "./error-message.interface";

@Component({
    selector: 'error-message',
    templateUrl: './error-message.component.html'
})
export class ErrorMessageComponent {

    @Input() public errorMessage: IErrorMessage = {
        text: API_USER_DEFINED_MESSAGE.FAILED
    };
    
}