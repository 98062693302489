import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import * as moment from 'moment';
@Component({
    selector: 'time-picker',
    templateUrl: './time-picker.component.html',
    styles: []
})
export class TimePickerComponent implements OnChanges {
    hours: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    minutes: number[];
    defaultMinutes: number[] = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
    @Input() show: boolean;
    @Input() dateTime: string;
    @Input() minutesArr: number[];

    @Output() cancel = new EventEmitter();
    @Output() update = new EventEmitter();

    preTime: any;
    hour;
    minute;
    ampm;

    constructor() {   }

    ngOnChanges() {
        if (this.dateTime) {
            if (!this.preTime) {
                this.preTime = this.dateTime;
            }
            let convertedDateTime = moment.utc(this.dateTime, 'hh:mm').format('hh:mm A');
            let fTime = convertedDateTime.split(' ');
            let timeArr = fTime[0].split(':');
            this.hour = +timeArr[0];
            this.minute = +timeArr[1];
            this.ampm = fTime[1];
        }
        
        if(this.minutesArr){
            this.minutes = this.minutesArr;
        }else{
            this.minutes = this.defaultMinutes;
        }
    }

    cancelClick() {
        this.cancel.emit('');
    }

    updateClick() {
        let timeObj = `${this.hour}:${this.minute} ${this.ampm}`;
        this.update.emit({
            value: moment.utc(timeObj, ['h:mm A']).format('HH:mm'),
            preTime: this.preTime
        });
    }
}
