import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

/*
This pipe will give the number of days between two dates
*/
@Pipe({ name: 'getnights' })
export class GetNights implements PipeTransform {
    diffInNights: number;
    transform(startDate: Date, endDate: Date): number {
        var timeDiff = Math.abs(moment(startDate).diff(moment(endDate)));
        this.diffInNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return this.diffInNights;
    }
}