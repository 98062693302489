<div pre-register *ngIf="preRegister">
    <div course-head>
        <span><img src="assets/images/courses-title.svg" alt=""></span>
        <span>My Courses</span>
        <button (click)="redirectRegistration()">Course Enrollment</button>
    </div>
    <div>
        <span><p> <b>Note:</b>  Please allow 24 hours to see updates or changes to course registration reflected here.</p></span>
    </div>
    <div>
        <div register-course>
            <img src="assets/images/courses-pre.svg">
            <span>Chart your learning journey</span>
            <span enrollment>Click <a (click)="redirectRegistration()">“Course Enrollment”</a>  to begin.</span>
        </div>
    </div>
</div>
<div registered *ngIf="!preRegister && groupedCourses$ | async as courseList;">
    <div course-head>
        <span>
            <img src="assets/images/courses-title.svg">
        </span>
        <span>My Courses ( {{coursesCount$ | async}} )</span>
        <div>
            <button (click)="redirectRegistration()">Manage Registration</button>
        </div>
    </div>
    <div note>
        <span>Note:</span>
        <span>Please allow 24 hours to see updates or changes to course registration reflected here.</span>
    </div>
    <div course-container>
        <div course-list>
            <div *ngFor="let course of courseList | keyvalue">
                <h3>{{course.key | dateFormat : 'coursedate' }}</h3>
                <div card *ngFor="let item of course.value | orderBy: {property: column, direction: direction}" 
                [ngClass]="{'active': item.endDate | status, 'selected':item.Id === selectedId}" (click)="getCourseDetails(item.Id)">
                    <div>
                        <span>
                          <!--  <i class="material-icons"
                                [ngClass]="{'active': item.startDate| status :item.endDate}">school</i>-->
                                <img *ngIf="item.Id === selectedId" src="assets/images/courses-card-selected.svg" alt="">
                                <img *ngIf="((!item.endDate) && item.Id !== selectedId)" src="assets/images/courses-card-complete.svg" alt="" >
                                <img *ngIf="((item.endDate) && item.Id !== selectedId)" src="assets/images/courses-card-active.svg" alt="" >                                
                        </span>
                    </div>
                    <div>
                        <span>{{item.title}}</span>
                        <span>{{item.startDate | dateFormat : 'coursetime'}} -
                            {{item.endDate | dateFormat : 'coursetime'}} ET
                            • {{item.location}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div course-list-details *ngIf="course$ | async as course">
            <div upper>
                <h1>{{course.title}}</h1>
                <div>
                    <div>
                        <span>
                             <img src="assets/images/date-table.svg" alt="">
                        </span>
                        <span>{{course.startDate | dateFormat : 'ddd, ll'}}</span>
                    </div>
                    <div>
                        <span>
                             <img src="assets/images/time-table.svg" alt="">
                        </span>
                        <span>{{course.startDate | dateFormat : 'coursetime'}} -
                            {{course.endDate | dateFormat : 'coursetime'}} ET</span>
                    </div>
                    <div>
                        <span>
                             <img src="assets/images/location-table.svg" alt="">
                        </span>
                        <span>{{course.location}}</span>
                    </div>
                    <div>
                        <span>
                            <img src="assets/images/facilitator-solid-table.svg" alt="">
                        </span>
                        <span>{{course.facilitators}}</span>
                    </div>
                    <div>
                        <span>
                            <img src="assets/images/participants-solid-table.svg" alt="">
                        </span>
                        <span>{{course.participants}}</span>
                    </div>
                </div>
                <h4>Description</h4>
                <div [ngClass]="{'hide':isExpand}">
                    <span  [innerHTML]="course.description"></span>
                </div>
                <div (click)="expandText()">{{txtShow}}</div>
            </div>
            <div course-material *ngIf="course.materials">
                <h4>Course Materials</h4>
                <div material-card *ngFor="let material of course.materials">
                    <div a>
                        <span>{{material.title}}</span>
                    </div>
                    <div b>
                        <span>
                            <i class="material-icons" *ngIf="material.video">play_circle_filled</i>
                            {{material.subtitle}}
                        </span>
                        <span (click)="redirectmaterial(material.link)">
                            <i class="material-icons">chevron_right</i>
                        </span>
                    </div>
                </div>
            </div>
            <div assignments *ngIf="course.assignments">
                <h4>Assignments</h4>
                <div assignment-card *ngFor="let assigment of course.assignments">
                    <div a>
                        <span>{{assigment.title}}</span>
                    </div>
                    <div b>
                        <span> {{assigment.subtitle}}</span>
                        <span (click)="redirectmaterial(assigment.link)">
                            <i class="material-icons">chevron_right</i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-contianer *ngIf="coursesList?.length === 0 && programId">
        <p class="background-white m-t-xs">
            <span class="m-l-lg">
                <error-message [errorMessage]="errorMessage"></error-message>
            </span>
        </p>
</ng-contianer>
<div class="loading" *ngIf="showGlobalSpinner">
    <mat-spinner></mat-spinner>
</div>