import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { AutoLogoutService } from 'src/app/auto-logout-service.service';

@Component({
    selector: 'portal-logout',
    templateUrl: './logout.component.html',
    styles: []
})
export class LogoutComponent implements OnInit {
    idp: string = environment.idp;


    constructor(public auth: AuthenticationService, private dialog: MatDialog) { }

    ngOnInit() {
        this.dialog.closeAll();
        localStorage.setItem('logout-event', Math.random().toString());
        this.auth.logOut();
    }
}