import { Component } from '@angular/core';
import { AuthenticationService } from './services/authentication/authentication.service';
import { AutoLogoutService } from './auto-logout-service.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styles: [],
})

export class AppComponent {
    constructor(private auth: AuthenticationService, private auto: AutoLogoutService) {
        this.auth.handleUserAuth();
    }
}
