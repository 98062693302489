<button (click)="download()" class="btn-primary">Export to Excel <img src="assets/icons/excel.svg" alt="" ml10></button>
<div panel-list *ngIf="participants; else loading">
    <div registration-list>
        <div head [attr.mode]="searchmode">
            <div left>
                <!-- <input type="text" placeholder="Search sessions by name or ID">
                <span spacer></span>
                <span><i class="material-icons">search</i></span> -->
            </div>
            <div right>
                <span><i class="material-icons">hotel</i></span>
                <section>
                    <span>Hotel Waitlist</span>
                    <span>Innovative Creative Learning (11/04/19 - 11/08/19) • Max Capacity: 400</span>
                </section>
                <span (click)="toggleRegistration()">
                  <i class="material-icons">clear</i>
                </span>
            </div>
            <span><i class="material-icons">local_library</i></span>
             <div>Registration Status ( {{participants?.length}} )</div>
            <div right-head>
                <!-- <form search>
                    <input type="text" name="search" value="" placeholder="Search participants by name or email">
                </form>
                <span><i class="material-icons"> search</i></span>
                <span><i class="material-icons"> sort </i></span> -->
            </div>
            <!-- <div close>
                <span (click)="toggleRegistration()">
                    <i class="material-icons"> navigate_before</i>
                </span>
                <span>
                    <i class="material-icons"> hotel </i>
                </span>
            </div> -->
        </div>
        <table class="table-fixedheader" [attr.mode]="mode" *ngIf="participants?.length; else displayErrorMessage">
            <thead>
                <th class="edit" (click)="sort('lastName', $event)">
                    <span >Name</span>
                    <i class="material-icons" [ngClass]="{'active':isLastNameAsc}"> arrow_drop_down</i>
                </th>
                <th class="edit" (click)="sort('email', $event)">
                    <span >Email</span>
                    <i class="material-icons" [ngClass]="{'active':isEmailAsc}">arrow_drop_down</i>
                </th>
                <!-- <th class="edit"><span (click)="sort('flights[0]?.Status[0]')">Flight To</span> -->
                    <th class="edit" (click)="sort('flightArrival', $event)"><span>Arrival</span>
                    <i class="material-icons" [ngClass]="{'active':isFlightArrivalStatusAsc}"> arrow_drop_down</i>
                </th>
                <th class="view">
                    <div>
                        <div (click)="sort('lastName', $event)">
                            <span>Name</span>
                            <i class="material-icons" [ngClass]="{'active':isLastNameAsc}"> arrow_drop_down</i>
                        </div>
                        <div (click)="sort('email', $event)">
                            <span>Email</span>
                            <i class="material-icons" [ngClass]="{'active':isEmailAsc}"> arrow_drop_down</i>
                        </div>
                    </div>
                </th>
                <th class="edit" (click)="sort('flightDeparture', $event)">
                    <span>Departure</span>
                    <i class="material-icons" [ngClass]="{'active':isFlightDepartureStatusAsc}">arrow_drop_down</i>
                </th>
                <th class="edit" (click)="sort('inviteeStatus', $event)"><span>Cvent Status</span>
                    <i class="material-icons" [ngClass]="{'active':isInviteeStatusAsc}"> arrow_drop_down</i>
                </th>
                <th class="edit" (click)="sort('registrationType', $event)"><span>Registration Type</span>
                    <i class="material-icons" [ngClass]="{'active':isRegistrationTypeAsc}"> arrow_drop_down</i>
                </th>
                <th class="edit" (click)="sort('hotelStatus', $event)">
                    <span>Hotel Status</span>
                    <i class="material-icons" [ngClass]="{'active':isHotelStatusAsc}">arrow_drop_down</i>
                </th>
                <th class="edit" (click)="sort('courseStatus', $event)">
                    <span>Course Status</span>
                    <i class="material-icons" [ngClass]="{'active':isCourseStatusAsc}">arrow_drop_down</i>
                </th>
                <th class="last-view">
                    <div>
                        <div>
                            <span>Start Date</span>
                            <i class="material-icons">arrow_drop_down</i>
                        </div>
                        <div>
                            <span>End Date</span>
                            <i class="material-icons">arrow_drop_down</i>
                        </div>
                        <div>
                            <span>Room Type</span>
                            <i class="material-icons">arrow_drop_down</i>
                        </div>
                        <div>
                            <span>Date Added</span>
                            <i class="material-icons">arrow_drop_down</i>
                        </div>
                    </div>
                </th>
            </thead>
            <tbody>
                <tr *ngFor="let participant of participants | orderBy: {property: column, direction: direction, isDateSort: isDateSort}">
                    <td class="name edit">
                        <div>
                            <div><span></span></div>

                            <span>{{participant?.lastName}}, {{participant?.firstName}}</span>
                        </div>
                    </td>
                    <td class="edit">
                        <div>
                            <span>{{participant?.email}}</span>
                        </div>
                    </td>
                    <td class="edit">
                        <ng-container *ngIf="participant?.flightArrival; else displayNoFlightBooked">
                            <div>
                                <span>{{participant?.flightArrival | dateFormat : 'll'}}</span> <br>
                            </div>
                            <div class="m-t-xs ">
                                <span class="text-gray-light font-size-14 font-weight-bold">{{participant?.flightArrival | timeFormat}} ET</span>
                            </div>
                        </ng-container>
    
                    </td>
                    <td class="view">
                        <div>
                            <span>{{participant?.lastName}}, {{participant?.firstName}}</span>
                            <span>{{participant?.email}}</span>
                        </div>
                    </td>
                    <td class="default">
                        <div>
                            <span>{{(participant?.hotel?.booking)?.startDate | dateFormat : 'registrationdate'}}</span>
                            <span>{{(participant?.hotel?.booking)?.endDate | dateFormat : 'registrationdate'}}</span>
                            <span>{{(participant?.hotel?.booking)?.roomType}}</span>
                            <span><span> <span>{{(participant?.hotel?.booking)?.requestDate | dateFormat : 'registrationdate'}}</span> </span>
                            </span>
                        </div>
                    </td>
                    <td class="edit">
                        <ng-container  *ngIf="participant?.flightDeparture; else displayNoFlightBooked">
                            <div>
                                <span>{{participant?.flightDeparture | dateFormat : 'll'}}</span> <br>
                            </div>
                            <div class="m-t-xs ">
                                <span class="text-gray-light font-size-14 font-weight-bold">{{participant?.flightDeparture | timeFormat}} ET</span>
                            </div>
                        </ng-container>

                    </td>
                    <td class="edit">
                        <div>
                            <span>{{participant?.inviteeStatus}}</span>
                        </div>
                    </td>
                    <td class="edit">
                        <div>
                            <span>{{participant?.registrationType}}</span>
                        </div>
                    </td>
                    <td class="edit status">
                        <div class="font-size-15">
                            <span class="{{participant?.hotelStatus | statuscolor }} ">{{participant?.hotelStatus}}</span>
                        </div>
                    </td>
                    <td class="edit">
                        <div>
                            <span>{{participant?.courseStatus}}</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ng-template #displayErrorMessage>
        <p class="background-white m-t-xs noResults">
            <span class="m-l-lg">
                <error-message [errorMessage]="errorMessage"></error-message>
            </span>
        </p>
    </ng-template>
</div>

<ng-template #loading>
    <div class="loading" *ngIf="!showLoading">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>

<ng-template #displayNoFlightBooked>
    <div>
        <span>Not Booked</span> <br>
    </div>
</ng-template>

<div *ngIf="showLoading">
    <div class="loading">
        <mat-spinner></mat-spinner>
    </div>
</div>