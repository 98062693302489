<div class="timepicker" [attr.show]="show">
    <div class="time-header">Time</div>
    <mat-form-field class="time-field">
        <mat-label>Hour</mat-label>
        <mat-select [(ngModel)]="hour">
            <mat-option *ngFor="let hour of hours" [value]="hour">{{hour}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="time-field">
        <mat-label>Minute</mat-label>
        <mat-select [(ngModel)]="minute">
            <mat-option *ngFor="let minute of minutes" [value]="minute">{{minute}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="time-field">
        <mat-select [(ngModel)]="ampm">
            <mat-option value="AM">AM</mat-option>
            <mat-option value="PM">PM</mat-option>
        </mat-select>
    </mat-form-field>
    <div class="time-footer">
        <div class="time-buttons">
            <button type="button" class="btn" (click)="cancelClick()">Cancel</button>
            <button type="button" class="btn btn-primary" (click)="updateClick()">Done</button>
        </div>
    </div>
</div>