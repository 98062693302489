import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'portal-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    idp: string = environment.idp;

    constructor(public auth: AuthenticationService, private dialog: MatDialog) {
    }

    ngOnInit() {
        this.dialog.closeAll();
    }
}