import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timeTotal' })
export class TimeTotal implements PipeTransform {
    transform(values: Array<any>): string {
        let total = 0;
        if (values) {
            values.forEach(i => {
                let sTime = new Date('1901/01/01 ' + i.checkInTime);
                let eTime = new Date('1901/01/01 ' + i.checkOutTime);
                if (sTime && eTime) {
                    total += eTime.getTime() - sTime.getTime();
                }
            });
        }
        return Math.round(total / 60000) + " mins";
    }
}