import { IProgram } from "src/app/models/IProgram";

export namespace JourneyActions {

    export class SetItineraryProgram {
        static readonly type = '[Journey] Set Itinerary Program';
        constructor(public itineraryProgram: any) {}
    }

    export class SetCurrentItineraryPrograms {
        static readonly type = '[Journey] Set Current Itinerary Program';
        constructor(public currentItineraryProgram: IProgram[]) {}
    }

    export class SetUpcomingItineraryPrograms {
        static readonly type = '[Journey] Set Future Itinerary Program';
        constructor(public upcomingItineraryProgram: IProgram[]) {}
    }

    export class SetSelecedProgram {
        static readonly type = '[Journey] Set Selected Program';
        constructor(public selectedProgram: IProgram) {}
    }

    export class SetSelecedProgramTravelInfo {
        static readonly type = '[Journey] Set Travel info for Selected Program';
        constructor(public travelInfo: any) {}
    }

    
    export class SetSelecedProgramRoomInfo {
        static readonly type = '[Journey] Set Room info for Selected Program';
        constructor(public roomInfo: any) {}
    }
}