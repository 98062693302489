import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';


// Common Interface to implement Leaving component.
export interface canComponentExit {
  canLeaveComponent: (nextState : string) => boolean | Observable<boolean> ;
}

@Injectable({
  providedIn: 'root'
})
export class UnSavedChangesGuard implements CanDeactivate<canComponentExit> {

  constructor(private dialog: MatDialog) { }

  canDeactivate(
    component: canComponentExit, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot):
    Observable<boolean> | Promise<boolean> | boolean {

      if(component.canLeaveComponent){
        return component.canLeaveComponent(nextState?.url);
      }
      return true;
  }

}
