import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { API } from 'aws-amplify';

API.configure({
    endpoints: [
        {
            name: "portal",
            endpoint: environment.aws.apiEndpoint
        }
    ]
});

@Injectable({
    providedIn: 'root'
})
export class ZoneService {
    zone:any=`[
        {
        "Abbreviation": "A",
        "Offset": "+1"
    },
    {
        "Abbreviation": "A",
        "Offset": "+01"
    },
       {
        "Abbreviation": "ACDT",
        "Offset": "+10:30"
    },
    {
        "Abbreviation": "ACST",
        "Offset": "+9:30"
    },
    {
        "Abbreviation": "ACST",
        "Offset": "+09:30"
    },
    {
        "Abbreviation": "ACT",
        "Offset": "-5"
    },
    {
        "Abbreviation": "ACT",
        "Offset": "-05"
    },
    {
        "Abbreviation": "ACT",
        "Offset": "+9:30 / +10:30"
    },
    {
        "Abbreviation": "ACWST",
        "Offset": "+8:45"
    },
    {
        "Abbreviation": "ACWST",
        "Offset": "+08:45"
    },
    {
        "Abbreviation": "ADT",
        "Offset": "+4"
    },
    {
        "Abbreviation": "ADT",
        "Offset": "+04"
    },
    {
        "Abbreviation": "ADT",
        "Offset": "-3"
    },
    {
        "Abbreviation": "ADT",
        "Offset": "-03"
    },
    {
        "Abbreviation": "AEDT",
        "Offset": "+11"
    },
    {
        "Abbreviation": "AEST",
        "Offset": "+10"
    },
    {
        "Abbreviation": "AET",
        "Offset": "+10:00 / +11:00"
    },
    {
        "Abbreviation": "AFT",
        "Offset": "+4:30"
    },
    {
        "Abbreviation": "AFT",
        "Offset": "+04:30"
    },
    {
        "Abbreviation": "AKDT",
        "Offset": "-8"
    },
    {
        "Abbreviation": "AKST",
        "Offset": "-9"
    },
    {
        "Abbreviation": "AKST",
        "Offset": "-09"
    },
    {
        "Abbreviation": "ALMT",
        "Offset": "+6"
    },
    {
        "Abbreviation": "ALMT",
        "Offset": "+06"
    },
    {
        "Abbreviation": "AMST",
        "Offset": "-3"
    },
    {
        "Abbreviation": "AMST",
        "Offset": "-03"
    },
    {
        "Abbreviation": "AMST",
        "Offset": "+5"
    },
    {
        "Abbreviation": "AMST",
        "Offset": "+05"
    },
    {
        "Abbreviation": "AMT",
        "Offset": "-4"
    },
    {
        "Abbreviation": "AMT",
        "Offset": "-04"
    },
    {
        "Abbreviation": "AMT",
        "Offset": "+4"
    },
    {
        "Abbreviation": "AMT",
        "Offset": "+04"
    },
    {
        "Abbreviation": "ANAST",
        "Offset": "+12"
    },
    {
        "Abbreviation": "ANAT",
        "Offset": "+12"
    },
    {
        "Abbreviation": "AQTT",
        "Offset": "+5"
    },
    {
        "Abbreviation": "AQTT",
        "Offset": "+05"
    },
    {
        "Abbreviation": "ART",
        "Offset": "-3"
    },
    {
        "Abbreviation": "ART",
        "Offset": "-03"
    },
    {
        "Abbreviation": "AST",
        "Offset": "+3"
    },
    {
        "Abbreviation": "AST",
        "Offset": "+03"
    },
    {
        "Abbreviation": "AST",
        "Offset": "-4"
    },
    {
        "Abbreviation": "AST",
        "Offset": "-04"
    },
    {
        "Abbreviation": "AT",
        "Offset": "-4:00 / -3:00"
    },
    {
        "Abbreviation": "AT",
        "Offset": "-04:00 / -03:00"
    },
    {
        "Abbreviation": "AWDT",
        "Offset": "+9"
    },
    {
        "Abbreviation": "AWDT",
        "Offset": "+09"
    },
    {
        "Abbreviation": "AWST",
        "Offset": "+8"
    },
    {
        "Abbreviation": "AWST",
        "Offset": "+08"
    },
    {
        "Abbreviation": "AZOST",
        "Offset": "+0"
    },
    {
        "Abbreviation": "AZOST",
        "Offset": "+00"
    },
   {
        "Abbreviation": "AZOT",
        "Offset": "-1"
    },
    {
        "Abbreviation": "AZOT",
        "Offset": "-01"
    },
    {
        "Abbreviation": "AZST",
        "Offset": "+5"
    },
    {
        "Abbreviation": "AZST",
        "Offset": "+05"
    },
    {
        "Abbreviation": "AZT",
        "Offset": "+4"
    },
    {
        "Abbreviation": "AZT",
        "Offset": "+04"
    },
    {
        "Abbreviation": "AoE",
        "Offset": "-12"
    },
    {
        "Abbreviation": "B",
        "Offset": "+2"
    },
    {
        "Abbreviation": "B",
        "Offset": "+02"
    },
    {
        "Abbreviation": "BNT",
        "Offset": "+8"
    },
    {
        "Abbreviation": "BNT",
        "Offset": "+08"
    },
    {
        "Abbreviation": "BOT",
        "Offset": "-4"
    },
    {
        "Abbreviation": "BOT",
        "Offset": "-04"
    },
    {
        "Abbreviation": "BRST",
        "Offset": "-2"
    },
    {
        "Abbreviation": "BRST",
        "Offset": "-02"
    },
    {
        "Abbreviation": "BRT",
        "Offset": "-3"
    },
    {
        "Abbreviation": "BRT",
        "Offset": "-03"
    },
    {
        "Abbreviation": "BST",
        "Offset": "+6"
    },
    {
        "Abbreviation": "BST",
        "Offset": "+06"
    },
    {
        "Abbreviation": "BST",
        "Offset": "+11"
    },
    {
        "Abbreviation": "BST",
        "Offset": "+1"
    },
    {
        "Abbreviation": "BST",
        "Offset": "+01"
    },
    {
        "Abbreviation": "BTT",
        "Offset": "+6"
    },
    {
        "Abbreviation": "BTT",
        "Offset": "+06"
    },
    {
        "Abbreviation": "C",
        "Offset": "+3"
    },
    {
        "Abbreviation": "C",
        "Offset": "+03"
    },
    {
        "Abbreviation": "CAST",
        "Offset": "+8"
    },
    {
        "Abbreviation": "CAST",
        "Offset": "+08"
    },
    {
        "Abbreviation": "CAT",
        "Offset": "+2"
    },
    {
        "Abbreviation": "CAT",
        "Offset": "+02"
    },
    {
        "Abbreviation": "CCT",
        "Offset": "+6:30"
    },
    {
        "Abbreviation": "CCT",
        "Offset": "+06:30"
    },
    {
        "Abbreviation": "CDT",
        "Offset": "-5"
    },
    {
        "Abbreviation": "CDT",
        "Offset": "-4"
    },
    {
        "Abbreviation": "CEST",
        "Offset": "+2"
    },
    {
        "Abbreviation": "CET",
        "Offset": "+1"
    },
    {
        "Abbreviation": "CDT",
        "Offset": "-05"
    },
    {
        "Abbreviation": "CDT",
        "Offset": "-04"
    },
    {
        "Abbreviation": "CEST",
        "Offset": "+02"
    },
    {
        "Abbreviation": "CET",
        "Offset": "+01"
    },
    {
        "Abbreviation": "CHADT",
        "Offset": "+13:45"
    },
    {
        "Abbreviation": "CHAST",
        "Offset": "+12:45"
    },
    {
        "Abbreviation": "CHOST",
        "Offset": "+09"
    },
    {
        "Abbreviation": "CHOT",
        "Offset": "+08"
    },
    {
        "Abbreviation": "CHUT",
        "Offset": "+10"
    },
    {
        "Abbreviation": "CIDST",
        "Offset": "-04"
    },
    {
        "Abbreviation": "CIST",
        "Offset": "-05"
    },
    {
        "Abbreviation": "CHOST",
        "Offset": "+09"
    },
    {
        "Abbreviation": "CHOT",
        "Offset": "+08"
    },
    {
        "Abbreviation": "CHUT",
        "Offset": "+10"
    },
    {
        "Abbreviation": "CIDST",
        "Offset": "-04"
    },
    {
        "Abbreviation": "CIST",
        "Offset": "-05"
    },
    {
        "Abbreviation": "CKT",
        "Offset": "-10"
    },
    {
        "Abbreviation": "CLST",
        "Offset": "-03"
    },
    {
        "Abbreviation": "CLT",
        "Offset": "-04"
    },
    {
        "Abbreviation": "COT",
        "Offset": "-05"
    },
    {
        "Abbreviation": "CST",
        "Offset": "-06"
    },
    {
        "Abbreviation": "CST",
        "Offset": "+08"
    },
    {
        "Abbreviation": "CST",
        "Offset": "-05"
    },
    {
        "Abbreviation": "CLST",
        "Offset": "-03"
    },
    {
        "Abbreviation": "CLT",
        "Offset": "-04"
    },
    {
        "Abbreviation": "COT",
        "Offset": "-05"
    },
    {
        "Abbreviation": "CST",
        "Offset": "-06"
    },
    {
        "Abbreviation": "CST",
        "Offset": "+08"
    },
    {
        "Abbreviation": "CST",
        "Offset": "-05"
    },
    {
        "Abbreviation": "CT",
        "Offset": "-6:00 / -5:00"
    },
    {
        "Abbreviation": "CVT",
        "Offset": "-01"
    },
    {
        "Abbreviation": "CXT",
        "Offset": "+07"
    },
    {
        "Abbreviation": "CT",
        "Offset": "-6:00 / -5:00"
    },
    {
        "Abbreviation": "CVT",
        "Offset": "-01"
    },
    {
        "Abbreviation": "CXT",
        "Offset": "+07"
    },
    {
        "Abbreviation": "ChST",
        "Offset": "+10"
    },
    {
        "Abbreviation": "D",
        "Offset": "+04"
    },
    {
        "Abbreviation": "DAVT",
        "Offset": "+07"
    },
    {
        "Abbreviation": "DDUT",
        "Offset": "+10"
    },
    {
        "Abbreviation": "E",
        "Offset": "+05"
    },
    {
        "Abbreviation": "EASST",
        "Offset": "-05"
    },
    {
        "Abbreviation": "EAST",
        "Offset": "-06"
    },
    {
        "Abbreviation": "EAT",
        "Offset": "+03"
    },
    {
        "Abbreviation": "ECT",
        "Offset": "-05"
    },
    {
        "Abbreviation": "EDT",
        "Offset": "-04"
    },
    {
        "Abbreviation": "EEST",
        "Offset": "+03"
    },
    {
        "Abbreviation": "EET",
        "Offset": "+02"
    },
    {
        "Abbreviation": "EGST",
        "Offset": "+0"
    },
    {
        "Abbreviation": "EGST",
        "Offset": "+00"
    },
    {
        "Abbreviation": "EGT",
        "Offset": "-1"
    },
    {
        "Abbreviation": "EST",
        "Offset": "-5"
    },
    {
        "Abbreviation": "EST",
        "Offset": "-05"
    },
    {
        "Abbreviation": "ET",
        "Offset": "-5:00"
    },
    {
        "Abbreviation": "EST",
        "Offset": "-05:00"
    },
    {
        "Abbreviation": "ET",
        "Offset": "-4:00"
    },
    {
        "Abbreviation": "EST",
        "Offset": "-04:00"
    },
    {
        "Abbreviation": "ET",
        "Offset": "-5:00 / -4:00"
    },
    {
        "Abbreviation": "F",
        "Offset": "+06"
    },
    {
        "Abbreviation": "FET",
        "Offset": "+03"
    },
    {
        "Abbreviation": "F",
        "Offset": "+06"
    },
    {
        "Abbreviation": "FET",
        "Offset": "+03"
    },
    {
        "Abbreviation": "FJST",
        "Offset": "+13"
    },
    {
        "Abbreviation": "FJT",
        "Offset": "+12"
    },
    {
        "Abbreviation": "FKST",
        "Offset": "-3"
    },
    {
        "Abbreviation": "FKT",
        "Offset": "-4"
    },
    {
        "Abbreviation": "FNT",
        "Offset": "-2"
    },
    {
        "Abbreviation": "G",
        "Offset": "+7"
    },
    {
        "Abbreviation": "GALT",
        "Offset": "-6"
    },
    {
        "Abbreviation": "GAMT",
        "Offset": "-9"
    },
    {
        "Abbreviation": "GET",
        "Offset": "+4"
    },
    {
        "Abbreviation": "GFT",
        "Offset": "-3"
    },
    {
        "Abbreviation": "FKST",
        "Offset": "-3"
    },
    {
        "Abbreviation": "FKT",
        "Offset": "-04"
    },
    {
        "Abbreviation": "FNT",
        "Offset": "-02"
    },
    {
        "Abbreviation": "G",
        "Offset": "+07"
    },
    {
        "Abbreviation": "GALT",
        "Offset": "-06"
    },
    {
        "Abbreviation": "GAMT",
        "Offset": "-09"
    },
    {
        "Abbreviation": "GET",
        "Offset": "+04"
    },
    {
        "Abbreviation": "GFT",
        "Offset": "-03"
    },
    {
        "Abbreviation": "GILT",
        "Offset": "+12"
    },
    {
        "Abbreviation": "GMT",
        "Offset": "+0"
    },
    {
        "Abbreviation": "GST",
        "Offset": "+4"
    },
    {
        "Abbreviation": "GST",
        "Offset": "-2"
    },
    {
        "Abbreviation": "GYT",
        "Offset": "-4"
    },
    {
        "Abbreviation": "H",
        "Offset": "+8"
    },
    {
        "Abbreviation": "HDT",
        "Offset": "-9"
    },
    {
        "Abbreviation": "HKT",
        "Offset": "+8"
    },
    {
        "Abbreviation": "HOVST",
        "Offset": "+8"
    },
    {
        "Abbreviation": "HOVT",
        "Offset": "+7"
    },
    {
        "Abbreviation": "GMT",
        "Offset": "+00"
    },
    {
        "Abbreviation": "GST",
        "Offset": "+04"
    },
    {
        "Abbreviation": "GST",
        "Offset": "-02"
    },
    {
        "Abbreviation": "GYT",
        "Offset": "-04"
    },
    {
        "Abbreviation": "H",
        "Offset": "+08"
    },
    {
        "Abbreviation": "HDT",
        "Offset": "-09"
    },
    {
        "Abbreviation": "HKT",
        "Offset": "+08"
    },
    {
        "Abbreviation": "HOVST",
        "Offset": "+08"
    },
    {
        "Abbreviation": "HOVT",
        "Offset": "+07"
    },
    {
        "Abbreviation": "HST",
        "Offset": "-10"
    },
    {
        "Abbreviation": "I",
        "Offset": "+9"
    },
    {
        "Abbreviation": "ICT",
        "Offset": "+07"
    },
    {
        "Abbreviation": "IDT",
        "Offset": "+03"
    },
    {
        "Abbreviation": "IOT",
        "Offset": "+06"
    },

    {
        "Abbreviation": "I",
        "Offset": "+09"
    },
    {
        "Abbreviation": "ICT",
        "Offset": "+07"
    },
    {
        "Abbreviation": "IDT",
        "Offset": "+03"
    },
    {
        "Abbreviation": "IOT",
        "Offset": "+06"
    },

    {
        "Abbreviation": "IRDT",
        "Offset": "+4:30"
    },
    {
        "Abbreviation": "IRKST",
        "Offset": "+9"
    },
    {
        "Abbreviation": "IRKT",
        "Offset": "+8"
    },
    {
        "Abbreviation": "IRST",
        "Offset": "+3:30"
    },
    {
        "Abbreviation": "IRDT",
        "Offset": "+4:30"
    },
    {
        "Abbreviation": "IRKST",
        "Offset": "+09"
    },
    {
        "Abbreviation": "IRKT",
        "Offset": "+08"
    },
    {
        "Abbreviation": "IRST",
        "Offset": "+03:30"
    },
    {
        "Abbreviation": "IST",
        "Offset": "+5:30"
    },
    {
        "Abbreviation": "IST",
        "Offset": "+05:30"
    },
    {
        "Abbreviation": "IST",
        "Offset": "+1"
    },
    {
        "Abbreviation": "IST",
        "Offset": "+01"
    },
    {
        "Abbreviation": "IST",
        "Offset": "+2"
    },
    {
        "Abbreviation": "IST",
        "Offset": "+02"
    },
    {
        "Abbreviation": "JST",
        "Offset": "+9"
    },
    {
        "Abbreviation": "JST",
        "Offset": "+09"
    },
    {
        "Abbreviation": "K",
        "Offset": "+10"
    },
    {
        "Abbreviation": "KGT",
        "Offset": "+6"
    },
    {
        "Abbreviation": "KGT",
        "Offset": "+06"
    },
    {
        "Abbreviation": "KOST",
        "Offset": "+11"
    },
    {
        "Abbreviation": "KRAST",
        "Offset": "+8"
    },
    {
        "Abbreviation": "KRAT",
        "Offset": "+7"
    },
    {
        "Abbreviation": "KST",
        "Offset": "+9"
    },
    {
        "Abbreviation": "KUYT",
        "Offset": "+4"
    },
    {
        "Abbreviation": "KRAST",
        "Offset": "+08"
    },
    {
        "Abbreviation": "KRAT",
        "Offset": "+07"
    },
    {
        "Abbreviation": "KST",
        "Offset": "+09"
    },
    {
        "Abbreviation": "KUYT",
        "Offset": "+04"
    },
    {
        "Abbreviation": "L",
        "Offset": "+11"
    },
    {
        "Abbreviation": "LHDT",
        "Offset": "+11"
    },
    {
        "Abbreviation": "LHST",
        "Offset": "+10:30"
    },
    {
        "Abbreviation": "LINT",
        "Offset": "+14"
    },
    {
        "Abbreviation": "M",
        "Offset": "+12"
    },
    {
        "Abbreviation": "MAGST",
        "Offset": "+12"
    },
    {
        "Abbreviation": "MAGT",
        "Offset": "+11"
    },
    {
        "Abbreviation": "MART",
        "Offset": "-9:30"
    },
    {
        "Abbreviation": "MAWT",
        "Offset": "+5"
    },
    {
        "Abbreviation": "MDT",
        "Offset": "-6"
    },
    {
        "Abbreviation": "MAWT",
        "Offset": "+05"
    },
    {
        "Abbreviation": "MDT",
        "Offset": "-06"
    },
    {
        "Abbreviation": "MHT",
        "Offset": "+12"
    },
    {
        "Abbreviation": "MMT",
        "Offset": "+6:30"
    },
    {
        "Abbreviation": "MSD",
        "Offset": "+4"
    },
    {
        "Abbreviation": "MSK",
        "Offset": "+3"
    },
    {
        "Abbreviation": "MST",
        "Offset": "-7"
    },
    {
        "Abbreviation": "MT",
        "Offset": "-7:00 / -6:00"
    },
    {
        "Abbreviation": "MUT",
        "Offset": "+04"
    },
    {
        "Abbreviation": "MVT",
        "Offset": "+05"
    },
    {
        "Abbreviation": "MYT",
        "Offset": "+08"
    },
    {
        "Abbreviation": "N",
        "Offset": "-01"
    },

    {
        "Abbreviation": "MSD",
        "Offset": "+04"
    },
    {
        "Abbreviation": "MSK",
        "Offset": "+03"
    },
    {
        "Abbreviation": "MST",
        "Offset": "-07"
    },
    {
        "Abbreviation": "MT",
        "Offset": "-07:00 / -06:00"
    },
    {
        "Abbreviation": "MUT",
        "Offset": "+04"
    },
    {
        "Abbreviation": "MVT",
        "Offset": "+05"
    },
    {
        "Abbreviation": "MYT",
        "Offset": "+08"
    },
    {
        "Abbreviation": "N",
        "Offset": "-01"
    },
    {
        "Abbreviation": "NCT",
        "Offset": "+11"
    },
    {
        "Abbreviation": "NDT",
        "Offset": "-2:30"
    },
    {
        "Abbreviation": "NFDT",
        "Offset": "+12"
    },
    {
        "Abbreviation": "NFT",
        "Offset": "+11"
    },
    {
        "Abbreviation": "NOVST",
        "Offset": "+7"
    },
    {
        "Abbreviation": "NOVT",
        "Offset": "+7"
    },
    {
        "Abbreviation": "NOVT",
        "Offset": "+07"
    },
    {
        "Abbreviation": "NPT",
        "Offset": "+5:45"
    },
    {
        "Abbreviation": "NRT",
        "Offset": "+12"
    },
    {
        "Abbreviation": "NST",
        "Offset": "-3:30"
    },
    {
        "Abbreviation": "NUT",
        "Offset": "-11"
    },
    {
        "Abbreviation": "NZDT",
        "Offset": "+13"
    },
    {
        "Abbreviation": "NZST",
        "Offset": "+12"
    },
    {
        "Abbreviation": "O",
        "Offset": "-2"
    },
    {
        "Abbreviation": "O",
        "Offset": "-02"
    },
    {
        "Abbreviation": "OMSST",
        "Offset": "+7"
    },
    {
        "Abbreviation": "OMST",
        "Offset": "+6"
    },
    {
        "Abbreviation": "ORAT",
        "Offset": "+5"
    },
    {
        "Abbreviation": "P",
        "Offset": "-3"
    },
    {
        "Abbreviation": "PDT",
        "Offset": "-7"
    },
    {
        "Abbreviation": "PET",
        "Offset": "-5"
    },
    {
        "Abbreviation": "OMSST",
        "Offset": "+07"
    },
    {
        "Abbreviation": "OMST",
        "Offset": "+06"
    },
    {
        "Abbreviation": "ORAT",
        "Offset": "+05"
    },
    {
        "Abbreviation": "P",
        "Offset": "-03"
    },
    {
        "Abbreviation": "PDT",
        "Offset": "-07"
    },
    {
        "Abbreviation": "PET",
        "Offset": "-05"
    },
    {
        "Abbreviation": "PETST",
        "Offset": "+12"
    },
    {
        "Abbreviation": "PETT",
        "Offset": "+12"
    },
    {
        "Abbreviation": "PGT",
        "Offset": "+10"
    },
    {
        "Abbreviation": "PHOT",
        "Offset": "+13"
    },
    {
        "Abbreviation": "PHT",
        "Offset": "+8"
    },
    {
        "Abbreviation": "PKT",
        "Offset": "+5"
    },
    {
        "Abbreviation": "PMDT",
        "Offset": "-2"
    },
    {
        "Abbreviation": "PMST",
        "Offset": "-03"
    },
    {
        "Abbreviation": "PHT",
        "Offset": "+08"
    },
    {
        "Abbreviation": "PKT",
        "Offset": "+05"
    },
    {
        "Abbreviation": "PMDT",
        "Offset": "-02"
    },
    {
        "Abbreviation": "PMST",
        "Offset": "-03"
    },
    {
        "Abbreviation": "PONT",
        "Offset": "+11"
    },
    {
        "Abbreviation": "PST",
        "Offset": "-8"
    },
    {
        "Abbreviation": "PST",
        "Offset": "-08"
    },
    {
        "Abbreviation": "PT",
        "Offset": "-8:00 / -7:00"
    },
    {
        "Abbreviation": "PWT",
        "Offset": "+09"
    },
    {
        "Abbreviation": "PYST",
        "Offset": "-03"
    },
    {
        "Abbreviation": "PYT",
        "Offset": "-04"
    },
    {
        "Abbreviation": "PYT",
        "Offset": "+8:30"
    },
   {
        "Abbreviation": "Q",
        "Offset": "-4"
    },
    {
        "Abbreviation": "QYZT",
        "Offset": "+6"
    },
    {
        "Abbreviation": "R",
        "Offset": "-05"
    },
    {
        "Abbreviation": "RET",
        "Offset": "+4"
    },
    {
        "Abbreviation": "ROTT",
        "Offset": "-3"
    },
    {
        "Abbreviation": "S",
        "Offset": "-6"
    },
    {
        "Abbreviation": "SAKT",
        "Offset": "+11"
    },
    {
        "Abbreviation": "SAMT",
        "Offset": "+4"
    },
    {
        "Abbreviation": "SAST",
        "Offset": "+2"
    },
    {
        "Abbreviation": "SBT",
        "Offset": "+11"
    },
    {
        "Abbreviation": "SCT",
        "Offset": "+4"
    },
    {
        "Abbreviation": "SGT",
        "Offset": "+8"
    },
    {
        "Abbreviation": "SRET",
        "Offset": "+11"
    },
    {
        "Abbreviation": "SRT",
        "Offset": "-3"
    },
    {
        "Abbreviation": "SST",
        "Offset": "-11"
    },
    {
        "Abbreviation": "SYOT",
        "Offset": "+3"
    },
    {
        "Abbreviation": "T",
        "Offset": "-7"
    },
    {
        "Abbreviation": "TAHT",
        "Offset": "-10"
    },
    {
        "Abbreviation": "TFT",
        "Offset": "+5"
    },
    {
        "Abbreviation": "TJT",
        "Offset": "+5"
    },
    {
        "Abbreviation": "TKT",
        "Offset": "+13"
    },
    {
        "Abbreviation": "TLT",
        "Offset": "+9"
    },
    {
        "Abbreviation": "TMT",
        "Offset": "+5"
    },
    {
        "Abbreviation": "TOST",
        "Offset": "+14"
    },
    {
        "Abbreviation": "TOT",
        "Offset": "+13"
    },
    {
        "Abbreviation": "TRT",
        "Offset": "+3"
    },
    {
        "Abbreviation": "TVT",
        "Offset": "+12"
    },
    {
        "Abbreviation": "U",
        "Offset": "-8"
    },
    {
        "Abbreviation": "ULAST",
        "Offset": "+9"
    },
    {
        "Abbreviation": "ULAT",
        "Offset": "+8"
    },
    {
        "Abbreviation": "UTC",
        "Offset": "UTC"
    },
    {
        "Abbreviation": "UYST",
        "Offset": "-2"
    },
    {
        "Abbreviation": "UYT",
        "Offset": "-3"
    },
    {
        "Abbreviation": "UZT",
        "Offset": "+5"
    },
    {
        "Abbreviation": "V",
        "Offset": "-9"
    },
    {
        "Abbreviation": "VET",
        "Offset": "-4"
    },
    {
        "Abbreviation": "VLAST",
        "Offset": "+11"
    },
    {
        "Abbreviation": "VLAT",
        "Offset": "+10"
    },
    {
        "Abbreviation": "VOST",
        "Offset": "+6"
    },
    {
        "Abbreviation": "VUT",
        "Offset": "+11"
    },
    {
        "Abbreviation": "W",
        "Offset": "-10"
    },
    {
        "Abbreviation": "WAKT",
        "Offset": "+12"
    },
    {
        "Abbreviation": "WARST",
        "Offset": "-3"
    },
    {
        "Abbreviation": "WAST",
        "Offset": "+2"
    },
    {
        "Abbreviation": "WAT",
        "Offset": "+1"
    },
    {
        "Abbreviation": "WEST",
        "Offset": "+1"
    },
    {
        "Abbreviation": "WET",
        "Offset": "+0"
    },
    {
        "Abbreviation": "WFT",
        "Offset": "+12"
    },
    {
        "Abbreviation": "WGST",
        "Offset": "-2"
    },
    {
        "Abbreviation": "WGT",
        "Offset": "-3"
    },
    {
        "Abbreviation": "WIB",
        "Offset": "+7"
    },
    {
        "Abbreviation": "WIT",
        "Offset": "+9"
    },
    {
        "Abbreviation": "WITA",
        "Offset": "+8"
    },
    {
        "Abbreviation": "WST",
        "Offset": "+14"
    },
    {
        "Abbreviation": "WST",
        "Offset": "+1"
    },
    {
        "Abbreviation": "WT",
        "Offset": "+0"
    },
    {
        "Abbreviation": "X",
        "Offset": "-11"
    },
    {
        "Abbreviation": "Y",
        "Offset": "-12"
    },
    {
        "Abbreviation": "YAKST",
        "Offset": "+10"
    },
    {
        "Abbreviation": "YAKT",
        "Offset": "+9"
    },
    {
        "Abbreviation": "YAPT",
        "Offset": "+10"
    },
    {
        "Abbreviation": "YEKST",
        "Offset": "+6"
    },
    {
        "Abbreviation": "YEKT",
        "Offset": "+5"
    },
    {
        "Abbreviation": "Z",
        "Offset": "+0"
    }]`;

    constructor() { }

    getZones(): any {
        return JSON.parse(this.zone);
    }
    
}


