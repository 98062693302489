<div left>
    <div class="title">
        <div>
            <img alt="kpmg logo" class="dark-logo" src="assets/images/white.png" />
        </div>
    </div>
    <div class="left-content">
        <p>Error: 401 Not Authorized</p>
        <span>
            Sorry, we cannot verify that you are authorized to access this <br />
            page or resource. Please <a href="#">contact administration</a> if you have <br />
            any questions or concerns.
        </span>
    </div>
</div>
<div right>
    <p>4<img src="assets/images/401@2x.png" />1</p>
</div>