<header>
    <div up>
        <div logo>
            <div class="title">
                <div>
                    <img alt="kpmg logo" class="dark-logo" src="assets/images/kpmg-logo-nav.svg" />
                </div>
            </div>
        </div>
        <div tabs>
            <a *ngFor="let tab of tabs" [ngClass]="{'active': tab.Active}">
                <span (click)="click(tab.componentName)"  [ngClass]="{'disabled': tab.disable}">
                    <!-- <i class="-icons ">{{tab.icon}}</i>{{tab.name}} -->
                    <img src="{{tab.src}}" alt=""><span>{{tab.name}}</span>

                </span>
            </a>

        </div>
        <div class="user-group">
            <span user-name>{{(user$ | async)?.firstName.charAt(0) | uppercase}}{{(user$ | async)?.lastName.charAt(0) | uppercase}}</span>
            <span>
                <span username #lastName1>{{(user$ | async)?.firstName}} {{(user$ | async)?.lastName}}</span>
            </span>
            <span class="caret" (click)="toggleUserRole()"></span>
            <section class="profile-dropdown" *ngIf="isUserShow"> 
                    <span (click)="routAdmin('journey')" [attr.isDisabled]="true">Journey Portal</span>
                    <span (click)="routAdmin('facilitator')"  *ngIf="isFacilitator">Facilitator</span>                    
                    <span (click)="routAdmin('admin')" *ngIf="isAdmin">Admin Portal</span>
                    <span (click)="routAdmin('signOut')">Sign Out</span> 
                </section>

        </div>
    </div>
</header>
<!-- <div register-lakehouse *ngIf="selectedBox == i"> -->

<div *ngIf="componentName!=='home' && selectedProgram?.name">
    <div header-summary>
        <p class=" text-cyan-blue font-weight-bold">{{ selectedProgram?.name}} • {{ selectedProgram?.startDate | dateFormat : 'mdyy'}} - {{ selectedProgram?.endDate | dateFormat : 'mdyy'}}
            <span id="course-list-dropdown" class="caret cursor-pointer  inline-block"   (click)="toogleDropdown()"> </span>
        </p>

        <div class="panel-row position-absolute" *ngIf="showDropdown"  (clickOutside)="hideShowDropdown($event)">
            <programs-list [currentProgramsList]="currentPrograms$ | async" [upcomingProgramsList]="upcomingPrograms$ | async" [programId]="programId" (selectedProgram)="setSelectedProgram($event)"></programs-list>
        </div>
        
    </div>
</div>