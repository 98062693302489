import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchFilter' })
export class SearchFilterPipe implements PipeTransform {

  transform(items: any[], searchTerm: string, propName: string): any {
    if (!searchTerm) {
      return items;
    }
    searchTerm = searchTerm.toLowerCase();
    return items.filter(
      item => (propName ? item[propName] : item).toLowerCase().includes(searchTerm)
    );
  }
}