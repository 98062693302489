import { Pipe, PipeTransform } from '@angular/core';

/*
This pipe will give the number of days between two dates
*/
@Pipe({ name: 'timeDiff' })
export class TimeDiff implements PipeTransform {
    transform(startTime: any, endTime: any): number {
        var sTime = new Date('1901/01/01 ' + startTime);
        var eTime = new Date('1901/01/01 ' + endTime);
        var difference = eTime.getTime() - sTime.getTime(); // This will give difference in milliseconds
        var resultInMinutes = Math.round(difference / 60000);
        return Math.floor(resultInMinutes);
    }
}