import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IProgram } from 'src/app/models/IProgram';

@Component({
  selector: 'programs-list',
  templateUrl: './programs-list.component.html',
  styles: [
  ]
})
export class ProgramsListComponent {

  @Input() public programId: number;

  @Input() public currentProgramsList: IProgram[] = [];

  @Input() public upcomingProgramsList: IProgram[] = [];

  @Output() selectedProgram: EventEmitter<IProgram> = new EventEmitter<IProgram>();

  constructor() {
   }

  public setSelectedProgram(program: IProgram): void {
    this.selectedProgram.emit(program);
  }

}
