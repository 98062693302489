<ng-container *ngIf="programs; else loading">
<journey-header></journey-header>
<router-outlet></router-outlet>
<journey-footer></journey-footer>
</ng-container>
<ng-template #loading>
    <div class="loading" *ngIf="!programs">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
