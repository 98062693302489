import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fromEvent, Observable, Subscription } from 'rxjs';

@Component({
    selector: 'portal-error',
    templateUrl: './error.component.html',
    styles: []
})
export class ErrorComponent implements OnInit, OnDestroy {
    paramValue; any;
    onlineEvent: Observable<Event>;
    offlineEvent: Observable<Event>;
    subscriptions: Subscription[] = [];
    connectionStatus: string;

    constructor(private activateRoute: ActivatedRoute) { }

    ngOnInit() {
        this.activateRoute.params.subscribe(param => {
            this.paramValue = param['id'];
            if (this.paramValue == undefined)
                this.paramValue = 404;

        });
        this.getSatus();
    }

    getSatus() {
        this.onlineEvent = fromEvent(window, 'online');
        this.offlineEvent = fromEvent(window, 'offline');
        this.subscriptions.push(this.onlineEvent.subscribe(() => {
            this.paramValue = 'online';
        }));
        this.subscriptions.push(this.offlineEvent.subscribe(() => {
            this.paramValue = 'offline';
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}