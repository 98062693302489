import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'dateFormat' })
export class DateFormatPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value != undefined) {
            switch (args) {
                case 'coursedate': value = moment(value).format('dddd M/D'); break;
                case 'coursetime': value = moment(value).format('hh:mm A'); break;
                case 'detaildate': value = moment(value).format('ddd, MMM D'); break;
                case 'flight': value = moment(value).format('ddd, MMM DD, YYYY '); break;
                case 'fulldayname': value = moment(value).format('dddd'); break;
                case 'dayindigit': value = moment(value).format('DD'); break;
                case 'daywithyear': value = (moment(value).format('MMM, ')) + "’" + (moment(value).format('YYYY')).substr(2, 2) ; break;
                case 'mmddyy': value = moment(value).format('MM/DD/YY'); break;
                case 'mmddyyyy': value = moment(value).format('MMM DD, YYYY'); break;
                case 'mdyy': value = moment(value).format('M/D/YY'); break;
                case 'dmdyy': value = moment(value).format('dddd M/D/YY'); break;
                case 'mm-ddd-yy': value = moment(value).format('MM-DD-YY'); break;
                case 'yyyy-mm-dd': value = moment(value).format('YYYY-MM-DD'); break;
                case 'ddd, ll': value = moment(value).format('ddd, ll'); break;
                case 'll': value = moment(value).format('ll'); break;
                case 'overviewdate': value = moment(value).format('ddd, MMM DD, YYYY '); break;
                case 'ddd, ll withoutyear': value = moment(value).format('ddd, MMM D'); break;
                case 'll withoutyear': value = moment(value).format('MMM D'); break;
                case 'dd/mm/yyyy': value = moment(value).format('dd/mm/yyyy '); break;
                case 'mm/dd/yy': value = moment(value).format('MM/DD/YY'); break;
                case 'mm/dd/yyyy': value = moment(value).format('MM/DD/YYYY'); break;
                case 'rosterTime': value = moment.utc(value, 'hh:mm').format('hh:mm A'); break;
                case 'missedAddedTime':
                    value = moment.utc(value, 'hh:mm').format('hh:mm A');
                    if (value == "Invalid date") {
                        value = 'Add Time';
                    }
                    break;
                case 'missedStartTime':
                    value = moment.utc(value, 'hh:mm').format('hh:mm A');
                    if (value == "Invalid date") {
                        value = 'Start Time';
                    }
                    break;
                case 'missedEndTime':
                    value = moment.utc(value, 'hh:mm').format('hh:mm A');
                    if (value == "Invalid date") {
                        value = 'End Time';
                    }
                    break;
                case 'MMM dd - dd, yyyy':
                    let getProgramStartDateMonth = moment(value.programStartDate).format('MMM');
                    let getProgramEndDateMonth = moment(value.programEndDate).format('MMM');
                    if (getProgramStartDateMonth === getProgramEndDateMonth) {
                        value = moment(value.programStartDate).format('MMM D') + " - " + moment(value.programEndDate).format('D, YYYY');
                    }
                    else {
                        value = moment(value.programStartDate).format('MMM D') + " - " + moment(value.programEndDate).format('ll');
                    }
                    break;
            }
            return value;
        }
    }
}