import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeZoneAbbreviations'
})
export class TimeZoneAbbreviationsPipe implements PipeTransform {

  transform(value: String, ...args: unknown[]): String {

    return value.slice(0, 1) + value.slice(2);
  }

}
