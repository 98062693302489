import { Component, OnInit } from '@angular/core';
import { ParticipantService } from '../../../services/aws/participant/participant.service';
import { IParticipant } from '../../../models/IParticipant';
import { take } from 'rxjs/operators';

@Component({
    selector: 'participants',
    templateUrl: './participants.component.html',
    styles: []
})
export class ParticipantsComponent implements OnInit {
    participants: IParticipant;
    isDesc: boolean = false;
    column: string = 'lastName';
    direction: number;
    innercolumn: string = 'name';
    islastnameAsc: boolean = false;
    isemailAsc: boolean = false;
    isphoneAsc: boolean = false;
    isprogramAsc: boolean = false;

    constructor(private participant: ParticipantService) { }

    ngOnInit() {
        this.participant.getList()
        .pipe(take(1))
        .subscribe((data) => {
            this.participants = data; 
        });
    }
 
    sort(property) {
        this.isDesc = !this.isDesc;
        this.column = property;
        this.direction = this.isDesc ? 1 : -1;
        this.innercolumn = property;
        this.switchIcon(property);
    }

    sortSub(property) {
        this.isDesc = !this.isDesc;
        this.innercolumn = property;
        this.direction = this.isDesc ? 1 : -1;
        this.switchIcon(property);
    }
    
    switchIcon(property) {
        switch (property) {
            case 'lastName':
                this.islastnameAsc = !this.islastnameAsc;
                break;
            case 'email':
                this.isemailAsc = !this.isemailAsc;
                break;
            case 'mobilePhone':
                this.isphoneAsc = !this.isphoneAsc;
                break;
            case 'name':
                this.isprogramAsc = !this.isprogramAsc;
        }
    }
}
