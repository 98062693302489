<div breadcrumbs>
    <ol class="Breadcrumb">
        <li class="Breadcrumb-item">
            <a class="Breadcrumb-link" routerLink="/admin/dashboard">
                Dashboard
            </a>
        </li>
        <li class="Breadcrumb-item">
            <a class="Breadcrumb-link" routerLink="/admin/participants">
                Participants
            </a>
        </li>
    </ol>
</div>
<div participants>
    <h1>Participants</h1>
</div>
<div participants-list>
    <div head>
        <span><i class="material-icons">local_library</i></span>
        <div>All Participants 760</div>
        <div right-head>
            <form search>
                <input type="text" name="search" value="" placeholder="Search participants by name or email">
            </form>
            <span><i class="material-icons"> search</i></span>
            <span><i class="material-icons"> sort </i></span>
        </div>
    </div>
    <table sticky>
        <thead>
            <th (click)="sort('lastName')">
                <span>Name</span>
                <i class="material-icons" [ngClass]="{'active':islastnameAsc}"> arrow_drop_down</i>
            </th>
            <th>
                <span (click)="sort('email')">Email</span>
                <i class="material-icons" [ngClass]="{'active':isemailAsc}">arrow_drop_down</i>
            </th>
            <th (click)="sort('mobilePhone')"><span>phone</span>
                <i class="material-icons" [ngClass]="{'active':isphoneAsc}"> arrow_drop_down</i>
            </th>
            <th (click)="sortSub('name')">
                <span>Programe</span>
                <i class="material-icons" [ngClass]="{'active':isprogramAsc}">arrow_drop_down</i>
            </th>
            <th>
                <span>type</span>
            </th>
        </thead>
    </table>
    <table>
        <tbody>
            <tr *ngFor="let item of participants | orderBy: {property: column, direction: direction}">
                <td>
                    <div>
                        <span></span>
                        <span>{{item.lastName}}, {{item.firstName}}</span>
                    </div>
                </td>
                <td>
                    <div>
                        <span>{{item.email}}</span>
                    </div>
                </td>
                <td>
                    <div>
                        <div>
                            <span>{{item.mobilePhone}}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div>
                        <div #programItem *ngFor="let programitmes of item.programs | orderBy: {property: innercolumn, direction: direction}">
                            <span>{{programitmes}}</span>
                            <span>{{programitmes.startDate}} - {{programitmes.endDate}}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div *ngFor="let participanttype of item.programs">
                        <div *ngFor="let pt of participanttype.participantTypes">
                            {{pt}}
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>