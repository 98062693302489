<div [ngSwitch]="paramValue">
    <div *ngSwitchCase="403">
        <error-forbidden></error-forbidden>
    </div>
    <div *ngSwitchCase="401">
        <error-notauthorized></error-notauthorized>
    </div>
    <div *ngSwitchCase="'offline'">
        <error-network></error-network>
    </div>
    <div *ngSwitchDefault>
        <error-pagenotfound></error-pagenotfound>
    </div>
</div>