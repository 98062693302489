import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { ReportService } from '../../../../services/aws/report/report.service';
import { IReport } from '../../../../models/IReport';
import { IErrorMessage } from 'src/app/widgets/error-message/error-message.interface';
import { API_USER_DEFINED_MESSAGE } from 'src/app/widgets/error-message/error-message.const';

@Component({
    selector: 'report-arrival',
    templateUrl: './arrival.component.html',
})
export class ReportArrivalComponent implements OnInit {
   
    reports: IReport[];
    public errorMessage: IErrorMessage = {};
    showLoading: any;
    isDesc: boolean = false;
    column: string = 'lastName';
    direction: number;
    isNameAsc: boolean = false;
    isMobileAsc: boolean = false;
    isStatusAsc: boolean = false;
    isAirLineAsc: boolean = false;
    isFlightNoAsc: boolean = false;
    isArriveDateAsc: boolean = false;
    isArriveTimeAsc: boolean = false;
    //isDateAsc: boolean = false;

    breadcrumbOptions = {
        breadcrumb: [
            {
                label: 'Dashboard',
                itemclass: 'Breadcrumb-item',
                linkclass: 'Breadcrumb-link',
                link: '/admin/dashboard'
            },
            // {
            //     label: 'Reporting',
            //     itemclass: 'Breadcrumb-item',
            //     linkclass: 'Breadcrumb-link',
            // },
            {
                label: 'Flight Arrival',
                itemclass: 'Breadcrumb-item',
                linkclass: 'Breadcrumb-link',
                link: '/admin/reports/arrival'
            }
        ],
        dropdowns: [

        ]
    };

    constructor(private reportService: ReportService) { }

    ngOnInit() {

        this.errorMessage = {};
        this.reportService.getReport('flightArrivals').pipe(map(data => data))
        .subscribe((response: any) => {
            this.errorMessage.text = response?.length? '' : API_USER_DEFINED_MESSAGE.EMPTY_RESPONSE;
            this.reports = response;
        },
        () => {
            this.reports = [];
            this.errorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
        });
    }

    download() {
        this.reportService.getFlightReport('flightArrivals');
    }

    sort(property) {
        this.isDesc = !this.isDesc;
        this.column = property;
        this.direction = this.isDesc ? 1 : -1;
        switch (property) {
            case 'lastName':
                this.isNameAsc = !this.isNameAsc;
                break;
            case 'mobileNumber':
                this.isMobileAsc = !this.isMobileAsc;
                break;
            case 'status':
                this.isStatusAsc = !this.isStatusAsc;
                break;
            case 'airline':
                this.isAirLineAsc = !this.isAirLineAsc;
                break;
            case 'flightNumber':
                this.isFlightNoAsc = !this.isFlightNoAsc;
                break;
            case 'arrivalDate':
                this.isArriveDateAsc = !this.isArriveDateAsc;
                break;
            case 'arrivalTime':
                this.isArriveTimeAsc = !this.isArriveTimeAsc;
        }
    }
}
