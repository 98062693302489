<div *ngIf="programOverviewErrorMessage?.text">
        <p class="background-white m-t-xs m-b-xxl">
            <span class="m-l-lg">
                <error-message [errorMessage]="programOverviewErrorMessage"></error-message>
            </span>
        </p>
    </div>

<div >
    <ng-container *ngIf="program; else loading">

    
    <div overview-dashboard>
        <div>
            <div>
                <div library>
                    <!-- <i class="material-icons">local_library</i> -->
                    <img src="assets/icons/programs-banner-stamp.svg" alt="">
                </div>
                <div>
                    <span> {{program?.startDate | dateFormat : 'ddd, ll withoutyear'}} - {{program?.endDate | dateFormat : 'ddd, ll'}} </span>
                    <span>{{program?.startDate | getdays : program?.endDate}} Days <ng-container *ngIf="program?.programType"> &bull; {{ program?.programType }}</ng-container> </span>
                </div>
            </div>
            <div>
                <span icl>{{program?.name}}</span>
            </div>
        </div>
        <div right-section>
            <div top-cards>
                <div>
                    <span>
                        <!-- <i class="material-icons">import_contacts</i> -->
                        <img src="assets/icons/courses-banner.svg" alt="">
                    </span>
                    <div>
                        <span>{{(program.counts)?.classes || 0}}</span>
                        <span>Classes</span>
                    </div>
                </div>
                <div>
                    <span>
                        <!-- <i class="material-icons">local_play</i> -->
                        <img src="assets/icons/events.svg" alt="">
                    </span>
                    <div>
                        <span>{{(program.counts)?.events || 0}}</span>
                        <span>Sessions</span>
                    </div>
                </div>
                <!-- <div>
                    <span>
                        <i class="material-icons">person_pin_circle </i>
                    </span>
                    <div>
                        <span>{{(program.counts)?.activities || 0}}</span>
                        <span>House Activities</span>
                    </div>
                </div> -->
            </div>
            <div bottom-cards>
                <div>
                    <span>
                        <!-- <i class="material-icons">person</i> -->
                        <img src="assets/icons/participants-banner.svg" alt="">
                    </span>
                    <div>
                        <span>{{(program.counts)?.participants || 0}}</span>
                        <span>Participants</span>
                    </div>
                </div>
                <div>
                    <span>
                        <!-- <i class="material-icons">school</i> -->
                        <img src="assets/icons/facilitators-banner.svg" alt="">
                    </span>
                    <div>
                        <span>{{(program.counts)?.facilitators || 0}}</span>
                        <span>Facilitators</span>
                    </div>
                </div>
                <!-- <div>
                    <span>
                        <i class="material-icons">mic</i>
                    </span>
                    <div>
                        <span>{{(program.counts)?.speakers || 0}}</span>
                        <span>Speakers</span>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <div list-group>
        <div>
            <span>{{program?.cEventId}}</span>
            <span>Cvent Event ID</span>
        </div>
        <!-- <div>
            <span>HMN9SGDKR4K</span>
            <span>Cvent RFP ID</span>
        </div> -->
        <div>
            <span>{{program?.sabaProgramId}}</span>
            <span>Saba Program Code</span>
        </div>
        <div>
            <span>{{program?.hyatAcronym}}</span>
            <span>Hyatt Acronym</span>
        </div>
        <div>
            <span>{{program?.hyattBookingNo}}</span>
            <span>Hyatt Booking Ref No.</span>
        </div>
        <div>
            <span>{{program?.concurId}}</span>
            <span>Concur Meeting Star ID</span>
        </div>
    </div>
    <div view-mode>
        <span>Schedule</span>
        <!-- <span>{{program?.startDate | days : program?.endDate}} Days Left</span> -->
        <span class="{{program?.startDate | classcolor : program?.endDate }}">{{program?.startDate | days : program?.endDate}}</span>
        <!-- <span spacer></span>
        <span>
            <i class="material-icons">search</i>
        </span>
        <span>
            <i class="material-icons">filter_list</i>
        </span>
        <span divider></span>
        <span>
            <i class="material-icons">view_comfy</i>
        </span>
        <span>
            <i class="material-icons">view_headline</i>
        </span> -->
    </div>
</ng-container>
    <div *ngIf="programList; else loading">
        <div *ngFor="let list of programList | orderBy: {property: 'key', direction: 1, isDateSort: true} ;let i= index">
            <div date-timeline>
                <div>
                    <span>DAY {{i+1}} OF {{programList.length}}</span>
                    <span>&#8226;</span>
                    <span>{{list.key | dateFormat : 'overviewdate'}}</span>
                </div>
            </div>
            <div level #morning>
                    <span *ngIf="morning.nextSibling.innerHTML.length > 48">Morning Session</span>
            </div>
            <div card-section-morning>
                <div *ngFor="let item of list.value | programs:'morning'">
                    <div a>
                        <span [ngClass]="{'active': curTime > item.startTime}">
                            <i class="material-icons"> {{item?.type}} </i>
                        </span>
                    </div>
                    <div b>
                        <div>
                            <span title="{{item?.title}}">{{item?.title}}</span>
                        </div>
                        <div>
                            <span>{{item?.startTime}} - {{item?.endTime}} </span>
                            <span>&#8226;</span>
                            <span>{{item?.location}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div level #afternoon>
                    <span *ngIf="afternoon.nextSibling.innerHTML.length  > 48">Afternoon Session</span>
            </div>
            <div card-section-morning>
                <div *ngFor="let item of list.value | programs: 'afternoon'">
                    <div a>
                        <span>
                            <i class="material-icons"> {{item?.type}} </i>
                        </span>
                    </div>
                    <div b>
                        <div>
                            <span [ngClass]="{'active':curTime > item.startTime}" title="{{item?.title}}">{{item?.title}}</span>
                        </div>
                        <div>
                            <span>{{item?.startTime}} - {{item?.endTime}} </span>
                            <span>&#8226;</span>
                            <span>{{item?.location}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div level #evening>
                    <span *ngIf="evening.nextSibling.innerHTML.length > 48">Evening Session</span>
            </div>
            <div card-section-morning>
                <div *ngFor="let item of list.value | programs: 'evening'" #eveningSession>
                    <div a>
                        <span>
                            <i class="material-icons green"> {{item?.type}} </i>
                        </span>
                    </div>
                    <div b>
                        <div>
                            <span [ngClass]="{'active':curTime > item.startTime}" title="{{item?.title}}">{{item?.title}}</span>
                        </div>
                        <div>
                            <span>{{item?.startTime}} - {{item?.endTime}} </span>
                            <span>&#8226;</span>
                            <span>{{item?.location || 'TBD'}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="loading" *ngIf="!showLoading">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>

<div *ngIf="showLoading">
    <div class="loading">
        <mat-spinner></mat-spinner>
    </div>
</div>