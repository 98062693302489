<!-- <div breadcrumbs>
   
</div> -->
<div breadcrumbs [option]='option'>
</div>
<div setting>
    <h1>Settings</h1>
</div>
<div setting-container>
    <div setting-panel>
        <div>User Access</div>
        <div>
            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old</p>
        </div>
        <span spacer></span>
        <div>Manage Access</div>
        <div>Manage Groups / Roles</div>
    </div>
    <div setting-panel>
        <div>Ad-hoc Notifications</div>
        <div>
            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old</p>
        </div>
        <span spacer></span>
        <div><a routerLink="/admin/settings/notification" routerLinkActive="active">Ad-hoc Notifications</a></div>
        <div><a routerLink="/admin/settings/notification" routerLinkActive="active">Notification Templates</a></div>
    </div>
    <div setting-panel>
        <div>Global Mobile Settings</div>
        <div>
            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old</p>
        </div>
        <span spacer></span>
        <div>Session Timeout</div>
        <div>Archive Settings</div>
    </div>
    <div setting-panel>
        <div>Content Management</div>
        <div>
            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old</p>
        </div>
        <span spacer></span>
        <div>About Lakehouse</div>
        <div>Fitness Classes</div>
    </div>
</div>