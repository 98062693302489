import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { ProgramService } from "src/app/services/aws/program/program.service";
import { Subject } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";
import { IParticipants } from "src/app/models/IClassRoster";
import * as moment from 'moment';
import { ClassRosterService } from "src/app/services/aws/class/class-roster.service";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { IConfirmation } from "src/app/models/IConfirmation";
import { ReportService } from "src/app/services/aws/report/report.service";
import { Router } from '@angular/router';
import { API_USER_DEFINED_MESSAGE } from 'src/app/widgets/error-message/error-message.const';
import { TimeDiff } from 'src/app/widgets/pipes/timeDiff.pipe';
import { SessionDetailsMetadataModel } from "../models/session-details-metadata.model";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { IProgram } from "src/app/models/IProgram";
import { IDownloadExcel, ISessionDialogInput, ISessionsMetaData } from "../interface/sessions.interface";
import { SessionsService } from "../services/sessions.service";
import { saveAs } from "file-saver";
import { ClassRosterComponent } from "../../classes/class-detail/class-roster/class-roster.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'app-session-details-cpe',
  templateUrl: './session-details-cpe.component.html',
  styleUrls: ['./session-details-cpe.component.scss'
  ],
  providers: [TimeDiff]
})


export class SessionDetailsCpeComponent extends ClassRosterComponent implements OnInit, OnDestroy {

  public programInfo: IProgram;
  protected unSubscribe: Subject<any> = new Subject();
  public metaData: ISessionsMetaData;
  public sessionMetaData: SessionDetailsMetadataModel;

  constructor(protected http: HttpClientModule, protected programService: ProgramService,
    protected classRosterService: ClassRosterService,
    protected auth: AuthenticationService,
    protected reportService: ReportService,
    protected timeDiff: TimeDiff,
    protected router: Router,
    protected dialog: MatDialog,
    protected programservice: ProgramService,
    @Inject(MAT_DIALOG_DATA) public data: ISessionDialogInput,
    protected sessionsService: SessionsService,
    protected snackBar:MatSnackBar) {
    super(http, programService, classRosterService, auth, reportService, timeDiff, router,snackBar);
    this.participantDirection = 1;
    this.direction = 1;
    this.emaildetail$ = this.auth.getUserEmail();

    this.getProgramInfo();
    this.getSessionsMetaData();
  }


  ngOnInit() {
    this.emaildetail$.pipe(take(1)).subscribe(x => {
      this.email = x;
      this.getConfirmation(this.data.programId, this.data.sessionId);
  });

    this.getRosterParticipants();

    if (moment(this.classDate).format('YYYY-MM-DD HH:mm') <= moment(new Date()).format('YYYY-MM-DD HH:mm')) {
      this.enableCheckbox = false;
    }
  }

  public dialogclose(): void {
    this.dialog.closeAll();
  }

  public getProgramInfo(): void {
    this.programservice.get(this.data.programId)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((response: IProgram) => {
        this.programInfo = response;
      });
  }

  public getSessionsMetaData(): void {

    this.sessionsService.getSessionMetadata(this.data.programId, this.data.sessionId)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((data: ISessionsMetaData) => {
        this.sessionMetaData = new SessionDetailsMetadataModel(data);

      },
        () => {
          this.sessionMetaData = new SessionDetailsMetadataModel(<ISessionsMetaData>{});
        });
  }

  checkInStatus($event, participantId, data) {
    let participantCheckStatus = {
      participantId: participantId,
      checkIn: {
        status: $event.source ? $event.source.checked ? true : false : false,
        updatedBy: 'Admin',
        createdBy: this.email
      }
    };
    participantCheckStatus.checkIn["recordId"] = data?.checkIn?.recordId;
    this.classRosterService.edit(this.data.programId, this.data.sessionId, ($event.source.id) ? "update" : "insert", "checkInStatus");
    this.classRosterService.update(participantCheckStatus);
    this.classRosterService.save().pipe(take(1)).subscribe(
      () => {
        this.getRosterParticipants();
      });
  }

  cancelWidgetConfirm() {
    this.cancelModel = !this.cancelModel;
    this.prepareAddParticipantsInfo(this.participants);
    this.toggleRow(null, this.cancelType);
  }

  keepCancelWidgetEditing(type) {
    this.participants = JSON.parse(JSON.stringify(this._participants));
    this.cancelType = type;
    this.cancelModel = !this.cancelModel;
  }

  togglePicker(participantId?, timeMissed = null, type = null, target = null) {
    this.listText = '';
    this.selectedPickerId = (timeMissed) ? timeMissed.id : 0;
    this.selectedType = type;
    this.selectedTarget = target;
    if (this.selectedTarget === 'participant' && participantId) {
      this.participantSelectedIndex = this.participants.findIndex((val: any) => val.participantId === participantId);
      this.addedParticipantsInfo[this.participantSelectedIndex].selectedType = type;
      this.addedParticipantsInfo[this.participantSelectedIndex].selectedTarget = target;
    }
    let copy: any;
    if (type) {
      switch (this.selectedTarget) {
        case "participation":
          let participation = (timeMissed) ? timeMissed : {
            checkInTime: "Add Time",
            checkOutTime: "Add Time",
            checkInBy: 'Admin',
            createdBy: this.email
          };
          copy = {
            participantId: participantId,
            participationTime: [participation]
          };
          this.classRosterService.edit(this.data.programId, this.data.sessionId, (timeMissed) ? "update" : "insert", target, copy);
          this.classRosterService.update(copy);
          break;
        default:
          let miss = (timeMissed) ? timeMissed : {
            classDate: moment(this.classDate).format('YYYY-MM-DD'),
            checkInTime: "Add Time",
            checkOutTime: "Add Time",
            checkInBy:'Admin',
            createdBy: this.email
          };
          copy = {
            participantId: participantId,
            totalMissed: [miss]
          };
          this.classRosterService.edit(this.data.programId, this.data.sessionId, (timeMissed) ? "update" : "insert", target, copy);
          this.classRosterService.update(copy);
      }
    }
  }

  saveConfirm() {
    this.doConfirm = false;
    this.classRosterService
      .postConfirmation(
        {
          facilitatorId: this.email,
          confirmed: true,
          role: 'Admin'
        } as IConfirmation,
        this.data.programId, this.data.sessionId
      )
      .pipe(take(1))
      .subscribe(() => {
        this.getConfirmation(this.data.programId, this.data.sessionId);
        this.isConfirmed = true;
      },
      () => {
          this.snackBar.open('Failed to save confirmation of the attendance. Please try again.', 'OK', {
              duration: 8000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['error-snackbar']
          });
      })
  }

  getRosterParticipants() {
    this.participantsErrorMessage = {};

    this.programService.getRosterParticipants(this.data.programId, this.data.sessionId).pipe(
      takeUntil(this.destroy$),
      map(data => data))
      .subscribe((response: IParticipants[]) => {
        this.participantsErrorMessage.text = response?.length ? '' : API_USER_DEFINED_MESSAGE.EMPTY_RESPONSE;
        const currentInfo = JSON.parse(JSON.stringify(this.participants || []));
        response.map(x => x.fullName = x?.lastName + ', ' + x?.firstName);
        this.participants = response;
        this._participants = JSON.parse(JSON.stringify(this.participants));
        this.prepareAddParticipantsInfo(currentInfo);
      },
        (err) => {
          this.participants = [];
          this._participants = JSON.parse(JSON.stringify(this.participants));
          this.participantsErrorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
        });
  }

  delete(pid, time, target, index) {

    this.missedInfo.id = time.id;
    this.missedInfo.checkInBy = time.checkInBy;
    this.missedInfo.comments = time.comments;
    this.missedInfo.createdBy = this.email;
    this.arrMissedInfo.push(this.missedInfo);
    this.deletedInfo.participantId = pid;
    this.deletedInfo.totalMissed = this.arrMissedInfo;
    this.classRosterService.edit(this.data.programId, this.data.sessionId, "delete", "participant");
    this.classRosterService.update(this.deletedInfo);
    this.addedParticipantsInfo[index].enableSaveCta = true;
    this.participantSelectedIndex = index;
    this.setSaveBarValues(time);


  }


  public downloadReport(): void {
    this.sessionsService.downloadSessionsReport(this.data.programId, this.data.sessionId)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((data: IDownloadExcel) => {
        this.save(data.data, 'SessionData');
      });

  }
  protected save(data: string, name: string): void {
    if (data) {
      const blob = new Blob([atob(data)], { type: 'text/csv' });
      saveAs(blob, name + '-' + (new Date()).toLocaleString() + '.csv');
    }
  }
}