import { Component, EventEmitter, Output, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, Validators, FormBuilder} from '@angular/forms';
import { FILTER_TYPE, IFilterRequest, IFilterResponse } from './filter.interface';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    providers: [DatePipe]
})

export class FilterComponent implements OnInit {

    @Output() public appliedDateFilter = new EventEmitter<IFilterResponse>();

    @Output() public clearFilter = new EventEmitter<void>();

    @Input() public filterInput: IFilterRequest;

    @Input() public searchableOptions: string[] = [];

    public filteredOptions: Observable<string[]>;

    @Input() public displayFilter: boolean = false;
    public isFilterActive: boolean = false;
    public filterForm: FormGroup;
    public minDate: Date;
    public maxDate: Date;
    @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger }) autoComplete: MatAutocompleteTrigger;

    constructor(private fb: FormBuilder, public datepipe: DatePipe) { }

    public ngOnInit(): void {
        this.minDate = this.filterInput.minDate;
        this.maxDate = this.filterInput.maxDate;
        this.createFilterForm(this.filterInput);
        if(this.filterInput?.filterType){
            this.isFilterActive = true;
        }
        window.addEventListener('scroll', this.scrollEvent, true);
    }

    scrollEvent = (): void => {
        if(this.autoComplete?.panelOpen)
          this.autoComplete?.updatePosition();
    };
    /**
     * Function used to create from filter
     * @param filterInput 
     */
    createFilterForm(filterInput) {
        this.filterForm = this.fb.group({
            filterType:  [filterInput.filterType],
            onDate: [{ value: filterInput.onDate, disabled: filterInput.onDate == null ? true : false }, [Validators.required]],
            startDate: [{ value: filterInput.startDate, disabled: filterInput.startDate == null ? true : false }, [Validators.required]],
            endDate: [{ value: filterInput.endDate, disabled: filterInput.endDate == null ? true : false }, [Validators.required]],
            searchableOptions: [{ value: '', disabled: true }, []]
        });
        if (this.searchableOptions.length) {
            this.filterForm.get('searchableOptions').addValidators(Validators.required);
            this.filteredOptions = this.filterForm.get('searchableOptions').valueChanges.pipe(
                startWith(''),
                map(value => this._filter(value || '')),
            );
        }
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.searchableOptions.filter(option => option.toLowerCase().includes(filterValue));
    }

    toogleFilter() {
        this.displayFilter = !this.displayFilter;
    }

    applyFilter() {
        const filterType: string = (this.filterForm.get('filterType').value);
        const startEndDates = this.getStartEndDate(filterType);
        const output: IFilterResponse = {
            filterType: FILTER_TYPE[filterType],
            startDate: startEndDates.startDate,
            endDate: startEndDates.endDate,
            selectedAutoCompleteValue:  this.filterForm.get('searchableOptions').value
        };
        this.appliedDateFilter.emit(output);
        this.displayFilter = false;
    }

    /**
     * Function used to get start date
     * @param filterType 
     * @returns 
     */
    private getStartEndDate(filterType: string): IFilterRequest {
        const startEnd = {
            startDate: new Date(),
            endDate: new Date()
        };
        if (filterType === FILTER_TYPE.ON) {
            const onDate = this.filterForm.get('onDate').value;
            startEnd.startDate = onDate;
            startEnd.endDate = onDate;
        } else if (filterType === FILTER_TYPE.FROM) {
            startEnd.startDate = this.filterForm.get('startDate').value;
            startEnd.endDate = this.filterForm.get('endDate').value;
        }
        return startEnd;
    }

    public resetFilter() {
        this.resetForm();
        this.filterForm.setErrors({ 'invalid': true });
        this.isFilterActive = false;
        this.clearFilter.emit();
        this.filterInput = {};
    }

    public filterTypeChange(type: FILTER_TYPE): void {
        this.isFilterActive = true;
        this.resetForm();
        this.filterForm.get('filterType').setValue(FILTER_TYPE[type]);
        switch (type) {
            case FILTER_TYPE.ON:
                this.filterForm.controls['onDate'].enable();
                this.filterForm.controls['onDate'].markAsUntouched();
                break;
            case FILTER_TYPE.FROM:
                this.filterForm.controls['startDate'].markAsUntouched();
                this.filterForm.controls['startDate'].markAsUntouched();
                this.filterForm.controls['startDate'].enable();
                this.filterForm.controls['endDate'].enable();
                break;
            case FILTER_TYPE.CREATED_BY:
                this.filterForm.controls['searchableOptions'].markAsUntouched();
                this.filterForm.controls['searchableOptions'].enable();
                break;
        }
    }

    private resetForm(): void {
        this.filterForm.reset();
        this.filterForm.controls['onDate'].disable();
        this.filterForm.controls['startDate'].disable();
        this.filterForm.controls['endDate'].disable();
        this.filterForm.controls['searchableOptions'].disable();
    }
}