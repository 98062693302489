<ol class="Breadcrumb">
    <li *ngFor="let item of option.breadcrumb" ngClass={{item.itemclass}}>
        <a ngClass={{item.linkclass}} routerLink={{item.link}} routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
        [class.disabled]="item.link === hash">
            {{item.label}}
        </a>
    </li>
    <li *ngIf="option.dropdowns !== undefined">
        <mat-select [(value)]="selected" (selectionChange)="itemchange($event)">
            <mat-option *ngFor="let item of option.dropdowns" [value]="[item.value]" [disabled]="item.value === selected">
                <a routerLink={{item.value}}>
                    {{item.text}}                
                </a>
            </mat-option>
        </mat-select>
    </li>
</ol>