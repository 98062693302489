export interface  IRecipients{
recipients: IRecipient[];
}

export interface IRecipient{
    firstName: string;
    lastName: string;
    email: string,
    participantId: string;
    name?: string; // Combination of Lastname, Firstname
}

export interface ICategoryData{
    name?: string;
    id?: string;
    _id?: string; // Since backend is not providing  unique ID, UI is generating custom unique ID
    dtStart?: string;
    dtEnd?: string;
    email?: string
    display?: string;
    dtStartTime?: string;
    dtEndTime?: string;
    checked?: boolean;
}

export class  IAttributes{
    attributes: IAttribute[];
 }
    

export class IAttribute{
    name: string;
    id: string;
    dtStart: string;
    dtEnd: string;
    email: string;
    display: string;
  
    constructor(name: string,id: string,dtStart: string,dtEnd: string, email: string, display: string){
        this.name = name;
        this.id = id;
        this.dtStart = dtStart;
        this.dtEnd = dtEnd;
        this.email = email;
        this.display = display;
    }
}


export interface ICount{
    count:string;
}

export class IMessage{

    type: string;
    title: string;
    body: string;
    // eslint-disable-next-line camelcase
    participant_ids: number[];

    constructor(type: string, title: string, body: string, particpantids: number[]){
        this.type = type;
        this.title = title;
        this.body = body;
        // eslint-disable-next-line camelcase
        this.participant_ids = particpantids;
    }
}



