import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { IUser } from "../models/IUser";
import { UserActions } from "./user.actions";

export interface UserStateModel {
    user: IUser
}

@State<UserStateModel>({
    name: 'user',
    defaults: {
        user: null
    }
})
@Injectable()
export class UserState {

    constructor() {}

    @Selector()
    static user(state: UserStateModel){
        return state.user;
    }

    @Action(UserActions.SetUser)
    setUser(ctx: StateContext<UserStateModel>, action: UserActions.SetUser) {
        ctx.patchState({
            user: action.user
        });
    }

}
