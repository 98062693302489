import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, distinctUntilChanged, mergeMap, takeUntil } from 'rxjs/operators';
import { ItineraryService } from '../../../services/aws/itinerary/itinerary.service';
import { Select } from '@ngxs/store';
import { JourneyState } from '../journey.state';
import { IProgram } from 'src/app/models/IProgram';
import { IFlight, IFlightDetail, ILegs } from 'src/app/models/IFlight';

@Component({
    selector: 'journey-flight',
    templateUrl: './flight.component.html',
    styles: []
})
export class FlightComponent implements OnInit, OnDestroy {
    
    public destroy$: Subject<boolean> = new Subject<boolean>();
    
    preRegister: boolean = false;

    public showGlobalSpinner: boolean = true;

    public displayFlightInformation: boolean = false;

    flightOneLegs:  ILegs & IFlight;
    flightTwoLegs:  ILegs & IFlight;
    showTravelTips: boolean = false;

    @Select(JourneyState.getSelectedProgram) itineraryProgram$: Observable<IProgram>;

    constructor(private itinerary: ItineraryService) { }

    ngOnInit() {
        if(this.noDataAvailable()){
            this.preRegister = true;
        }else{
            this.itineraryProgram$
                .pipe(
                    distinctUntilChanged(),
                    // skipWhile(itineraryProgram => !itineraryProgram?.id),
                    mergeMap((itineraryProgram) => this.itinerary.getFlight(itineraryProgram?.id)),
                    takeUntil(this.destroy$)
                )
                .subscribe((data: IFlight[]) => {
                    this.showGlobalSpinner = false;
                    this.flightOneLegs = null;
                    this.flightTwoLegs = null;
                    const flightInformation: IFlight = data[0];
                    this.displayFlightInformation = flightInformation?.flights?.length > 0;
                    this.preRegister = !this.displayFlightInformation;
                    if (flightInformation?.flights?.length > 0) {
                        const processedFlightNumbers = new Map();
                        flightInformation?.flights.map((flight: IFlightDetail) => {
                            if (!processedFlightNumbers.has(flight?.flightNumber)) {
                                processedFlightNumbers.set(flight?.flightNumber, flight);
                                const lastleg: ILegs = flight.legs[flight.legs?.length - 1];
                                const firstLeg: ILegs = flight.legs[0];
                                let referenceToUpdate: ILegs & IFlight = <ILegs & IFlight>{};
                                referenceToUpdate = <ILegs & IFlight>firstLeg;
                                referenceToUpdate.endTime = lastleg?.arrivalTime;
                                referenceToUpdate.bookingId = flightInformation?.bookingId;
                                referenceToUpdate.flightNo = +firstLeg?.flightNo || +flight?.flightNumber;
                                referenceToUpdate.endAirport = lastleg?.endAirport;
                                referenceToUpdate.ticketNumber = flightInformation?.ticketNumber;
                                if (!this.flightOneLegs) {
                                    this.flightOneLegs = referenceToUpdate;
                                } else {
                                    this.flightTwoLegs = referenceToUpdate;
                                }
                            }
                        });
                    }
                    
                },
                () => {
                    this.showGlobalSpinner = false;
                    this.displayFlightInformation = false;
                    this.preRegister = true;
                });
        }
    }

    noDataAvailable(): boolean{
        let isNoDataAvailable = false;
        this.itineraryProgram$.pipe(take(1)).subscribe(
            (data) => {
                isNoDataAvailable = !data;
        });
        return isNoDataAvailable;
    }

    public ngOnDestroy(){
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}