import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { of } from "rxjs/internal/observable/of";
import { tap } from "rxjs/operators";
import { IClass, IProgram, ISession } from "src/app/models/IProgram";
import { ParticipantService } from "src/app/services/aws/participant/participant.service";
import { ProgramService } from "src/app/services/aws/program/program.service";
import { ProgramActions } from "./program.action";

export interface ProgramsStateModel {
    programs: IProgram[],
    programOverview: IProgram,
    classes: IClass[],
    sessions: ISession[]
}

@State<ProgramsStateModel>({
    name: 'programs',
    defaults: {
        programs: [],
        programOverview: null,
        classes: [],
        sessions: []
    }
})
@Injectable()
export class ProgramState {

    constructor(private programService: ProgramService, private participantService: ParticipantService) { }

    @Selector()
    static getPrograms(state: ProgramsStateModel) {
        return state.programs;
    }

    @Selector()
    static programOverview(state: ProgramsStateModel) {
        return state.programOverview;
    }

    @Selector()
    static getClasses(state: ProgramsStateModel) {
        return state.classes;
    }

    @Selector()
    static getSessions(state: ProgramsStateModel) {
        return state.sessions;
    }

    @Action(ProgramActions.GetProgramsOverview)
    getProgramsOverview(ctx: StateContext<ProgramsStateModel>, programId: ProgramActions.GetProgramsOverview) {
        const state = ctx.getState();
        const existingprogramOverview = state?.programOverview;
        if (existingprogramOverview) {
            return of(existingprogramOverview);
        }
        return this.programService.get(programId.programId)
            .pipe(
                tap((response: IProgram) => {
                    ctx.patchState({
                        programOverview: response
                    });
                })
            );
    }

    @Action(ProgramActions.GetPrograms)
    getPrograms(ctx: StateContext<ProgramsStateModel>) {
        const state = ctx.getState();
        const existingPrograms = state?.programs;
        if (existingPrograms?.length) {
            return of(existingPrograms);
        } else {
            return this.programService.getProgramsList()
                .pipe(
                    tap((programsResponse: IProgram[]) => {
                        ctx.patchState({
                            programs: programsResponse
                        });
                    }));
        }
    }

    @Action(ProgramActions.ClearProgramOverview)
    clearPrograms(ctx: StateContext<ProgramsStateModel>) {
        ctx.patchState({
            programOverview: null,
            classes: null,
            sessions: null
        });
    }

    @Action(ProgramActions.ClearClasses)
    clearClassesByProgramId(ctx: StateContext<ProgramsStateModel>) {
        ctx.patchState({
            classes: null,
        });
    }

    @Action(ProgramActions.GetClassesByProgramId)
    getClassesByProgramId(ctx: StateContext<ProgramsStateModel>, action: ProgramActions.GetClassesByProgramId) {
        const state = ctx.getState();
        const existingClasses = state?.classes;
        if (existingClasses?.length) {
            return of(existingClasses);
        }
        const endpoint = action?.payload?.emailId ? this.participantService.getExternalFacilitatorCourses(action.payload.programId) :
            this.programService.getClassesByProgram(action.payload.programId);
        return endpoint
            .pipe(
                tap((response: any) => {
                    ctx.patchState({
                        classes: response
                    });
                })
            );
    }

    @Action(ProgramActions.GetSessionsByProgramId)
    getSessionsByProgramId(ctx: StateContext<ProgramsStateModel>, action: ProgramActions.GetSessionsByProgramId) {
        const state = ctx.getState();
        const existingSessions = state?.sessions;
        if (existingSessions?.length) {
            return of(existingSessions);
        }
        return this.programService.getSessionsByProgram(action.programId)
            .pipe(
                tap((response: any) => {
                    ctx.patchState({
                        sessions: response
                    });
                })
            );
    }

    @Action(ProgramActions.ClearPrograms)
    clearAllPrograms(ctx: StateContext<ProgramsStateModel>) {
        ctx.patchState({
            programs: null
        });
    }

}


