<div *ngIf="showGlobalSpinner">
    <div class="loading">
        <mat-spinner></mat-spinner>
    </div>
</div>
<!-- <div breadcrumbs [option]='breadcrumbOptions'></div> -->
<div>
    <div>
        <div class-roster-body>
            <div class-roster-title>
                <h1>Program Documents</h1>
            </div>
        </div>
    </div>

    <div class="panel">
        <div class="panel-header panel-header-2">
            <div class="panel-title">
                <span class="p-t-0 p-b-0"><img src="assets/icons/document-icon-blue.svg" alt=""></span>
                <p>Documents ( {{programDocs?.length}} )</p><br>
            </div>

            <div class="displayFlex">
                <div class-roster-body>
                    <div class-roster-title class="add-doc-icon">
                        <div class="export-button" (click)="addRow()">Add Document
                            <img src="assets/icons/document-icon-white.svg" alt="" ml10>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <p class="p-select-mobile-msg" *ngIf="programDocs?.length">By selecting 'display on mobile' you are confirming
            stakeholder approval to display the document.</p>
        <p class="no-results-filter" *ngIf="isFilterRes; else displayTable">{{filterRes}}</p>
        <ng-template #displayTable>
            <form *ngIf="programDocs; else loading" class="panel-body" [formGroup]="eventsTable">
                <div class="events-table">
                    <table class="table participant-table"
                        *ngIf="eventsTable?.get('tableRows')?.controls?.length; else displayErrorMessage">
                        <thead #target class="sticky">
                            <th>
                                <span>DOCUMENT NAME</span>
                                <img (click)="sort('name')" [ngClass]="{'active':isNameAsc}"
                                    src="assets/icons/sort-by-down.svg" alt="">
                            </th>
                            <th>
                                <span>DESCRIPTION</span>
                                <img (click)="sort('desc')" [ngClass]="{'active':isDescriptionAsc}"
                                    src="assets/icons/sort-by-down.svg" alt="">
                            </th>
                            <th>
                                <span>DOCUMENT URL</span>
                                <img (click)="sort('url')" [ngClass]="{'active':isTypeAsc}"
                                    src="assets/icons/sort-by-down.svg" alt="">
                            </th>
                            <th>
                                <span>DISPLAY ON MOBILE</span>
                                <img (click)="sort('mobileDisplay')" [ngClass]="{'active':isRegisteredAsc}"
                                    src="assets/icons/sort-by-down.svg" alt="">
                            </th>
                            <th>
                                <span>Actions</span>
                            </th>
                        </thead>
                        <tbody>
                            <ng-container formArrayName="tableRows"
                                *ngFor="let group of getFormControls.controls; let i=index">
                                <tr *ngIf="group.get('isEditable').value" [formGroupName]="i">
                                    <td>
                                        <mat-form-field>
                                            <mat-label>Document Name</mat-label>
                                            <input matInput type="text" formControlName="name" maxlength="50">
                                        </mat-form-field>
                                        <span class="textGreySmall">{{group.get('name')?.value ? group.get('name')?.value?.length : 0}} / 50</span>
                                    </td>
                                    <td>
                                        <mat-form-field>
                                            <mat-label>Description</mat-label>
                                            <input matInput type="text" formControlName="desc" maxlength="300">
                                        </mat-form-field>
                                        <span class="textGreySmall">{{group.get('desc')?.value ? group.get('desc')?.value?.length : 0}} / 300</span>
                                    </td>
                                    <td>
                                        <mat-form-field>
                                            <mat-label>Document URL</mat-label>
                                            <input matInput type="text" formControlName="url">
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field class="select-mobile-icon">
                                            <input matInput hidden="true" />
                                            <mat-checkbox formControlName="mobileDisplay">
                                            </mat-checkbox>
                                        </mat-form-field>
                                    </td>

                                    <td>
                                        <button matTooltip="Cancel" matTooltipPosition="above" mat-icon-button
                                            color="warn" (click)="cancelRow(i, group)">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                        <button matTooltip="Save" matTooltipPosition="above" mat-icon-button
                                            class="text-green" [disabled]="!eventsTable.valid" (click)="saveRow(group)">
                                            <mat-icon>done</mat-icon>
                                        </button>

                                    </td>
                                </tr>
                                <tr *ngIf="!group.get('isEditable').value">
                                    <td>
                                        <div class="user-profile">
                                            <div>
                                                <span class="table-name-head seeParticipants">
                                                    {{group.get('name').value}}</span>

                                            </div>
                                        </div>
                                    </td>
                                    <td class="tb-date-desc">
                                        {{group.get('readMore').value ? group.get('desc').value :
                                        group.get('desc').value | slice:0:50}}
                                        <a class="seeMore" *ngIf="group.get('desc').value.length > '50'"
                                            (click)="group.get('readMore').value = !group.get('readMore').value">
                                            {{ group.get('readMore').value ? 'See Less': 'See More' }}
                                        </a>
                                    </td>
                                    <td>
                                        <a class="restrict-url" href="{{group.get('url').value}}" target="_blank">
                                            {{group.get('url').value}}
                                        </a>
                                    </td>
                                    <td>
                                        <input class="td-mobile-display" type="checkbox"
                                            [checked]="group.get('mobileDisplay').value" disabled>

                                    <td>
                                        <button matTooltip="Edit" matTooltipPosition="above" mat-icon-button
                                            color="primary" (click)="editRow(group, addRowExist)"
                                            [disabled]="disableDeleteEditButons[i]">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button matTooltip="Delete" matTooltipPosition="above" mat-icon-button
                                            color="warn" (click)="deleteRow(i, group)"
                                            [disabled]="disableDeleteEditButons[i]">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </form>
        </ng-template>
    </div>

</div>
<ng-template #displayErrorMessage>
    <p class="background-white m-t-xs">
        <span class="m-l-lg">
            <error-message [errorMessage]="errorMessage"></error-message>
        </span>
    </p>
</ng-template>

<ng-template #loading>
    <div class="loading" *ngIf="!showLoading">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>