import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { flatMap, switchMap, skipWhile, distinctUntilChanged } from 'rxjs/operators';
import { JourneyState } from 'src/app/features/journey/journey.state';
import { IProgram } from 'src/app/models/IProgram';
import { ItineraryService } from '../../../services/aws/itinerary/itinerary.service';

@Component({
    selector: 'journey-footer',
    templateUrl: './footer.component.html',
    styles: []
})
export class FooterComponent implements OnInit {
    toggle: string = 'open';
    invitation: boolean = true;
    course: boolean = true;
    flight: boolean = true;
    stay: boolean = true;

    @Select(JourneyState.getSelectedProgram) itineraryProgram$: Observable<IProgram>;

    constructor(private itinerary: ItineraryService) { }

    ngOnInit() {
        this.itineraryProgram$.pipe().subscribe(() => {
                this.invitation = false;
            }
        );

        this.itineraryProgram$.pipe(
            distinctUntilChanged(),
            skipWhile(itineraryProgram => !itineraryProgram?.id),
            switchMap(itineraryProgram => this.itinerary.getProgramCourses(itineraryProgram?.id)),
        ).pipe().subscribe(
            courses => {
                this.course = (courses.length < 1);
            }
        );

        this.itineraryProgram$.pipe(
            distinctUntilChanged(),
            skipWhile(itineraryProgram => !itineraryProgram?.id),
            switchMap(itineraryProgram => this.itinerary.getFlight(itineraryProgram?.id)),
            flatMap(data => {
                this.flight = (data?.length < 1);
                return data as any;
            })).pipe().subscribe();

        this.itineraryProgram$.pipe(
            distinctUntilChanged(),
            skipWhile(itineraryProgram => !itineraryProgram?.id),
            switchMap(itineraryProgram => this.itinerary.getStay(itineraryProgram?.id)),
        ).pipe().subscribe(
            data => {
                this.stay = (data==null);
            }
        );
    }

    click() {
        this.toggle === 'close' ? this.toggle = 'open' : this.toggle = 'close';
    }
}
