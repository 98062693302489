<!-- <footer [attr.mode]="toggle" (click)="click()">
    
    <div class="active">
        <div>
            <span><i class="material-icons">keyboard_arrow_left</i></span>
            <span><i class="material-icons">assignment</i></span>
        </div>
        <div class="disable" [ngClass]="{'enable':invitation}">
            <span></span>
            <span>Invitation</span>
            <span *ngIf="invitation"><i class="material-icons active">done</i></span>
        </div>
        <div class="disable" [ngClass]="{'enable':course}"><span></span><span>Courses</span><span><i class="material-icons">done</i></span>
            <span *ngIf="course"><i class="material-icons active">done</i></span>
        </div>
        <div class="disable" [ngClass]="{'enable':flight}"><span></span><span>Flight</span><span><i class="material-icons">done</i></span>
            <span *ngIf="flight"><i class="material-icons active">done</i></span>
        </div>
        <div class="disable" [ngClass]="{'enable':stay}"><span></span><span>Stay</span><span><i class="material-icons">done</i></span>
            <span *ngIf="stay"><i class="material-icons active">done</i></span>
        </div>
    </div>
</footer> -->

<footer [attr.mode]="toggle" (click)="click()">
        <div>
          <span><img src="assets/images/expand-close-right.svg" alt=""></span>
        </div>
  <section>
      <div>
            <img src="assets/images/status-title.svg" alt="">
      </div>
      <div status>Status</div>
      
  </section>
        <div class="active">
          <div class="disable enable">
            <span *ngIf="!invitation"><img src="assets/images/status-complete.svg" alt=""></span> <span  *ngIf="invitation"><img src="assets/images/status-incomplete.svg" alt=""></span>
            <span>Invitation</span>
          </div>
  
          <div class="stepper"></div>
          
          <div class="disable">
            <span *ngIf="!course"><img src="assets/images/status-complete.svg" alt=""></span> <span  *ngIf="course"><img src="assets/images/status-incomplete.svg" alt=""></span>
            <span>Courses</span>
          </div>
          
          <div class="stepper"></div>
         
          <div class="disable">
            <span *ngIf="!flight"><img src="assets/images/status-complete.svg" alt=""></span><span  *ngIf="flight"><img src="assets/images/status-incomplete.svg" alt=""></span>
            <span >Flight</span>
          </div>
  
          <div class="stepper"></div>
          
          <div class="disable">
            <span *ngIf="!stay"><img src="assets/images/status-complete.svg" alt=""></span><span  *ngIf="stay"><img src="assets/images/status-incomplete.svg" alt=""></span>
            <span>Room</span>
          </div>
        </div>
      </footer>
  