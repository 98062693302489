import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'days' })
export class DaysPipe implements PipeTransform {
    daysLeft: any;
    curDate: Date;
    curTime: any;
    transform(startDate?: any, endDate?: any): any {
       
        if (startDate != undefined) {
            if (moment(this.curDate).format('YYYY-MM-DD') <= moment(startDate).format('YYYY-MM-DD'))
            {
                this.daysLeft = moment(moment(endDate).format('YYYY-MM-DD')).diff(moment(moment(this.curDate).format('YYYY-MM-DD')), 'days');
                this.daysLeft = this.daysLeft + ' Days Left ';
            }
            else if (((moment(this.curDate).format('YYYY-MM-DD HH:mm')) > (moment(startDate).format('YYYY-MM-DD HH:mm'))) && ((moment(this.curDate).format('YYYY-MM-DD HH:mm')) < (moment(endDate).format('YYYY-MM-DD HH:mm')))) {
                this.daysLeft = 'IN PROGRESS';
            }
        else if (moment(this.curDate).format('YYYY-MM-DD') >= moment(endDate).format('YYYY-MM-DD'))
          //  this.daysLeft = 0;
            this.daysLeft = 'COMPLETE';
            //Thu, Mar 11, 2019
            return this.daysLeft;
        }
    }
}