<div class-roster>
    <div class-roster-body>
        <div class-roster-title>
            <h1>Class Roster</h1>
            <div (click)="openReport()" class="export-button">Export to Excel
                <img src="assets/icons/excel.svg" alt="" ml10>
                <img src="assets/icons/dropdown-button-down-white.svg" alt="" ml10>
                <ul export-dd [attr.show]="showReport">
                    <li (click)="download('participants')">Classroom Attendance</li>
                    <li (click)="download('wellbeing')">Well-being Check</li>
                </ul>
            </div>
        </div>
        <div class="tab-body">
            <ng-container *ngIf="!isConfirmed">
                <div>
                    <div class="notification" *ngIf="isFacilitatorOver==='disable'">
                        <div>
                            <p>The attendance record as shown is an accurate record of each participant’s attendance.</p>
                            <p class="text-gray">
                                <img src="assets/icons/confirmation-not_due.svg" alt=""> Facilitator Must Confirm Attendance</p>
                        </div>
                        <button type="button" class="btn-primary" disabled (click)="confirm()">Confirm Attendance</button>
                    </div>
                    <div class="notification warning" *ngIf="isFacilitatorOver==='allow'">
                        <div>
                            <p>The attendance record as shown is an accurate record of each participant’s attendance.</p>
                            <p class="text-gray">
                                 <img src="assets/icons/confirmation-pending.svg" alt="">Facilitator Must Confirm Attendance&bull; &nbsp;
                                <span class="jp-bold" red>Action Required</span>
                            </p>
                        </div>
                        <button type="button" class="btn-primary" (click)="confirm()">Confirm Attendance</button>
                    </div>
                    <div class="notification danger" *ngIf="isFacilitatorOver==='over'">
                        <div>
                            <p>The attendance record as shown is an accurate record of each participant’s attendance.</p>
                            <p class="text-danger">
                                <img src="assets/icons/confirmation-overdue.svg" alt="">Facilitator Must Confirm Attendance&bull; &nbsp;
                                <span class="jp-bold" red>Action Required</span>
                            </p>
                        </div>
                        <button type="button" class="btn-primary" (click)="confirm()">Confirm Attendance</button>
                    </div>
                    <div *ngIf="doConfirm">
                        <section class="popover">
                            <div>
                                <span>Once you confirm attendance, only Admin can make changes. Do you wish to confirm and close participant attendance?</span>
                                <article>
                                    <button (click)="cancelConfirm()">Cancel</button>
                                    <button (click)="saveConfirm()">Confirm</button>
                                </article>
                            </div>
                        </section>
                    </div>
                </div>
            </ng-container>
            <div class="notification success" *ngIf="isConfirmed">
                <ng-container *ngIf="confirmationData; else displayConfirmationDataErrorMessage">
                <div>
                    <p><b>The attendance record as shown is an accurate record of each participant’s attendance.</b></p>
                    <p class="text-green">
                         <img src="assets/icons/confirmation-confirmed.svg" alt=""><p class="text-gray">{{confirmationData?.firstName}} {{confirmationData?.lastName}} • {{confirmationData?.createdDate | dateFormat : 'mmddyyyy'}} • {{confirmationData?.createdDate |dateFormat : 'coursetime'}}
                        
                    </p>
                </div>
                <button type="button" class="btn-success">Attendance Confirmed <i class="material-icons">check_circle</i></button>
                </ng-container>   
            </div>
            <ng-template #displayConfirmationDataErrorMessage>
                    <p class="background-white m-t-xs">
                        <span class="m-l-lg">
                            <error-message [errorMessage]="confirmationDataErrorMessage"></error-message>
                        </span>
                    </p>
            </ng-template>
            <div class="panel" *ngIf="facilitators; else loading">
                <div class="panel-header">
                    <div class="panel-title">
                        <p class="panel-title">Facilitator Attendance ({{facilitators.length}})</p>
                    </div>
                    <div class="display-flex">
                        <div class="expand-button" (click)="expandAll('facilitator')">Expand rows</div> 
                        <span class="m-l-sm m-r-sm pipe">|</span> 
                        <div class="expand-button m-r-sm" (click)="collapseAll('facilitator')">Collapse rows</div>
                    </div>
                </div>
                <div class="panel-body">
                    <table class="table facilitator-table" *ngIf="facilitators?.length; else displayFacilitatorsErrorMessage">
                        <thead>
                            <th (click)="sort('fullName','facilitator')">
                                <span>Name</span>
                                <img [ngClass]="{'active':isNameAsc}" src="assets/icons/sort-by-down.svg" alt="">
                            </th>
                            <th (click)="sort('email','facilitator')">
                                <span>Email</span>
                                <img [ngClass]="{'active':isEmailAsc}" src="assets/icons/sort-by-down.svg" alt="">
                            </th>
                            <th *ngIf="facilitatorAttendance">
                                <span>Facilitation</span>
                            </th>
                            <th *ngIf="facilitatorAttendance">
                                <span>Participation</span>
                            </th>
                            <th>
                                <span>Time Missed</span>
                            </th>
                        </thead>
                        <tbody>
                            <ng-container *ngFor='let data of facilitators | orderBy: {property: column, direction: direction}; let i = index'>
                                <tr [attr.edit]="data?.isEdit">
                                    <td>
                                        <div>
                                            <span>{{data?.fullName}} {{ data?.edit }}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <span>{{data?.email}}</span>
                                        </div>
                                    </td>
                                    <td *ngIf="facilitatorAttendance">
                                        <div class="time-item">
                                            <mat-form-field>
                                                <select matNativeControl [ngModel]="data?.facilitationResponse?.facilitationResponse" (change)="handleFacilitationResponse($event, data?.facilitationResponse, data.participantId, 'facilitation')">
                                                  <option value="Some Modules">Some Modules</option>
                                                  <option value="Entire Modules">Entire Modules</option>
                                                  <option value="NA">N/A</option>
                                                </select>
                                            </mat-form-field>
                                        </div>
                                        <div timeexpanded *ngIf="data?.facilitationResponse?.facilitationResponse==='Some Modules' || data?.facilitationResponse?.facilitationResponse==='Entire Modules'">
                                            <div class="total">
                                                <span>Total Facilitated:</span><span>{{ data?.facilitationTime | timeTotal }}</span>
                                            </div>
                                            <div class="time-row" *ngFor="let f of data?.facilitationTime">
                                                <div>
                                                    <span class="select-view" (click)="!enableCheckbox ? togglePicker(data.participantId,f,'checkIn','facilitation') : null">{{f?.checkInTime | dateFormat :'rosterTime'}}</span>
                                                    <span class="text-gray">to</span>
                                                    <span class="select-view" (click)="!enableCheckbox ? togglePicker(data.participantId,f,'checkOut','facilitation') : null">{{f?.checkOutTime | dateFormat :'rosterTime'}}</span>
                                                    <span close (click)="!enableCheckbox ? delete(data.participantId,f,'facilitation', i) : null">
                                                        <i class="material-icons">close</i>
                                                    </span>
                                                    <time-picker [dateTime]="[selectedType === 'checkIn' ? f?.checkInTime : f?.checkOutTime,'']" *ngIf="selectedPickerId === f?.id && selectedTarget === 'facilitation'" (cancel)="togglePicker()" (update)="update($event)"></time-picker>
                                                </div>
                                                <div>
                                                    <div class="comment"></div>
                                                    <span class="time-highlight">
                                                        <i class="material-icons">access_time</i>{{f?.checkInTime | timeDiff : f?.checkOutTime }}&nbsp;Mins
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="time-row">
                                                <div>
                                                    <span class="select-view" blank (click)="!enableCheckbox ? togglePicker(data.participantId,null,'addCheckIn','facilitation') : null">{{addedFacilitation[i]?.checkInTime | dateFormat :'missedStartTime'}}</span>
                                                    <span class="text-gray">to</span>
                                                    <span class="select-view" blank (click)="!enableCheckbox ? togglePicker(data.participantId,null,'addCheckOut','facilitation') : null">{{addedFacilitation[i]?.checkOutTime | dateFormat :'missedEndTime'}}</span>
                                                    <span></span>
                                                    <time-picker [dateTime]="['','']" *ngIf="selectedPickerId === 0 && selectedTarget === 'facilitation' && selectedIndex === i" (cancel)="togglePicker()" (update)="update($event)"></time-picker>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td *ngIf="facilitatorAttendance">
                                        <div class="time-item">
                                            <mat-form-field>
                                                <select matNativeControl [ngModel]="data?.facilitationResponse?.participationResponse" (change)="handleFacilitationResponse($event, data?.facilitationResponse, data.participantId)">
                                                  <option value="Yes">Yes</option>
                                                  <option value="No">No</option>
                                                  <option value="NA">N/A</option>
                                                </select>
                                            </mat-form-field>
                                        </div>
                                        <div timeexpanded *ngIf="data?.facilitationResponse?.participationResponse === 'Yes'">
                                            <div class="total">
                                                <span>Total Paticipated:</span><span>{{ data?.participationTime | timeTotal }}</span>
                                            </div>
                                            <div class="time-row" *ngFor="let p of data?.participationTime">
                                                <div>
                                                    <span class="select-view" (click)="!enableCheckbox?togglePicker(data.participantId,p,'checkIn','participation'):return">{{p?.checkInTime | dateFormat :'rosterTime'}}</span>
                                                    <span class="text-gray">to</span>
                                                    <span class="select-view" (click)="!enableCheckbox?togglePicker(data.participantId,p,'checkOut','participation'):return">{{p?.checkOutTime | dateFormat :'rosterTime'}}</span>
                                                    <span close (click)="!enableCheckbox?delete(data.participantId, p, 'participation', i):return">
                                                        <i class="material-icons">close</i>
                                                    </span>
                                                    <time-picker [dateTime]="[selectedType === 'checkIn' ? p?.checkInTime : p?.checkOutTime,'']" *ngIf="selectedPickerId === p?.id && selectedTarget === 'participation'" (cancel)="togglePicker()" (update)="update($event)"></time-picker>
                                                </div>
                                                <div>
                                                    <div class="comment"></div>
                                                    <span class="time-highlight">
                                                        <i class="material-icons">access_time</i>{{p?.checkInTime | timeDiff : p?.checkOutTime }}&nbsp;Mins
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="time-row">
                                                <div>
                                                    <span class="select-view" blank (click)="!enableCheckbox?togglePicker(data.participantId,null,'addCheckIn','participation'):return">{{addedParticipation[i]?.checkInTime | dateFormat :'missedStartTime'}}</span>
                                                    <span class="text-gray">to</span>
                                                    <span class="select-view" blank (click)="!enableCheckbox?togglePicker(data.participantId,null,'addCheckOut','participation'):return">{{addedParticipation[i]?.checkOutTime | dateFormat :'missedEndTime'}}</span>
                                                    <span></span>
                                                    <time-picker [dateTime]="['','']" *ngIf="selectedPickerId === 0 && selectedTarget === 'participation' && selectedIndex === i" (cancel)="togglePicker()" (update)="update($event)"></time-picker>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="time-missed-box">
                                            <label>
                                                <label *ngIf="totalTimeMissed(data?.totalMissed) !== 0" class="total-missed-label">
                                                    Total Missed: <div class="pipe bold-text m-l-xs">
                                                        {{totalTimeMissed(data?.totalMissed)}} Mins
                                                        </div>
                                                </label>
                                            </label>
                                            <div class="time-item">
                                                <span></span>
                                                <div class="expand" (click)="expandCollapse('facilitator', data, i)">
                                                    <img *ngIf="!data?.isEdit" src="assets/icons/expand-open-down.svg" alt="">
                                                    <img *ngIf="data?.isEdit" src="assets/icons/expand-close-up.svg" alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <div [ngClass]="{'m-t-lg' : totalTimeMissed(data?.totalMissed) !== 0}" timeexpanded>
                                            <div class="time-row" *ngFor="let timeMissed of data?.totalMissed">
                                                <div>
                                                    <span class="select-view" (click)="!enableCheckbox?togglePicker(data.participantId,timeMissed,'checkIn','facilitator'):return">{{(timeMissed?.checkInTime) | dateFormat :'rosterTime'}}</span>
                                                    <span class="text-gray">to</span>
                                                    <span class="select-view" (click)="!enableCheckbox?togglePicker(data.participantId,timeMissed,'checkOut','facilitator'):return">{{timeMissed?.checkOutTime | dateFormat :'rosterTime'}}</span>
                                                    <span close (click)="!enableCheckbox?delete(data.participantId,timeMissed, 'facilitators', i):return">
                                                        <i class="material-icons">close</i>
                                                    </span>
                                                    <time-picker [dateTime]="[selectedType === 'checkIn' ? timeMissed?.checkInTime : timeMissed?.checkOutTime, '']" *ngIf="selectedPickerId === timeMissed?.id && selectedTarget === 'facilitator'" (cancel)="togglePicker()" (update)="update($event)"></time-picker>
                                                </div>
                                                <div>
                                                    <div class="comment"> 
                                                        <!-- *ngIf="selectedIndex === i" -->
                                                        <span (click)="toggleComment(timeMissed?.id,'facilitators')">
                                                            <img src="assets/icons/comment.svg" alt=""></span>
                                                        <div class="comment-box" *ngIf="selectedBox === timeMissed.id && commentBoxType === 'facilitators'">
                                                            <div comment-box-header>
                                                                <span>
                                                                    <img src="assets/icons/comment.svg" alt="">{{timeMissed?.comments}}</span>
                                                                <i class="material-icons" (click)="closeComment(i,'facilitators')">close</i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="total-time-count">
                                                        <!-- <span class="time-highlight" [hidden]="true">
                                                            <mat-icon> chat_bubble_outline</mat-icon>
                                                        </span> -->
                                                        <span class="time-highlight time">
                                                            <i class="material-icons">access_time</i>
                                                            {{timeMissed?.checkInTime | timeDiff : timeMissed?.checkOutTime }} Mins
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="time-row">
                                                <div>
                                                    <span class="select-view" blank (click)="!enableCheckbox?togglePicker(data.participantId,null,'addCheckIn','facilitator'):return">{{addedFacilitatorsInfo[i]?.checkInTime | dateFormat :'missedStartTime'}}</span>
                                                    <span class="text-gray">to</span>
                                                    <span class="select-view" blank (click)="!enableCheckbox?togglePicker(data.participantId,null,'addCheckOut','facilitator'):return">{{addedFacilitatorsInfo[i]?.checkOutTime | dateFormat :'missedEndTime'}}</span>
                                                    <span></span>
                                                    <time-picker [dateTime]="['','']" *ngIf="selectedPickerId === 0 && selectedTarget === 'facilitator'  && selectedIndex === i" (cancel)="togglePicker()" (update)="update($event)"></time-picker>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="reason-row" *ngIf="((addedFacilitatorsInfo[i]?.enableSaveCta && isShowSave) || updatedDetailCache?.length > 0)  && ((selectedIndex === i) || (participatiopnSelectedIndex === i) || (facilitationSelectedIndex === i))">
                                    <td colspan="5">
                                        <save-bar [showReason]="showReason" [newAddedDetails]="newAddedDetails" [isAdded]="isAdded" [isSuccess]="isSuccess" [isShowSave]="isShowSave || updatedDetailCache?.length > 0"
                                        [type]="'facilitator'"
                                        (toggle)="toggleRow(null,'facilitator')" [updatedDetailCache]="updatedDetailCache" [isDeleted]="isDeleted" [listText]="listText" (updateComment)="updateCommentFacilitator($event)"
                                        [data]="addedFacilitatorsInfo[i]"
                                                  (toggleCancelWidget)="keepCancelWidgetEditing('facilitator')" (toggleSave)="getRosterFacilitators()"></save-bar>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>

                    <ng-template #displayFacilitatorsErrorMessage>
                        <p class="background-white m-t-xs">
                            <span class="m-l-lg">
                               <error-message [errorMessage]="facilitatorsErrorMessage"></error-message>
                            </span>
                        </p>
                    </ng-template>

                </div>
            </div>
            <div class="panel" *ngIf="participants; else loading">
                <div class="panel-header">
                    <div class="panel-title">
                        <p>Participant Attendance ({{participants.length}})</p>
                    </div>
                    <div class="display-flex">
                        <div class="expand-button" (click)="expandAll('participants')">Expand rows</div> 
                        <span class="m-l-sm m-r-sm pipe">|</span> 
                        <div class="expand-button m-r-sm" (click)="collapseAll('participants')">Collapse rows</div>
                    </div>
                </div>
                <div class="panel-body">
                    <table class="table participant-table" *ngIf="participants?.length; else displayParticipantsErrorMessage">
                        <thead>
                            <th (click)="sort('fullName','participant')">
                                <span>Name</span>
                                <img src="assets/icons/sort-by-down.svg" [ngClass]="{'active':isParticipantNameAsc}" alt="">
                            </th>
                            <th (click)="sort('email','participant')">
                                <span>Email</span>
                                <img src="assets/icons/sort-by-down.svg" [ngClass]="{'active':isEmailNameAsc}" alt="">
                            </th>
                            <th>
                                <span>Check-in</span>
                            </th>
                            <th>
                                <span>Check-in By</span>
                            </th>
                            <th>
                                <span>Time Missed</span>
                            </th>
                        </thead>
                        <tbody>
                            <ng-container *ngFor='let data of participants | orderBy: {property: participantColumn, direction: participantDirection}; let i = index'>
                                <tr [attr.edit]="data?.isEdit">
                                    <td>
                                        <div>
                                            <span>{{data?.fullName}}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <span>{{data?.email}}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <mat-checkbox [disabled]="data?.checkIn?.updatedBy == 'Code' || 
                                            data?.checkIn?.updatedBy == 'Beacon'
                                            ? true : false"
                                            [attr.data-updatedBy]="data?.checkIn?.updatedBy" color="primary" value="{{data?.checkIn?.status}}" [checked]="data?.checkIn?.status" (change)="checkInStatus($event,data.participantId, data)"></mat-checkbox>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <span>{{data?.checkIn?.updatedBy}}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="time-missed-box">
                                            <label>
                                                <label *ngIf="totalTimeMissed(data?.totalMissed) !== 0" class="total-missed-label">
                                                    Total Missed: <div class="pipe bold-text m-l-xs">
                                                        {{totalTimeMissed(data?.totalMissed)}} Mins
                                                        </div>
                                                </label>
                                            </label>
                                            <div class="time-item">
                                                <span></span>
                                                <div class="m-l-sm" (click)="expandCollapse('participants', data, i)">
                                                    <img *ngIf="!data?.isEdit" src="assets/icons/expand-open-down.svg" alt="">
                                                    <img *ngIf="data?.isEdit" src="assets/icons/expand-close-up.svg" alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <div [ngClass]="{'m-t-lg' : totalTimeMissed(data?.totalMissed) !== 0}" timeexpanded>
                                            <div class="time-row" *ngFor="let timeMissed of data?.totalMissed">
                                                <div>
                                                    <span class="select-view" (click)="!enableCheckbox?togglePicker(data.participantId,timeMissed,'checkIn','participant'):return">{{timeMissed?.checkInTime | dateFormat :'rosterTime'}}</span>
                                                    <span class="text-gray">to</span>
                                                    <span class="select-view" (click)="!enableCheckbox?togglePicker(data.participantId,timeMissed,'checkOut','participant'):return">{{timeMissed?.checkOutTime | dateFormat :'rosterTime'}}</span>
                                                    <span close (click)="!enableCheckbox?delete(data.participantId,timeMissed, 'participants', i):return"><i class="material-icons">close</i></span>
                                                    <time-picker [dateTime]="[selectedType === 'checkIn'? timeMissed?.checkInTime : timeMissed?.checkOutTime, '']" 
                                                    *ngIf="selectedPickerId === timeMissed.id && selectedTarget === 'participant'" (cancel)="togglePicker()" 
                                                    (update)="update($event)"></time-picker>
                                                </div>
                                                <div *ngIf="timeMissed?.comments">
                                                    <div class="comment" >
                                                        <span (click)="toggleComment(timeMissed?.id,'participants')">
                                                            <img src="assets/icons/comment.svg" alt="">
                                                        </span>
                                                        <div class="comment-box" *ngIf="selectedParticipantBox === timeMissed.id && commentBoxType === 'participants'">
                                                            <div comment-box-header>
                                                                <span><img src="assets/icons/comment.svg" alt="">{{timeMissed?.comments}}</span>
                                                                <i class="material-icons" (click)="closeComment(i,'participants')">close</i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="total-time-count">
                                                        
                                                        <span class="time-highlight participant-time">
                                                            <i class="material-icons">access_time</i>
                                                            {{timeMissed?.checkInTime | timeDiff : timeMissed?.checkOutTime }} Mins
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="time-row">
                                                <div class="position-relative">
                                                    <span class="select-view" blank 
                                                    (click)="!enableCheckbox?togglePicker(data.participantId,null,'addCheckIn','participant'):return">
                                                        {{addedParticipantsInfo[i]?.checkInTime | dateFormat :'missedStartTime'}}
                                                    </span>
                                                    <span class="text-gray">to</span>
                                                    <span class="select-view participant-endtime" blank 
                                                    (click)="!enableCheckbox?togglePicker(data.participantId,null,'addCheckOut','participant'):return">
                                                        {{addedParticipantsInfo[i]?.checkOutTime | dateFormat :'missedEndTime'}}
                                                    </span>
                                                    <div class="position-absolute participant-time-picker">
                                                        <time-picker [dateTime]="['','']" 
                                                        *ngIf="selectedPickerId === 0 && selectedTarget === 'participant' && participantSelectedIndex === i" 
                                                        (cancel)="togglePicker()" (update)="update($event)"></time-picker>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="reason-row" *ngIf="((addedParticipantsInfo[i]?.checkInTime !== 'Add Time' && addedParticipantsInfo[i]?.checkOutTime !== 'Add Time') || (addedParticipantsInfo[i]?.enableSaveCta && isShowSave) ||  (updatedDetailCache?.length > 0)) && participantSelectedIndex === i">
                                    <td colspan="6">
                                        <save-bar [showReason]="true" [newAddedDetails]="newAddedDetails" [isAdded]="isAdded" [isSuccess]="isSuccess" [isShowSave]="(addedParticipantsInfo[i]?.checkInTime !== 'Add Time' && addedParticipantsInfo[i]?.checkOutTime !== 'Add Time') || addedParticipantsInfo[i]?.enableSaveCta || updatedDetailCache?.length > 0" 
                                        (toggle)="toggleRow(null,'participants')" [updatedDetailCache]="updatedDetailCache" [isDeleted]="isDeleted" 
                                        [type]="'participants'"  (updateComment)="updateCommentFacilitator($event)"
                                        [listText]="listText" (toggleCancelWidget)="keepCancelWidgetEditing('participants')"  [index]="i"
                                        [data]="addedParticipantsInfo[i]"
                                        (toggleSave)="getRosterParticipants()"></save-bar>
                                        
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>

                    <ng-template #displayParticipantsErrorMessage>
                        <p class="background-white m-t-xs">
                            <span class="m-l-lg">
                                <error-message [errorMessage]="participantsErrorMessage"></error-message>
                            </span>
                        </p>
                    </ng-template>

                </div>
            </div>
        </div>
    </div>
</div>
<div cancel-widget [attr.showCancelWidget]="cancelModel">
    <div>
        <span>Are you sure you want to exit and lose changes?</span>
        <article>
            <button (click)="cancelWidgetConfirm()">Cancel</button>
            <button (click)="keepCancelWidgetEditing('')">Keep Editing</button>
        </article>
    </div>
</div>
<ng-template #loading>
    <div class="loading" *ngIf="!showLoading">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
