import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

/*
This pipe will give the number of days between two dates
*/
@Pipe({ name: 'getdays' })
export class GetDays implements PipeTransform {
    diffInDays: number;
    transform(startDate: Date, endDate: Date): number {
        let sDate = moment(startDate).startOf('day');
        let eDate = moment(endDate).endOf('day');
        this.diffInDays = Math.abs(sDate.diff(eDate, 'days'));
        return this.diffInDays && !isNaN(this.diffInDays) ? this.diffInDays + 1 : 0;
    }
}
