import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { HeaderService } from '../../../services/shared/header.service.';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { ItineraryService } from '../../../services/aws/itinerary/itinerary.service';
import * as moment from 'moment';
import { ProgramService } from 'src/app/services/aws/program/program.service';
import { Auth } from 'aws-amplify';
import { Select, Store } from '@ngxs/store';
import { JourneyState } from 'src/app/features/journey/journey.state';
import { IUser } from 'src/app/models/IUser';
import { UserState } from 'src/app/store/user.state';
import { IProgram } from 'src/app/models/IProgram';
import { JourneyActions } from '../journey.actions';

@Component({
    selector: 'journey-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    public showDropdown: boolean = false;

    public programId: number;

    tabs: any[] = this.getTabs();
    componentName: string = "home";
    title: string;
    startTime: any;
    endTime: any;
    location: string;
    show: boolean = false;
    @Select(UserState.user) user$: Observable<IUser>;
    @Select(JourneyState.getCurrentPrograms) currentPrograms$: Observable<IProgram[]>;
    @Select(JourneyState.getUpcomingPrograms) upcomingPrograms$: Observable<IProgram[]>;
    public selectedProgram: IProgram;
    isUserShow = false;
    isDisabled: string;
    private roles: Array<string> = [];
    isFacilitator: boolean;
    isAdmin: boolean;

    @Select(JourneyState.getSelectedProgram) selectedProgram$: Observable<IProgram>;

    constructor(private router: Router, private headerService: HeaderService, private auth: AuthenticationService, private itinerary: ItineraryService, private program: ProgramService,
        private store: Store) {
        this.getSelectedProgram();
        this.assignRole();
        // @TOT check if this is still in use
        this.user$.pipe(take(1)).subscribe();
        this.upcomingPrograms$.pipe(
            skipWhile((programs) => programs?.length === 0),
            take(1)).subscribe(() => {
                this.enableTabs();
            });
        this.currentPrograms$.pipe(
            skipWhile((programs) => programs?.length === 0),
            take(1)).subscribe(() => {
                this.enableTabs();
            });
    }


    private enableTabs(): void {
        this.tabs.forEach((tab) => {
            tab.disable = false;
        });
    }

    private assignRole(): void {
        Auth.currentAuthenticatedUser().then(
            user => {
                if (user.signInUserSession) {
                    this.roles = user.signInUserSession.idToken.payload["cognito:groups"];
                } else {
                    this.roles = [localStorage.getItem("role")];
                }
            }
        );
    }

    ngOnInit() {
        this.headerService.getHeader().pipe(take(1)).subscribe(data => {
            this.title = data?.course?.courseTitle;
            this.startTime = moment(data?.course?.startDateTime).format("hh:mm A");
            this.endTime = moment(data?.course?.endtDateTime).format("hh:mm A");
            this.location = data?.course?.location;
        });
        this.routAdmin('journey');
        const roles = this.auth.getUserRoles();
        this.isFacilitator = roles.includes('facilitator') || roles.includes('admin');
        this.isAdmin = roles.includes('admin');
    }

    public getSelectedProgram(): void {
        this.selectedProgram$.subscribe((program: IProgram) => {
            this.selectedProgram = program;
            this.programId = +program?.id;
        });
    }

    public hideShowDropdown(event): void {
        if (event?.target?.id === 'course-list-dropdown') {
            return;
        }
        this.showDropdown = false;
    }

    public toogleDropdown(): void {
        this.showDropdown = !this.showDropdown;
    }

    public setSelectedProgram(program: IProgram): void {
        this.store.dispatch(new JourneyActions.SetSelecedProgram(program));
        this.showDropdown = false;
    }

    private getTabs(): any[] {
        return [
            /*  { name: 'Prepare', componentName: 'home', Active: true, icon: 'assignment_turned_in' },
             { name: 'Courses', componentName: 'courses', Active: false, icon: 'import_contacts' },
             { name: 'Flight', componentName: 'flight', Active: false, icon: 'flight' },
             { name: 'Stay', componentName: 'stay', Active: false, icon: 'local_hotel' } */
            { name: 'Prepare', componentName: 'home', Active: false, src: 'assets/images/prepare-nav.svg', disable: false },
            { name: 'Courses', componentName: 'courses', Active: false, src: 'assets/images/courses-nav.svg', disable: false },
            { name: 'Travel', componentName: 'flight', Active: false, src: 'assets/images/travel-nav.svg', disable: false },
            { name: 'Room', componentName: 'stay', Active: false, src: 'assets/images/room-nav.svg', disable: false }
        ];
    }

    click(componentName) {
        this.componentName = componentName;
        this.router.navigate(['journey/' + componentName]);
        this.tabs.forEach(function (value) {
            value.componentName == componentName ? value.Active = true : value.Active = false;
        });
    }

    toggleUserRole() {
        this.isUserShow = !this.isUserShow;
    }

    private routAdmin(value: string): void {
        if (value.includes('admin')) {
            this.router.navigate(['admin']);
            this.isUserShow = false;
        }
        else if (value.includes('journey')) {
            this.auth.routeObs();
            let routeNav = !(localStorage.getItem('previousUrl')?.substring(1)) ?
                'journey/home' : localStorage.getItem('previousUrl')?.substring(1);
            this.tabs = this.tabs.map((x) => {
                if (routeNav.includes(x.componentName)) {
                    x.Active = true;
                }
                return x;
            });
            this.router.navigate([`${routeNav}`]);
            this.isUserShow = false;
        }
        else if (value.includes('facilitator')) {
            this.router.navigate(['admin/programs-external']);
            this.isUserShow = false;
        }
        else if (value.includes('signOut')) {
            localStorage.clear();
            sessionStorage.clear();
            this.auth.signOut();
            this.router.navigate(['logout']);
            this.isUserShow = false;
        }
    }

    private showHideAdmin(): boolean {
        let result = true;
        if (this.roles.includes('admin') && this.router.url.includes('admin')) {
            result = false;
        }
        if (this.roles.includes('participant')) {
            result = false;
        }
        return result;
    }

    private showHideFacilitator(): boolean {
        let result = true;
        if (this.roles.includes('admin') && this.router.url.includes('facilitator')) {
            result = false;
        }
        return result;
    }

    private showHideParticipant(): boolean {
        let result = true;
        if (this.roles.includes('admin') && this.router.url.includes('journey')) {
            result = false;
        }
        return result;
    }

    /* 
    private showHideAdmin(ddlValue): boolean {
        let result = true;
        if (this.roles.includes('admin') && this.router.url.includes('admin')) {
            result = false;
        }
        return result;
    }

    private showHideFacilitator(ddlValue): boolean {
        let result = true;   //In case of showing facilitator in future, change result=true
        if (this.roles.includes('admin') && this.router.url.includes('facilitator')) {
            result = false;
        }
        return result;
    }

    private showHideParticipant(ddlValue): boolean {
        let result = true;
        if (this.roles.includes('admin') && this.router.url.includes('journey')) {
            result = false;
        }
        return result;
    }
    */
}
