<div pre-stay *ngIf="preRegister">
    <div stay-head>
        <span><img src="assets/images/room-title.svg" alt=""></span>
        <span>My Room</span>
        <button (click)="redirectRegistration()">Register</button>
    </div>
    <!--New Design-->
    <div>
        <span>
            <p> <b>Note:</b> Changes may take some time to reflect on your timeline.</p>
        </span>
    </div>
    <div>
        <div register-stay>
            <img src="assets/images/room-pre.svg" alt="">
            <span>Help us prepare for your stay</span>
            <span>
                Click <a (click)="redirectRegistration()"> “Register”</a> to begin. Enter your arrival and departure
                dates, and other key information to help us customize your Lakehouse experience to your unique needs.
            </span>
            <!--<button type="" (click)="redirectRegistration()">Specify Room Accomodation Needs</button>-->
        </div>
    </div>
</div>

<div journey-stay *ngIf="stay && !preRegister; else loading">
    <div room-head>
        <span>
            <span><img src="assets/images/room-title.svg" alt=""></span>
        </span>
        <span>My Room </span>
        <div>
            <button (click)="redirectRegistration()">Manage Room</button>
        </div>
    </div>
    <div note>
        <span></span>
        <span>Click <a (click)="redirectRegistration()">"Manage Room"</a> to update or cancel. <b>Note:</b> Changes may
            take some time to reflect on your timeline.</span>
    </div>
    <div room-container>
        <div left *ngIf="stay.length !== 2; else splitStay">
            <h4>Cvent Confirmation
                <span>&#8226;</span>
                <span>#{{stay?.confirmationNumber}}</span>
            </h4>
            <div room *ngIf="!declinedRoom">
                <div>

                    <div>
                        <span>{{stay?.checkInDate | dateFormat : 'fulldayname' }}</span>
                        <span>{{stay?.checkInDate | dateFormat : 'dayindigit' }}</span>
                        <span>{{stay?.checkInDate | dateFormat : 'daywithyear' }}</span>
                    </div>
                </div>
                <div room-detail>
                    <div>
                        <span>

                        </span>
                        <span>
                            <img src="assets/images/room-x-large.svg" alt="">
                        </span>
                        <span>

                        </span>
                    </div>
                    <span>{{stay?.checkInDate | getnights : stay?.checkOutDate }} {{(stay?.checkInDate | getnights :
                        stay?.checkOutDate) === 1 ? 'Night' : 'Nights'}}</span>
                </div>
                <div>
                    <div>
                        <span>{{stay?.checkOutDate | dateFormat : 'fulldayname' }}</span>
                        <span>{{stay?.checkOutDate | dateFormat : 'dayindigit' }}</span>
                        <span>{{stay?.checkOutDate | dateFormat : 'daywithyear' }}</span>
                    </div>
                </div>
            </div>

            <div *ngIf="!declinedRoom; else noRoom">
                <div class="hotel" *ngIf="stay?.hotelName == 'KPMG Lakehouse'; else notLakehouse">Hotel</div>

                <div>
                    <div class="line-break">
                        <h4>{{stay?.hotelName}}</h4>
                        <div>{{stay?.hotelAddress}}</div>
                        <span>{{stay?.hotelCity}}, {{stay?.hotelState}} {{stay?.hotelZip}}</span><br>
                        <div class="adaImageText" *ngIf="stay?.adaAccessible">
                            <div><img class="ada" src="assets/images/room-ada-wheelchair-2x.png"></div>
                            <div class="adaText">ADA Accessible</div>
                        </div>

                    </div>
                    <div>
                        <!--
                    <span>Accomodation Needs</span>
                    <span>{{accomadaationNeeeds}}</span>
                    -->
                    </div>
                </div>
            </div>
            <ng-template #noRoom>
                <h4 *ngIf="declinedRoom">No guest room requested</h4>
            </ng-template>
            <ng-template #notLakehouse>
                <div class="hotel">Location</div>
            </ng-template>
            <!-- <h4>Manage Your Reservation</h4>
            <div>
                <ul>
                    <li>
                        Contact the
                        <span>Hotel Operator</span> if you wish to update your reservation.
                    </li>
                </ul>
            </div>-->
        </div>
        <ng-template #splitStay class="split-room">
            <div class="split-room">
                <h4>Cvent Confirmation
                    <span class="cvent-code">&#8226;</span>
                    <span class="cvent-code"> #{{stay[0]?.confirmationNumber}}</span>
                </h4>
                <div>
                    <img src="assets/images/hotel.png">
                </div>

                <div class="split-room-2">
                    <div class="flex-box-room" *ngFor="let stays of stay; let i= index">
                        <div left style="padding-left: inherit; padding: 0px;
                        margin: 25px 50px 25px 0px; border: 3px solid #F5F5F5;
                        " *ngIf="stay.length == 2">
                            <div room style="min-width: 400px;"*ngIf="!declinedRoom">
                                <div>

                                    <div>
                                        <span>{{stay[i]?.checkInDate | dateFormat : 'fulldayname' }}</span>
                                        <span>{{stay[i]?.checkInDate | dateFormat : 'dayindigit' }}</span>
                                        <span>{{stay[i]?.checkInDate | dateFormat : 'daywithyear' }}</span>
                                    </div>
                                </div>
                                <div room-detail>
                                    <div>
                                        <span>

                                        </span>
                                        <span>
                                            <img src="assets/images/room-x-large.svg" alt="">
                                        </span>
                                        <span>

                                        </span>
                                    </div>
                                    <span>{{stay[i]?.checkInDate | getnights : stay[i]?.checkOutDate }}
                                        {{(stay[i]?.checkInDate
                                        | getnights : stay[i]?.checkOutDate) === 1 ? 'Night' : 'Nights'}}</span>
                                </div>
                                <div>
                                    <div>
                                        <span>{{stay[i]?.checkOutDate | dateFormat : 'fulldayname' }}</span>
                                        <span>{{stay[i]?.checkOutDate | dateFormat : 'dayindigit' }}</span>
                                        <span>{{stay[i]?.checkOutDate | dateFormat : 'daywithyear' }}</span>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="!declinedRoom; else noRoom">
                                <div class="hotel-split">Location</div>

                                <div class="hotel-name">
                                    <div class="line-break">
                                        <h4>{{stay[i]?.hotelName}}</h4>
                                        <div>{{stay[i]?.hotelAddress}}</div>
                                        <span>{{stay[i]?.hotelCity}}, {{stay[i]?.hotelState}}
                                            {{stay[i]?.hotelZip}}</span><br>
                                        <div class="adaImageText" *ngIf="stay[i]?.adaAccessible; else noAda">
                                            <div><img class="ada" src="assets/images/room-ada-wheelchair-2x.png"></div>
                                            <div class="adaText">ADA Accessible</div>
                                        </div>

                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                            <ng-template #noRoom>
                                <h4 *ngIf="declinedRoom">No guest room requested</h4>
                            </ng-template>
                            <ng-template #noAda>
                                <div class="no-ada"></div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <div right *ngIf="!declinedRoom && (stay?.isHotelKPMG ||stay[i]?.isHotelKPMG)">
            <img src="assets/images/hotel.png">
        </div>
    </div>
</div>
<ng-template #loading>
    <div class="loading" *ngIf="!preRegister">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>