import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { API } from 'aws-amplify';
import { Observable, from } from 'rxjs';
import { NotificationHistoryResponse } from '../interface/notification-history.interface';

API.configure({
  endpoints: [
      {
          name: "portal",
          endpoint: environment.aws.apiEndpoint
      }
  ]
});


@Injectable({
  providedIn: 'root'
})
export class NotificationHistoryService {


  constructor() { }

  public getNotificationsHistory(): Observable<NotificationHistoryResponse[]> {
    return from(API.get('portal', 'adhoc/scheduled/history', {}));
  }

  public deleteNotification(id: string): Observable<NotificationHistoryResponse[]> {
    return from(API.del('portal', `adhoc/scheduled/${id}/deletemessage`, {}));
  }

  public updateNotification(payload: NotificationHistoryResponse, id: string): Observable<NotificationHistoryResponse[]> {
    payload.sendType = 'schedule';
    return from(API.patch('portal', `adhoc/scheduled/editMessage/${id}/update`, { body: payload}));
  }

  public getRecipientsAttribute(id: string) {
    return from(API.get('portal', `adhoc/scheduled/showRecipientsDetails/${id}`, {}));
  }
}
