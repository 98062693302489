import { ElementRef, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'statuscolor' })
export class StatusColorPipe implements PipeTransform {
    constructor(private elRef: ElementRef) {}
    transform(value:any): any {
        if (value != undefined) {
            this.elRef.nativeElement.style['text-transform'] = 'uppercase';
            this.elRef.nativeElement.style['border-radius'] = '2px';
            this.elRef.nativeElement.style['font-size'] = 'inherit';
            switch(value) {
                case 'Cancelled':
                case 'Declined':
                    this.elRef.nativeElement.style.color = '#BC204B';
                    this.elRef.nativeElement.style.background = '#FFEFF3';
                    break;
                case 'Reserved':
                    this.elRef.nativeElement.style.color = '#017D30';
                    this.elRef.nativeElement.style.background = '#EBF7F0';
                    break;
                case 'Requested':
                    this.elRef.nativeElement.style.color = '#DDA300'
                    this.elRef.nativeElement.style.background = '#FEF9ED';
                    break;
                case 'Overflow':
                case 'Split Stay':
                    this.elRef.nativeElement.style.color = '#0445B9';
                    this.elRef.nativeElement.style.background = '#E6ECF8';
                    break;    
                default:
                    this.elRef.nativeElement.style.color = '#6B6B6B';
                    this.elRef.nativeElement.style.background = '#EEEEEE';
                    break;
            }
        }
    }
}