import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { flatMap, filter, switchMap, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { ItineraryService } from '../../../services/aws/itinerary/itinerary.service';
import { HeaderService } from '../../../services/shared/header.service.';
import { ProgramService } from 'src/app/services/aws/program/program.service';
import { Select } from '@ngxs/store';
import { JourneyState } from '../journey.state';
import { IErrorMessage } from 'src/app/widgets/error-message/error-message.interface';
import { API_USER_DEFINED_MESSAGE } from 'src/app/widgets/error-message/error-message.const';
import { IProgram } from 'src/app/models/IProgram';
import { ICourse } from 'src/app/models/ICourse';

@Component({
    selector: 'journey-courses',
    templateUrl: './courses.component.html',
    styles: []
})
export class CoursesComponent implements OnInit, OnDestroy {
    public showGlobalSpinner: boolean = true;
    public destroy$: Subject<boolean> = new Subject<boolean>();
    preRegister: boolean = false;
    registrationUrl: string = "https://focus.us.kpmg.com/Saba/Web/Main";
    programId: number;
    selectedId: string;
    programs$: Observable<any>;
    coursesList;
    courses$: Observable<any>;
    course$: Observable<any>;
    coursesCount$: Observable<any>;
    groupedCourses$: Observable<any>;
    isExpand: boolean = true;
    txtShow: string = "See More";
    column: string = 'startDate';
    direction: number = 1;
    public errorMessage: IErrorMessage = {};
    @Select(JourneyState.getSelectedProgram) itineraryProgram$: Observable<IProgram>;

    constructor(private itinerary: ItineraryService, private program: ProgramService, private headerService: HeaderService) { }

    ngOnInit() {
        this.itineraryProgram$
            .pipe(
                distinctUntilChanged(),
                // skipWhile(itineraryProgram => !itineraryProgram?.id),
                switchMap(itineraryProgram => this.program.get(itineraryProgram?.id)),
                takeUntil(this.destroy$))
            .subscribe(data => {
                if (data.programSetting) {
                    this.registrationUrl = data.programSetting.courseRegistrationUrl || "https://focus.us.kpmg.com/Saba/Web/Main";
                }
            });

        // TODO: Need to refactor this to use select from store, but having significant issues with existing flow
        this.itineraryProgram$
        .pipe(
            distinctUntilChanged(),
            // skipWhile(itineraryProgram => !itineraryProgram?.id),
            switchMap(itineraryProgram => {
                this.showGlobalSpinner = true;
                this.programId = +itineraryProgram.id;
                return this.itinerary.getProgramCourses(itineraryProgram.id);
            }),
            takeUntil(this.destroy$))
        .subscribe((response: ICourse[]) => {
            this.course$ = null;
            this.selectedId = '';
            this.coursesCount$ = of(response?.length);
            this.preRegister = response?.length === 0;
            this.errorMessage.text = response?.length ? '' : API_USER_DEFINED_MESSAGE.EMPTY_RESPONSE;
            this.coursesList = response;

            this.groupedCourses$ = of(response.reduce((acc, item) => {
                const key = moment(item['startDate']).format('YYYY-MM-DD');
                if (!acc.has(key)) {
                    acc.set(key, [item]);
                } else {
                    acc.get(key).push(item);
                }
                return acc;
            }, new Map()));
            this.showGlobalSpinner = false;
        },
        () => {
            this.coursesList = [];
            this.errorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
            this.showGlobalSpinner = false;
        });
    }

    getCourseDetails(courseId: string) {
        this.course$ = this.program.getClassDetail(this.programId, courseId)
            .pipe(
                flatMap(data => (data as any)),
                filter(course => ((course as any).id + '') == courseId),
                tap(course => {
                    const find = '\n';
                    const re = new RegExp(find, 'g');
                    course.description = course.description.replace(re, '<br>');
                })
            );

        this.selectedId = courseId;
    }

    redirectMaterial(link) {
        window.open(link, "_blank");
    }

    redirectRegistration() {
        window.open(this.registrationUrl, "_blank");
    }

    expandText() {
        this.isExpand = !this.isExpand;
        if (this.txtShow == "See More") this.txtShow = "See Less"; else this.txtShow = "See More";
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
