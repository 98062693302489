<div>
    <!--top navigation start-->
    <div register-lakehouse *ngIf="isShow">
        <div head>
            <span></span>
            <span (click)="toggleRegister()"><img src="assets/images/close.svg" alt=""></span>
        </div>
        <div body>
            <div>
                <div>
                    <h3>Register for Lakehouse</h3>
                </div>
                <div>
                    <span> Use the top navigation above to set the stage for your Lakehouse experience.<b>Scroll down</b>  or
                        use the page navigation above for vital Lakehouse resources to prepare you on your journey.</span>
                </div>
                <div>
                    <!--  <button value="Prepare" (click)="scrollTo('section2')">Prepare <img src="assets/images/anchor-white.svg"></button>-->
                    <button value="Got it" (click)="toggleRegister()">Got it</button>
                </div>
            </div>
            <div>
                <div>
                    <img src="assets/images/courses-onboarding.svg" alt="">
                    <p>Enroll</p>
                    <p>in <b>Courses</b></p>
                </div>
                <div>
                    <img src="assets/images/flight-onboarding.svg" alt="">
                    <p>Arrange</p>
                    <p>your <b>Travel</b></p>
                </div>
                <div>
                    <img src="assets/images/room-onboarding.svg" alt="">
                    <p>Book</p>
                    <p>your <b>Room</b></p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showGlobalSpinner">
        <div class="loading">
          <mat-spinner></mat-spinner>
        </div>
      </div>
    <div down>
        <div tabs-down>
            <a (click)="scrollTo('section1')" [ngClass]=" {'active': currentSection==='section1'}">
                <span>Welcome</span>
            </a>
            <a (click)="scrollTo('section2')" [ngClass]=" {'active': currentSection==='section2'}">
                <span>Prepare</span>
            </a>
            <a (click)="scrollTo('section3')" [ngClass]=" {'active': currentSection==='section3'}">
                <span>What’s Different?</span>
            </a>
            <a (click)="scrollTo('section4')" [ngClass]=" {'active': currentSection==='section4'}">
                <span>Arrival</span>
            </a>
            <a (click)="scrollTo('section6')" [ngClass]=" {'active': currentSection ==='section6'}">
                <span>Download the App</span>
            </a>
            <a (click)="scrollTo('section7')" [ngClass]="{'active': currentSection ==='section7'}">
                <span>Learn About Lakehouse</span>
            </a>
        </div>
    </div>
    <!--Top Navigation ends -->
    <div outer-div id="parentDiv" scrollSpy [spiedTags]="['DIV']" [ngClass]="isShow ? 'journey-home-scroll-init' : 'journey-home-scroll'" (sectionChange)="onSectionChange($event)" [class.no-margin]="isShow">
        <div hero id="section1" >
            <div greeting>
                <p welcome>Welcome to KPMG Lakehouse</p>
                <h3 *ngIf="(user$ | async) as user">{{ greeting }}<br />{{user.firstName}}</h3>
                <div *ngIf="programs; else displayProgramErrorMessage" class="font-weight-bold font-size-14 m-t-0">
                <p class="font-size-24 m-t-0">{{programs.name}}  <img src="assets/images/sort-down.png" alt="" class="width-15px cursor-pointer m-l-xs" (click)="toogleDropdown()" id="course-list-dropdown"></p>
                <span>{{programStartEndDate | dateFormat : 'MMM dd - dd, yyyy'}}</span>
            </div>
            <ng-template #displayProgramErrorMessage >
                <div class="width-80"><span class="m-l-lg"><error-message [errorMessage]="errorMsg" ></error-message></span>
                </div>
            </ng-template>
        </div>
            <div class="panel-row position-absolute" *ngIf="showDropdown"  (clickOutside)="hideShowDropdown($event)">
                <programs-list [currentProgramsList]="currentProgramsList" [upcomingProgramsList]="upcomingProgramsList" [programId]="programId" (selectedProgram)="setSelectedProgram($event)"></programs-list>
            </div>
    </div>
        <div experience id="section2">
            <div content>
                <p title>Let the Journey Begin</p>
                <h3>Prepare</h3>
                <p>Your Lakehouse experience will be different from any </p>
                <p> other KPMG program you have ever attended.</p>
                <span>Before you travel, you will need to:</span>
            </div>
            <div info-block>
                <div>
                    <img src="assets/images/policies-anchor.svg">
                    <span>Get to know <b>Key Information</b></span>
                    <img src="assets/images/anchor.svg" (click)="scrollTo('section3')">
                </div>
                <div>
                    <img src="assets/images/arrival-anchor.svg">
                    <span>Preview the new <b>Arrival</b> process</span>
                    <img src="assets/images/anchor.svg" (click)="scrollTo('section4')">
                </div>
                <div>
                    <img src="assets/images/download-anchor.svg">
                    <span>Download the <b>Lakehouse App</b></span>
                    <img src="assets/images/anchor.svg" (click)="scrollTo('section6')">
                </div>
                <div>
                    <img src="assets/images/about-anchor.svg">
                    <span>Learn more about <b>Lakehouse</b></span>
                    <img src="assets/images/anchor.svg" (click)="scrollTo('section7')">
                </div>
            </div>
        </div>
        <div policy-procedure id="divCompbined">
            <div id="section3" three>
                <div>
                    <p title>Key Policies and Procedures</p>
                    <h3>First visit to Lakehouse?</h3>
                    <span s-video>From <b>expense policy</b> to <b>check-out procedure</b> to the importance of the <b>Lakehouse App</b>, there are a few things you need to know.</span>
                    <button (click)="openPage('https://kpmgus.sharepoint.com/sites/US-OI-BUS-People/learningdocuments/Forms/AllItems.aspx?id=%2Fsites%2FUS%2DOI%2DBUS%2DPeople%2Flearningdocuments%2FLakehouse%5FKeyInformation%2Epdf&parent=%2Fsites%2FUS%2DOI%2DBUS%2DPeople%2Flearningdocuments')">Review Key Information</button>
                   
                </div>
                <div>
                    <img src="assets/images/key-policies.png" alt="">
                </div>
            </div>
            <div id="section4" two>
                <div>
                    <img src="assets/images/arrival.png" alt="">
                </div>
                <div>
                    <p title>A Fantastic Start</p>
                    <h3>Arrival at Orlando Airport</h3>
                    <span top>Head to Baggage Claim level (Level 2), collect your luggage, and look for a Lakehouse transportation team member 
                        (with a KPMG Lakehouse sign). They’ll direct you to a shuttle, and after a short ride you’ll be walking through the Lakehouse doors.</span>
                    <span bottom>No need to stand in a check-in line—your <b>Lakehouse app</b>  will provide your <b>electronic key</b>  and direct you to your room.</span>
                </div>
            </div>
            <div four id="section6">
                <div>
                    <p title>Mobile Experience Companion</p>
                    <h3>Your Lakehouse App</h3>
                    <span>
                        In addition to providing your electronic room key, your Lakehouse App will 
                        also check you in for <b>CPE Credit</b>  during the week, provide key program announcements, help you navigate and network, and connect you to a 
                        wealth of amenities and activities to enhance your Lakehouse experience. 
                    </span>
                </div>
                <div>
                    <div> <img src="assets/images/lha-badge.svg" alt=""></div>
                    <div>
                        <h3>Download the App</h3>
                        <span>
                        Refer to the instructions for your device:
                    </span>
                        <div>
                            <button (click)="openPage('https://kpmg.service-now.com/kb_view.do?sysparm_article=KB0049837')">iOS</button>
                            <button (click)="openPage('https://kpmg.service-now.com/kb_view.do?sysparm_article=KB0049837')">Android</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div app id="section6-img">
            <img src="assets/images/app.png" class="img-responsive" width="1000" height="550" alt="">
        </div>
        <!-- id="about-lakehouse" -->
        <div about-lakehouse id="section7">
            <p title>Quick Links</p>
            <h3>Learn more about Lakehouse </h3>
            <div>
                <div>
                    <div>
                        <img src="assets/images/faq-about.svg" alt=""><span>Lakehouse FAQs</span>
                        <!-- <img src="assets/images/link-download.svg" alt=""> -->
                        <a href="https://kpmgus.sharepoint.com/sites/US-OI-BUS-Lakehouse/SiteAssets/Forms/AllItems.aspx?id=%2Fsites%2FUS%2DOI%2DBUS%2DLakehouse%2FSiteAssets%2FLakehouse%20FAQs%2Epdf&parent=%2Fsites%2FUS%2DOI%2DBUS%2DLakehouse%2FSiteAssets" target="_blank"><img src="assets/images/link-external.svg" alt=""></a>
                    </div>
                    <div>
                        <img src="assets/images/podcasts-about.svg" alt=""><span>See the Lakehouse Experience</span>
                        <a href="https://kpmgus.sharepoint.com/sites/US-OI-BUS-Lakehouse" target="_blank"><img src="assets/images/link-external.svg" alt=""></a>
                    </div>
                </div>
                <div>
                    <div>
                        <img src="assets/images/perks-about.svg" alt=""><span>Travel Benefits for Lakehouse</span>
                        <a href="https://kpmgus.sharepoint.com/sites/US-OI-BUS-Travel/SitePages/Vendor-Benefits-Promos.aspx#lakehouse" target="_blank"><img src="assets/images/link-external.svg" alt=""></a>
                    </div>
                    <div>
                        <img src="assets/images/away-about.svg" alt="">
                        <div>
                            <span>While You’re Away</span>
                            <span>KPMG Support Resources</span>
                        </div>
                        <a href="https://kpmgus.sharepoint.com/sites/US-OI-BUS-People/SitePages/WellBeingBenefitsforTravelers.aspx" target="_blank"><img src="assets/images/link-external.svg" alt=""></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
