import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
  transform(records: Array<any>, args?: any): any {
    if (!records) {
      return records;
    }
    return records.sort(function (a, b) {
        const aCompare = args.isDateSort ? new Date(a[args.property]) : a[args.property].toLocaleLowerCase();
        const bCompare = args.isDateSort ? new Date(b[args.property]) : b[args.property].toLocaleLowerCase();
        if(args.isDateSort){
          if ( !isNaN(bCompare) && !isNaN(aCompare)) {
            return (aCompare < bCompare ? -1 : (aCompare > bCompare ? 1 : 0)) * args.direction;
          } else {
            let value = a[args.property]?.toLocaleLowerCase()?.localeCompare(b[args.property]?.toLocaleLowerCase(), undefined, { numeric: true, sensitivity: 'base' });
            return (value === -1 ? -1 : (value === 1 ? 1 : 0)) * (args.direction * -1);
          }
        }
        let value = aCompare.localeCompare(bCompare, undefined, { numeric: true, sensitivity: 'base' });
        return (value === -1 ? -1 : (value === 1 ? 1 : 0)) * args.direction;
    });
  }
}