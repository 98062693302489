import { ICategoryDisplay } from "../models/ICategoryDisplay";

export const CATEGORY_DISPLAY_MAP: Map<string, ICategoryDisplay> = new Map<string, ICategoryDisplay>([

    ["course", {
        name: "course",
        description: "Course",
        selectName: "course"
    }],

    ["guest", {
        name: "guest",
        description: "Guest",
        selectName: "guest"
    }],

    ["meeting", {
        name: "meeting",
        description: "Meeting Room",
        selectName: "meeting room"
    }],

    ["house", {
        name: "house",
        description: "House and Well Being Event",
        selectName: "event"
    }],

    ["program", {
        name: "program",
        description: "Program",
        selectName: "program"
    }],

    ["session", {
        name: "session",
        description: "Session",
        selectName: "session"
    }],
    ["facilitator", {
        name: "facilitator",
        description: "Facilitator",
        selectName: "facilitator"
    }]

]);