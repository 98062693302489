
export namespace ProgramActions {

    export interface IGetClassesByProgramId {
        programId: string;
        emailId?: string;
    }

    export class GetPrograms {
        public static readonly type = '[Programs] Get all programs';
        constructor() {}
    }

    export class ClearProgramOverview {
        public static readonly type = '[Programs] Clear all programs overview, classes, sessions cached data';
        constructor() {}
    }

    export class ClearPrograms {
        public static readonly type = '[Programs] Clear all programs';
        constructor() {}
    }

    export class GetProgramsOverview {
        public static readonly type = '[Programs] Set programs overview';
        constructor(public programId: string) {}
    }

    export class GetClassesByProgramId {
        public static readonly type = '[Programs] Get Classes By Program Id';
        constructor(public payload: IGetClassesByProgramId) {}
    }

    export class ClearClasses {
        public static readonly type = '[Programs] Clear Classes';
        constructor() {}
    }

    export class GetSessionsByProgramId {
        public static readonly type = '[Programs] Get Sessions By Program Id';
        constructor(public programId: string) {}
    }

}