import { Component, Input, OnInit } from '@angular/core';
import { ProgramService } from '../../../../services/aws/program/program.service';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { ReportService } from 'src/app/services/aws/report/report.service';
import { IParticipant } from 'src/app/models/IParticipant';
import { IErrorMessage } from 'src/app/widgets/error-message/error-message.interface';
import { API_USER_DEFINED_MESSAGE } from 'src/app/widgets/error-message/error-message.const';

@Component({
    selector: 'program-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

    @Input()
    parentSpinner: boolean;
    
    mode: string = 'view';
    searchmode: string = 'open';
    programId: any;
    participants: IParticipant[];
    public errorMessage: IErrorMessage = {};
    isDesc: boolean = false;
    column: string = 'lastName';
    direction: number;
    isLastNameAsc: boolean = false;
    isEmailAsc: boolean = false;
    isFlightToAsc : boolean = false;
    isFlightFromAsc : boolean = false;
    isHotelStatusAsc: boolean = false;
    isCourseStatusAsc: boolean = false;
    isFlightArrivalStatusAsc: boolean = false;
    isFlightDepartureStatusAsc: boolean = false;
    isSessionStatusAsc: boolean = false;
    isInviteeStatusAsc : boolean = false;
    isRegistrationTypeAsc : boolean = false;
    programName: string;
    sDate: any;
    eDate: any;
    showLoading: boolean = false;
    public isDateSort: boolean = false;

    constructor(private programservice: ProgramService, private reportService: ReportService, private route: ActivatedRoute) {
        this.route.params.pipe(take(1)).subscribe(params => {
            this.programId = params["id"];
        });
    }

    ngOnInit() {
        this.getList();
    }

    public getList() {
        this.errorMessage = {};
        this.showLoading=this.parentSpinner;
        this.programservice.getParticipantsByProgram(this.programId)
        .subscribe((response: any) => {
            this.showLoading=false;
            this.errorMessage.text = response?.length? '' : API_USER_DEFINED_MESSAGE.EMPTY_RESPONSE;
            response.forEach((participant: IParticipant) => {
                if (participant?.hotelStatus === 'Declined Room') {
                    participant.hotelStatus = 'Declined';
                }
            });
            this.participants = response;
            // this.sort('lastName');
            return response;
        },
        () => {
            this.showLoading=false;
            this.participants = [];
            this.errorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
        });

    }

    toggleRegistration() {
        this.mode = this.mode != 'edit' ? 'edit' : 'view';
        this.searchmode = this.searchmode != 'close' ? 'close' : 'open';
        if (this.mode == 'edit' && this.participants != undefined)
            this.participants= this.participants.filter(x => x.hotel != null && x.hotel.status != null && x.hotel.status.toUpperCase() == 'WAITLIST');
        else this.getList();
    }

    sort(property, event) {
        event.stopPropagation();
        this.isDesc = !this.isDesc;
        this.column = property;
        this.direction = this.isDesc ? 1 : -1;
        this.isDateSort = false;
        switch (property) {
            case 'lastName':
                this.isLastNameAsc = !this.isLastNameAsc;
                break;
            case 'email':
                this.isEmailAsc = !this.isEmailAsc;
                break;
            case 'flightTo':
                this.isFlightToAsc = !this.isFlightToAsc;
                break;
            case 'flightFrom':
                this.isFlightFromAsc = !this.isFlightFromAsc;
                break;
            case 'hotelStatus':
                this.isHotelStatusAsc = !this.isHotelStatusAsc;
                break;
            case 'courseStatus':
                this.isCourseStatusAsc = !this.isCourseStatusAsc;
                break;
            case 'sessionStatus':
                this.isSessionStatusAsc = !this.isSessionStatusAsc;
                break;
            case 'flightArrival':
                this.isFlightArrivalStatusAsc = !this.isFlightArrivalStatusAsc;
                this.isDateSort = true;
                break;
            case 'flightDeparture':
                this.isFlightDepartureStatusAsc = !this.isFlightDepartureStatusAsc;
                this.isDateSort = true;
                break;
            case 'inviteeStatus':
                this.isInviteeStatusAsc = !this.isInviteeStatusAsc;
                break;
            case 'registrationType':
                this.isRegistrationTypeAsc = !this.isRegistrationTypeAsc;
                break;
        }
    }

    download() {
        this.reportService.getRegistrationStatusReport(this.programId);
    }
}
