<div class="position-relative" >
  <button mat-stroked-button [color]="isFilterActive ? 'primary' : ''" (click)="toogleFilter()">
    <img [src]="'assets/icons/filter-table-button' + (isFilterActive ? '-active' : '') + '.svg'"> Filters
  </button>

  <div *ngIf="displayFilter" class="filter-dropdown position-absolute font-size-14 " >

    <form [formGroup]="filterForm">

      <div class="filter-header">
        <div>
          <label class="text-gray-light">Filters</label>
        </div>
        <div>
          <!-- <button class="font-weight-600" mat-button color="primary" [disabled]="!this.filterForm.valid || !this.filterForm.get('filterType').value" (click)="applyFilter()">Apply</button> -->
          <button class="font-weight-600" mat-button color="primary" [disabled]="!this.filterForm.get('filterType').value" (click)="resetFilter()">Clear</button>
        </div>
      </div>

      <div class="font-size-16">

        <div class="m-t-md">
          <mat-radio-group formControlName="filterType">
            <mat-radio-button  value="TODAY" class="font-weight-600" (change)="filterTypeChange('TODAY')" color="primary">Today</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="m-t-md">
          <mat-radio-group formControlName="filterType">
            <mat-radio-button value="ON" class="font-weight-600" (change)="filterTypeChange('ON')" color="primary">On</mat-radio-button>
          </mat-radio-group>
          <mat-form-field class="m-l-xxl" floatLabel='never'>
            <input matInput name="on-date" autocomplete="off" formControlName="onDate" [matDatepicker]="onDatePicker"  placeholder="mm/dd/yyyy">
            <mat-datepicker-toggle matSuffix [for]="onDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #onDatePicker></mat-datepicker>
            <mat-error *ngIf="filterForm.controls.onDate.touched && filterForm.controls.onDate.invalid">
              Valid date is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="m-t-md">
          <mat-radio-group formControlName="filterType">
            <mat-radio-button value="FROM" class="font-weight-600" (change)="filterTypeChange('FROM')" color="primary">From</mat-radio-button>
          </mat-radio-group>
          <div>
            <mat-form-field class="m-l-xxl" floatLabel='never'>
              <input matInput name="from-date" autocomplete="off" formControlName="startDate" [matDatepicker]="startDatePicker" placeholder="mm/dd/yyyy">
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
              <mat-error *ngIf="filterForm.controls.startDate.touched && filterForm.controls.startDate.invalid">
                Valid date is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="m-l-xxl">
            <label class="m-t-sm m-b-sm font-weight-600">to</label>
          </div>
          <div>
            <mat-form-field class="m-l-xxl" floatLabel='never'>
              <input matInput name="to-date" autocomplete="off" formControlName="endDate" [matDatepicker]="endDatePicker" [min]="filterForm.controls['startDate'].value"
                placeholder="mm/dd/yyyy">
              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
              <mat-error *ngIf="filterForm.controls.endDate.touched && filterForm.controls.endDate.invalid">
                Valid date is required.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="m-t-md" *ngIf="searchableOptions?.length">
          <mat-radio-group formControlName="filterType">
            <mat-radio-button value="CREATED_BY" class="font-weight-600" (change)="filterTypeChange('CREATED_BY')" color="primary">Created by</mat-radio-button>
          </mat-radio-group>
          <mat-form-field class="m-l-xxl"  floatLabel='never' >
            <input type="text"
                   #autoCompleteInput
                   placeholder="Search names"
                   aria-label="Created By"
                   matInput
                   formControlName="searchableOptions"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

    </form>

    <div class="center" id="applyDateFilter">
      <button class="textblue cursor-pointer" mat-button color="primary" [disabled]="!this.filterForm.valid || !this.filterForm.get('filterType').value" (click)="applyFilter()">Apply</button>
      <!-- <button mat-button class="font-weight-600" color="primary" (click)="toogleFilter()">Cancel</button> -->
    </div>

  </div>