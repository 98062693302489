<div [attr.collapsed]="showHide">
    <div class="title">
        <!-- <img alt="kpmg logo" class="dark-logo" src="assets/images/lakehouse-logo.svg" />
        <div>
            <img alt="kpmg logo" class="dark-logo" src="assets/images/kpmg-logo.svg" />
            <span>Lakehouse</span>
        </div> -->
        <img src="assets/images/kpmg-logo.jpg" alt="">
        <i class="material-icons" (click)="toggleMenu()">play_arrow</i>
    </div>
    <div>
        <ng-content select="[main]"></ng-content>
    </div>
</div>
<div [attr.collapsed]="showHideSub">
    <div class="title">
        <label>{{ subMenuheading }}</label>
        <button sidenav-close (click)="closeSub()"><i class="material-icons">close</i></button>
    </div>
    <div>
        <ng-content select="[sub]"></ng-content>
    </div>
</div>