<div left>
    <div class="title">
        <div>
            <img alt="kpmg logo" class="dark-logo" src="assets/images/white.png" />
        </div>
    </div>
    <div class="left-content">
        <p>Error: 403 Forbidden</p>
        <span>
            Sorry, you do not have permission to access this page or <br />
            resource. Please <a href="#">contact administration</a> if you have any <br />
            questions or concerns.
        </span>
    </div>
</div>
<div right>
    <p>4<img src="assets/images/403@2x.png" />3</p>
</div>