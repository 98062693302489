import { ISessionsMetaData } from "../interface/sessions.interface";

export class SessionDetailsMetadataModel {

    public sessionId: string;

    public title: string;

    public startDate: string;

    public endDate: string;

    public location: string;

    public checkInCode: string;

    constructor(data: ISessionsMetaData) {
        this.setValues(data);
    }

    private setValues(data: ISessionsMetaData): void {
        this.title = data?.title || 'N/A';
        this.sessionId = data?.classId?.toString() || 'N/A';
        this.startDate = data?.startDate;
        this.endDate = data?.endDate;
        this.location = data?.location || 'TBD';
        this.checkInCode=data?.checkInCode || '';
    }

}