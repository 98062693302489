<div breadcrumbs [option]='breadcrumbOptions'></div>
<h1 prog-title>Programs</h1>
<div program-setting *ngIf="programs; else loading">
    <div>
        <div program-list>
            <div head [attr.mode]="headmode">
                <div left></div>
                <div right>
                    <span><img src="assets/icons/settings-table-white.svg" alt=""></span>
                    <span>Program Settings Mode</span>
                    <section cancel-msg *ngIf="popover">
                        <div>
                            <span>Are you sure you want to cancel and lose changes?</span>
                            <article>
                                <button (click)="cancel(false)">Cancel</button>
                                <button (click)="keepediting()">Keep Editing</button>
                            </article>
                        </div>
                    </section>
                    <figure *ngIf="!isDisplay">
                        <button cancel (click)="cancel(true)">Cancel</button>
                        <button save (click)="save()" [disabled]="isFormInvalid()">Save</button>
                    </figure>
                    <span *ngIf="isDisplay">
                        <i class="material-icons" (click)='toggleheader()'>clear</i>
                    </span>
                </div>
                <span><img src="assets/icons/programs-title.svg" alt=""></span>
                <div>Your Programs </div>
                <div right-head>
                    <mat-form-field class="m-r-sm" color="primary" hideLabel role="search">
                        <mat-icon matPrefix>search</mat-icon>
                        <input matInput placeholder="Search programs" type="search" class="form-control"
                            [(ngModel)]="searchTerm" maxlength="250" />
                    </mat-form-field>

                    <app-filter [filterInput]="filterInput" (appliedDateFilter)="appliedDateFilter($event)"
                        (clearFilter)="resetToDefault()" [displayFilter]="showFilter"
                        *ngIf="role === 'admin'"></app-filter>
                </div>
                <div close class="undo">
                    <span save *ngIf="isSaved">Saved successfully</span>
                    <span *ngIf="isSaved">
                        <i class="material-icons"> check_circle_outline</i>
                    </span>
                    <span (click)='toggleheader()'>
                        <i class="material-icons"> navigate_before</i>
                    </span>
                    <span><img src="assets/icons/settings-table.svg" alt=""></span>
                </div>
            </div>
            <div class="no-results-filter" *ngIf="isFilterRes; else displayTable">{{filterRes}}</div>
            <ng-template #displayTable>
                <table [attr.mode]="tablemode" class="table-fixedheader"
                    *ngIf="programs?.length; else displayErrorMessage">
                    <thead>
                        <th class="edit" (click)="sort('name')">
                            <span>Name</span>
                            <img [ngClass]="{'active':isNameAsc}" src="assets/icons/sort-by-down.svg" alt="">
                        </th>
                        <th class="edit" (click)="sort('startDate')">
                            <span>Date</span>
                            <img [ngClass]="{'active':isDateAsc}" src="assets/icons/sort-by-down.svg" alt="">
                        </th>
                        <th class="edit" (click)="sort('programType')" *ngIf="role==='admin'">
                            <span>Program Type</span>
                            <img [ngClass]="{'active':programTypeAsc}" src="assets/icons/sort-by-down.svg" alt="">
                        </th>
                        <th class="edit">
                            <span>{{(role==='admin') ? 'Sessions' : 'Events'}}</span>
                        </th>
                        <th class="edit">
                            <span>Attendees</span>
                        </th>
                        <th class="last-view">
                            <div>
                                <div>
                                    <span>Course Registration URL</span>
                                </div>
                                <div>
                                    <span>Cvent Registration URL</span>
                                </div>
                            </div>
                        </th>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let item of programs | searchFilter: searchTerm: 'name'| orderBy: {property: column, direction: direction}; let i = index">
                            <td class="edit">
                                <div>
                                    <span>
                                        <a
                                            [routerLink]="[role === 'admin' ? '/admin/programs' : '/admin/programs-external',item.id, 'program-overview']">{{item?.name}}</a>
                                    </span>
                                </div>
                            </td>
                            <td class="edit">
                                <div>
                                    <span>{{item.startDate | dateFormat : 'mmddyy'}} - {{item.endDate | dateFormat :
                                        'mmddyy'}}</span>
                                    <span>{{item.startDate | getdays : item.endDate}} days</span>
                                </div>
                            </td>
                            <td class="edit" *ngIf="role==='admin'">
                                <div>
                                    <span>{{ item?.programType }}</span>
                                </div>
                            </td>
                            <td class="edit">
                                <div>
                                    <div>
                                        <span>{{(item.counts)?.classes || 0}}</span>
                                        <span>Classes</span>
                                    </div>
                                    <div>
                                        <span>{{(item.counts)?.events || 0}}</span>
                                        <span>{{(role==='admin') ? 'Session' : 'Events'}}</span>
                                    </div>
                                </div>
                            </td>
                            <td class="edit">
                                <div>
                                    <div>
                                        <span>{{(item.counts)?.participants || 0}}</span>
                                        <span>Participants</span>
                                    </div>
                                    <div>
                                        <span>{{(item.counts)?.facilitators || 0}}</span>
                                        <span>Facilitators</span>
                                    </div>
                                </div>
                            </td>
                            <td class="last-view">
                                <div>
                                    <div>
                                        <input class="mat-input-element bottom-grey-border-override" id="{{item.id}}"
                                            data-urlName="courseUrl"
                                            [attr.data-ceventRegistrationUrl]="item?.programSetting?.ceventRegistrationUrl"
                                            #subject placeholder="https://…" maxlength="250"
                                            [(ngModel)]="item.programSetting.courseRegistrationUrl"
                                            (change)="valuechange($event, i)"
                                            (ngModelChange)="validateUrl(i, 'course')">
                                            <div class="error" *ngIf="courseUrlErrors[i]">{{ courseUrlErrors[i] }}</div>
                                    </div>
                                    <div>
                                        <input class="mat-input-element bottom-grey-border-override" id="{{item.id}}"
                                            data-urlName="cventUrl"
                                            [attr.data-courseRegistrationUrl]="item?.programSetting?.courseRegistrationUrl"
                                            #subject placeholder="https://…" maxlength="250"
                                            [(ngModel)]="item.programSetting.ceventRegistrationUrl"
                                            (change)="valuechange($event, i)"
                                            (ngModelChange)="validateUrl(i, 'cvent')">
                                            <div class="error" *ngIf="cventUrlErrors[i]">{{ cventUrlErrors[i] }}</div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-template>
        </div>
        <ng-template #displayErrorMessage>
            <p class="background-white m-t-xs">
                <span class="m-l-lg">
                    <error-message [errorMessage]="errorMessage"></error-message>
                </span>
            </p>
        </ng-template>
    </div>
</div>
<ng-template #loading>
    <div class="loading" *ngIf="!showLoading">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>