import { Component } from '@angular/core';

@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    styles: []
})
export class SettingsComponent {
    selected: string="Innovative creative learning";
    option = {
        // dropdowns: [],
        breadcrumb: [
            {
            label:'Dashboard',
            itemclass:'Breadcrumb-item',
            linkclass:'Breadcrumb-link',
            link:'/admin/dashboard'
        },
        {
            label:'Settings',
            itemclass:'Breadcrumb-item',
            linkclass:'Breadcrumb-link',
            link:'/admin/settings'
        }
    ],
        // toggle: {}
    };
    constructor() { }

}
