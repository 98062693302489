import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { API } from 'aws-amplify';
import { IReport } from '../../../models/IReport';
import { saveAs } from "file-saver";

API.configure({
    endpoints: [
        {
            name: "portal",
            endpoint: environment.aws.apiEndpoint
        }
    ]
});

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    constructor(private http: HttpClient) { }

    getReport(id: string): Observable<IReport> {
        return from(API.get('portal', 'reports/' + id, {})).pipe(
            map(data => {
                return data as IReport;
            })
        );
    }

    getFlightReport(id: string) {
        // not retuning an observable
        API.get('portal', 'reports/' + id + '?download', {})
            .then(payload => this.save(payload.data, (id == 'flightArrivals') ? 'FlightArrivalReports' : 'FlightDepartureReports'));
    }

    getRegistrationStatusReport(id) {
        // not returning an observable
        API.get('portal', 'programs/' + id + '/registrationStatus?download', {})
            .then(payload => this.save(payload.data, 'RegistrationStatusReports'));
    }

    getWellbeingCheckReport(programId, classId, reportName) {
        API.get('portal', 'programs/' + programId + '/courses/' + classId + '/participants?wellbeing', {})
            .then(payload => this.save(payload.data, reportName || 'WellbeingCheckReports'));
    }

    geParticipantCheckReport(programId, classId, reportName) {
        API.get('portal', 'programs/' + programId + '/courses/' + classId + '/participants?download', {})
            .then(payload => this.save(payload.data, reportName || 'ClassroomAttendanceReports'));
    }

    private save(data, name) {
        // get a date string and replace any non-# characters
        const dateString = (new Date()).toISOString().slice(0, 19).replace(/[^\d]/g, '');
        // strip invalid characters from filename, essentially anything not a word or space
        const fileName = name.replace(/[^\w\s-]/g, "");
        const blob = new Blob([atob(data)], { type: 'text/csv' });
        saveAs(blob, `${fileName}_${dateString}.csv`);
    }

    getEventReport(id, eventType, reportName) {
        API.get('portal', 'programs/' + id + '/download?eventType=' + eventType, {})
            .then(payload => this.save(payload.data, reportName || 'EventReports'));
    }

}


