import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { map, take, takeUntil } from "rxjs/operators";
import { IErrorMessage } from 'src/app/widgets/error-message/error-message.interface';
import { API_USER_DEFINED_MESSAGE } from 'src/app/widgets/error-message/error-message.const';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from "rxjs";
import { ConfirmDialogComponent } from 'src/app/widgets/confirm-dialog/confirm-dialog.component';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ProgramService } from 'src/app/services/aws/program/program.service';
import { UtilsService } from 'src/app/services/shared/utils.service';
import { ActivatedRoute } from '@angular/router';
import { IProgramDocs } from 'src/app/models/IProgram';

@Component({
    selector: 'app-program-documents',
    templateUrl: './program-documents.component.html',
    styleUrls: ['./program-documents.component.scss']
})
export class ProgramDocumentsComponent implements OnInit, OnDestroy {

    @Output()
    isProgramDocOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    parentSpinner: boolean;

    public programDocs: IProgramDocs[];
    public _programDocs: IProgramDocs[];
    programId: string;
    editedDocId: string;
    addRowExist: boolean = false;

    private destroy$ = new Subject();
    showLoading: boolean;
    isDesc: boolean = false;
    column: string;
    direction: number;
    isNameAsc: boolean = false;
    isDescriptionAsc: boolean = false;
    isUrlAsc: boolean = false;
    isRegisteredAsc: boolean = false;
    isMobileDisplayAsc: boolean = false;
    isFilterRes: boolean = false;
    filterRes: string = API_USER_DEFINED_MESSAGE.EMPTY_FILTER_RESPONSE;

    public invalidFileMessage = '';
    public eventsTable: FormGroup;
    public control: FormArray;
    public mode: boolean;
    public touchedRows;

    public isCopyRow: boolean;
    public disableUploadCta: boolean = true;
    private hasUnSavedChanges = false;

    public errorMessage: IErrorMessage = {};
    public fileEvent;
    public disableDeleteEditButons: boolean[] = [];
    public overlayDetails;
    public overlayData;
    public isEditable: boolean = true;
    public dialogUrlCheck: string;
    public editTableIndex: number;

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    public showGlobalSpinner: boolean = false;

    constructor(private fb: FormBuilder, private dialog: MatDialog, private snackBar: MatSnackBar,
        private programService: ProgramService, private utilsService: UtilsService, private route: ActivatedRoute) {
        this.route.params.pipe(take(1)).subscribe(params => {
            this.programId = params["id"];
        });
    }

    private get tableRows(): FormArray {
        return this.eventsTable.get('tableRows') as FormArray;
    }

    private setEventsTableValues(data): void {

        this.eventsTable.patchValue(data);

        const tableFormArray = (this.eventsTable.get('tableRows') as FormArray);

        while (tableFormArray.length) {
            tableFormArray.removeAt(0);
        }

        data.forEach(row => {
            tableFormArray.push(this.fb.group(row));
        });
    }

    public getAllProgramDocs(): void {
        this.showGlobalSpinner = this.parentSpinner;
        this.programService.getProgramDocuments(this.programId).pipe(
            takeUntil(this.destroy$),
            map((data: IProgramDocs[]) => data))
            .subscribe((response: IProgramDocs[]) => {
                this.showGlobalSpinner = false;
                this.errorMessage.text = response?.length ? '' : API_USER_DEFINED_MESSAGE.EMPTY_RESPONSE;
                this.programDocs = response;
                this._programDocs = response;
                this.setEventsTableValues(response);
                this.sort('default');
                this.validateDeleteEditButons();

            },
                () => {
                    this.showGlobalSpinner = false;
                    this.programDocs = [];
                    this.errorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
                });
    }

    ngOnInit() {
        this.touchedRows = [];
        this.eventsTable = this.fb.group({
            tableRows: this.fb.array([])
        });
        this.getAllProgramDocs();
    }


    private sort(property: string): void {
        this.isDesc = !this.isDesc;
        this.column = property;
        this.direction = this.isDesc ? 1 : -1;
        switch (property) {
            case 'name':
                this.isNameAsc = !this.isNameAsc;
                break;
            case 'desc':
                this.isDescriptionAsc = !this.isDescriptionAsc;
                break;
            case 'url':
                this.isUrlAsc = !this.isUrlAsc;
                break;
            case 'mobileDisplay':
                this.isMobileDisplayAsc = !this.isMobileDisplayAsc;
        }

        this.programDocs.sort((a, b) => {
            let aCompare;
            let bCompare;
            if (property === 'default') {
                aCompare = a[property];
                bCompare = b[property];
                return a.name?.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' });
            }
            else if (property === 'mobileDisplay') {
                aCompare = a[property];
                bCompare = b[property];
                return (aCompare < bCompare ? -1 : (aCompare > bCompare ? 1 : 0)) * this.direction;
            }
            else {
                aCompare = a[property]?.toLowerCase();
                bCompare = b[property]?.toLowerCase();
            }
            let value = aCompare.localeCompare(bCompare, undefined, { numeric: true, sensitivity: 'base' });
            return (value === -1 ? -1 : (value === 1 ? 1 : 0)) * this.direction;
        });
        this.setEventsTableValues(this.programDocs);
    }

    ngAfterOnInit() {
        this.control = this.eventsTable.get('tableRows') as FormArray;
    }



    private initiateForm(group?: FormGroup): FormGroup {
        return this.fb.group({
            id: [group?.get('id').value || null],
            name: [group?.get('name').value || null, [Validators.required]],
            desc: [group?.get('desc').value || null, [Validators.required]],
            url: [group?.get('url').value || null, [Validators.required, this.urlValidator]],
            isEditable: [true],
            readMore: [true],
            mobileDisplay: [group?.get('mobileDisplay').value || false]
        });
    }


    urlValidator: ValidatorFn = (control: AbstractControl) => {
        let validUrl = true;

        try {
            new URL(control.value)
        } catch {
            validUrl = false;
        }

        const pattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
            '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+!#;=,:]*)*' + // validate port and path
            '(\\?[;&a-zA_Z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-zA-Z\\d_]*)?$', 'i'); // validate fragment locator

        validUrl = pattern.test(control?.value?.trim());

        return validUrl ? null : { invalidUrl: true };
    }


    searchControl = this.fb.control(null, this.urlValidator);

    public addRow(): void {
        const control = this.eventsTable.get('tableRows') as FormArray;
        this.programDocumentEditMode(true);
        if (control.value[0]?.id !== null) {
            this.isCopyRow = false;
            this.setEventsTableValues(this.programDocs);
            control.insert(0, this.initiateForm());
            this.hasUnSavedChanges = true;
            this.validateDeleteEditButons();
            this.addRowExist = true;
        }
        else {
            control.removeAt(0);
            this.addRowExist = false;
            this.programDocumentEditMode(false);
        }
    }

    programDocumentEditMode(formOpen: boolean) {
        this.isProgramDocOpen.emit(formOpen);
    }

    private cancelRow(index: number, group: FormGroup): void {
        this.hasUnSavedChanges = false;
        this.programDocumentEditMode(false);
        let activityID = group.get('id').value;
        if (activityID !== null && this.isCopyRow !== true) {
            group.get('isEditable').setValue(false);
            group.get('readMore').setValue(false);
        }
        else {
            const control = this.eventsTable.get('tableRows') as FormArray;
            control.removeAt(index);
        }
        this.setEventsTableValues(this.programDocs);
        this.validateDeleteEditButons();
    }

    private deleteRow(index: number, group: FormGroup): void {
        this.isCopyRow = false;
        this.openConfirmDialog("Are you sure you want to delete this program document?")
            .pipe(take(1))
            .subscribe(result => {
                if (result) {
                    this.showGlobalSpinner = true;
                    let activityID = group.get('id').value;

                    this.programService.deleteProgramDocument(this.programId, activityID)
                        .pipe(take(1))
                        .subscribe(() => {
                            this.showGlobalSpinner = false;
                            this.programDocumentEditMode(false);
                            this.getAllProgramDocs();
                            this.snackBar.open('Program Document Deleted successfully.', 'OK', {
                                duration: 5000,
                                horizontalPosition: this.horizontalPosition,
                                verticalPosition: this.verticalPosition,
                                panelClass: ['success-snackbar']
                            });
                        },
                            () => {
                                this.showGlobalSpinner = false;
                                this.snackBar.open('Something went wrong, please try again later.', 'OK', {
                                    duration: 8000,
                                    horizontalPosition: this.horizontalPosition,
                                    verticalPosition: this.verticalPosition,
                                    panelClass: ['error-snackbar']
                                });
                            });

                }
            });

    }

    private copyRow(group: FormGroup, index: number): void {
        this.isCopyRow = true;
        const control = this.eventsTable.get('tableRows') as FormArray;
        let getStatus = group.get('status').value;
        if (getStatus === '0') {
            group.get('status').setValue('1');
        }
        control.insert(index + 1, this.initiateForm(group));
        this.hasUnSavedChanges = true;
        this.validateDeleteEditButons();
    }

    private editRow(group: FormGroup, rowCheck?: boolean): void {
        this.addRowExist = rowCheck;
        this.programDocumentEditMode(true);
        this.editedDocId = group?.get('id')?.value;
        const control: FormArray = this.eventsTable?.get('tableRows') as FormArray;
        const index = control.controls.findIndex(x => x.value == group.value);
        this.editTableIndex = index;

        if (!this.addRowExist) {
            for (let i = 0; i < control?.controls?.length; i++) {
                if (index !== i) {
                    this.cancelOpenRows(i + 1);
                }
            }
        }

        if (control.value[0]?.id == null) {
            control.removeAt(0);
            control.removeAt(index - 1);
            control.insert(index - 1, this.initiateForm(group));
            this.addRowExist = false;

        }
        else {
            control.removeAt(index);
            control.insert(index, this.initiateForm(group));
            this.isCopyRow = false;
            this.hasUnSavedChanges = true;
            this.addRowExist = false;
            const currentControl: FormArray = control.controls[index] as FormArray;
            // currentControl.controls['eventDetailName']['status'] = 'DISABLED';
        }

    }

    private cancelOpenRows(index: number): void {
        this.hasUnSavedChanges = false;
        const control = this.eventsTable.get('tableRows') as FormArray;
        control.removeAt(index);
        this.setEventsTableValues(this.programDocs);
        this.validateDeleteEditButons();
    }

    private validateDeleteEditButons(): void {
        this.disableDeleteEditButons = JSON.parse(JSON.stringify([]));
        const control = this.eventsTable.get('tableRows') as FormArray;
    }

    private addProgramDocument(fData: IProgramDocs, group?: FormGroup): void {
        this.showGlobalSpinner = true;
        this.programService.addProgramDocuments(this.programId, fData)
            .pipe(take(1))
            .subscribe(() => {
                this.showGlobalSpinner = false;
                this.programDocumentEditMode(false);
                this.hasUnSavedChanges = false;
                group.get('isEditable').setValue(false);
                group.get('readMore').setValue(false);
                this.getAllProgramDocs();
                this.snackBar.open('Program Document added successfully.', 'OK', {
                    duration: 5000,
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                    panelClass: ['success-snackbar']
                });
            },
                () => {
                    this.showGlobalSpinner = false;
                    this.snackBar.open('Something went wrong, please try again later.', 'OK', {
                        duration: 8000,
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        panelClass: ['error-snackbar']
                    });
                });
    }

    private updateProgramDocument(fData: IProgramDocs, group?: FormGroup): void {
        this.showGlobalSpinner = true;

        //updateDocument
        this.programService.updateDocument(this.programId, this.editedDocId, fData)
            .pipe(take(1))
            .subscribe(() => {
                this.showGlobalSpinner = false;
                this.programDocumentEditMode(false);
                this.hasUnSavedChanges = false;
                group.get('isEditable').setValue(false);
                group.get('readMore').setValue(false);
                this.getAllProgramDocs();
                this.snackBar.open('Program Document updated successfully.', 'OK', {
                    duration: 5000,
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                    panelClass: ['success-snackbar']
                });
            },
                () => {
                    this.showGlobalSpinner = false;
                    this.snackBar.open('Something went wrong, please try again later.', 'OK', {
                        duration: 8000,
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        panelClass: ['error-snackbar']
                    });
                });
    }

    private saveRow(group: FormGroup): void {

        let fData, id;
        id = group.get('id').value;

        if (id !== null && this.isCopyRow !== true) {
            fData =
            {
                "name": group.get('name').value,
                "desc": group.get('desc').value,
                "url": group.get('url').value,
                "mobileDisplay": group.get('mobileDisplay').value,
            };
            this.programDocumentUrlCheck(fData, group, false);
        }
        else {

            fData =
            {
                "name": group.get('name').value,
                "desc": group.get('desc').value,
                "url": group.get('url').value,
                "mobileDisplay": group.get('mobileDisplay').value,
            };
            this.programDocumentUrlCheck(fData, group, true);
        }

    }

    private get getFormControls() {
        const control = this.eventsTable.get('tableRows') as FormArray;
        return control;
    }

    private submitForm(): void {
        const control = this.eventsTable.get('tableRows') as FormArray;
        this.touchedRows = control.controls.filter(row => row.touched).map(row => row.value);
    }

    private openConfirmDialog(body: string): Observable<undefined> {
        return this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: "",
                body: body,
                buttonTextNo: "Cancel",
                buttonTextYes: "Yes, delete it!"
            },
            panelClass: 'notification-submit-dialog'
        }).afterClosed();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    private isUrlValueExist = (inputUrl: string, isNewDoc: boolean): boolean => {
        let copyProgramDocs = this.programDocs;

        isNewDoc
            ? copyProgramDocs
            : copyProgramDocs.splice(this.editTableIndex, 1); // Edit Mode remove current element check

        if (copyProgramDocs?.some((element) => element.url == inputUrl)) {
            return true;
        }
        else {
            return false;
        }
    }

    private programDocumentUrlCheck(fData: IProgramDocs, group: FormGroup, isNewDoc: boolean): void {

        this.dialogUrlCheck = fData?.url?.trim();
        const urlExist = this.isUrlValueExist(this.dialogUrlCheck, isNewDoc);

        if (!urlExist) {
            isNewDoc ? this.addProgramDocument(fData, group) : this.updateProgramDocument(fData, group);
        }
        else {
            this.openConfirmDialogUrl("The URL for this program document already exists. Please choose a different URL to save your document.")
                .pipe(take(1))
                .subscribe(result => {
                    if (result) {
                        isNewDoc ? this.addProgramDocument(fData, group) : this.updateProgramDocument(fData, group);
                    }
                })
        }
    }

    private openConfirmDialogUrl(body: string): Observable<undefined> {
        return this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: this.dialogUrlCheck,
                body: body,
                buttonTextNo: "Ok",
                buttonTextYes: "",
            },
            width: '900px',
            panelClass: 'custom-confirm-url-dialog'
        }).afterClosed();
    }

}
