import { Component, Input } from '@angular/core';
import { NavMenuService } from '../../services/nav-menu/nav-menu.service';

@Component({
    selector: 'side-nav',
    templateUrl: './side-nav.component.html',
    styles: []
})
export class SideNavComponent {
    showHide: boolean;
    showHideSub: boolean;
    @Input() programs: boolean;
    reporting: boolean;
    settings: boolean;
    test: boolean = true;
    subMenuheading: string;
    navdata: any[];
    // isiclClasssActive: boolean;
    // isbetClasssActive: boolean;
    // issaulClasssActive: boolean;
    isActive:boolean= false;
    isActiveClass:string;
    activeLink: string = 'dashboard';
    constructor(menus: NavMenuService) {
        this.showHide = false;
        this.showHideSub = true;
        this.programs = false;
        this.reporting = false;
        this.settings = false;
        this.subMenuheading = '';
        this.navdata = menus.menuItems;
        // this.isiclClasssActive = false;
        // this.isbetClasssActive = false;
        // this.issaulClasssActive = false;
    }

    toggleMenu() {
        this.showHide = !this.showHide;
       this.showHideSub=true;
       // this.closeSub();
    }

    openSub(subMenuName: string) {
        this.activeLink = subMenuName;
        if (subMenuName == 'programs') {
            this.subMenuheading = 'Programs';
            this.programs = true;
            this.reporting = false;
            this.settings = false;
        }
        else if (subMenuName == 'reporting') {
            this.subMenuheading = 'Reporting';
            this.programs = false;
            this.reporting = true;
            this.settings = false;
        }
        else if (subMenuName == 'settings') {
            this.subMenuheading = 'Settings';
            this.programs = false;
            this.reporting = false;
            this.settings = true;
        }
        else {
            this.programs = false;
            this.reporting = false;
            this.settings = false;
        }

        this.showHideSub = false;
    }

    closeSub(subMenuName?) {
        if(subMenuName !== undefined)
        {
            this.activeLink = subMenuName;
        }
        this.showHideSub = !this.showHideSub;
    }

    isIconActive = (label: string) => (this.activeLink == label) && !this.showHideSub;

    activateClass(menuName: string) {
        this.isActiveClass= menuName;
        this.isActive = !this.isActive;
        // if (menuName == 'Innovative Creative Learning') {
        //     this.isiclClasssActive = !this.isiclClasssActive
        // }
        // else if (menuName.toLowerCase() == 'Innovative Creative Learning') {
        //     this.isbetClasssActive = !this.isbetClasssActive
        // }
        // else if (menuName.toLowerCase() == 'Innovative Creative Learning') {
        //     this.issaulClasssActive = !this.issaulClasssActive
        // }
    }
}
