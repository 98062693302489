import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable, throwError, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { API } from 'aws-amplify';
import { IConfirmation } from '../../../models/IConfirmation';

API.configure({
    endpoints: [
        {
            name: "portal",
            endpoint: environment.aws.apiEndpoint
        }
    ]
});

@Injectable({
    providedIn: 'root'
})
export class ClassRosterService {
    private programId: any;
    private classId: any;
    private mode: any;
    public target: any;
    private copy: any;
    private data: any;
    private dirty: boolean = false;

    edit(programId, classId, mode, target, copy = null) {
        this.programId = programId;
        this.classId = classId;
        this.mode = mode;
        this.target = target;
        this.copy = copy;
    }

    editMode(): string {
        return this.mode;
    }

    original(): any {
        return this.copy;
    }

    update(data?: any) {
        if (data) {
            this.dirty = true;
            this.data = data;
        }
        return this.data;
    }

    isDirty(): boolean {
        return this.dirty;
    }

    save() {
        if (this.target == "participant") {
            switch (this.mode) {
                case "insert":
                    return this.insertParticipantTimeMissed();
                case "update":
                    return this.updateParticipantTimeMissed();
                case "delete":
                    return this.deleteParticipantTimeMissed();
            }
        } else if (this.target == "facilitator") {
            switch (this.mode) {
                case "insert":
                    return this.insertFacilitatorMissingTime();
                case "update":
                    return this.updateFacilitatorMissingTime();
                case "delete":
                    return this.deleteFacilitatorMissingTime();
            }
        } else if (this.target == "checkInStatus") {
            return this.updateCheckIn();
        } else if (this.target == "facilitationResponse") {
            return this.facilitationResponse();
        } else if (this.target == "facilitation") {
            switch (this.mode) {
                case "insert":
                    return this.insertFacilitation();
                case "update":
                    return this.updateFacilitation();
                case "delete":
                    return this.deleteFacilitation();
            }
        } else if (this.target == "participation") {
            switch (this.mode) {
                case "insert":
                    return this.insertParticipation();
                case "update":
                    return this.updateParticipation();
                case "delete":
                    return this.deleteParticipation();
            }
        }
    }

    cancel() {
        this.dirty = false;
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    private updateCheckIn() {
        return from(API.patch('portal', 'programs/' + this.programId + '/courses/' + this.classId + "/participants", { body: this.data })).pipe(
            map(res => { })
        );
    }

    private insertParticipantTimeMissed() {
        return from(API.post('portal', 'programs/' + this.programId + '/courses/' + this.classId + "/participants", { body: this.data })).pipe(
            map(res => {
            })
        );
    }

    private updateParticipantTimeMissed() {
        return from(API.patch('portal', 'programs/' + this.programId + '/courses/' + this.classId + "/participants", { body: this.data })).pipe(
            map(res => { })
        );
    }

    private deleteParticipantTimeMissed() {
        return from(API.del('portal', 'programs/' + this.programId + '/courses/' + this.classId + "/participants", { body: this.data })).pipe(
            map(res => { })
        );
    }

    private insertFacilitatorMissingTime() {
        return from(API.post('portal', 'programs/' + this.programId + '/courses/' + this.classId + "/facilitators", { body: this.data })).pipe(
            map(res => {
            })
        );
    }

    private updateFacilitatorMissingTime() {
        return from(API.patch('portal', 'programs/' + this.programId + '/courses/' + this.classId + "/facilitators", { body: this.data })).pipe(
            map(res => { })
        );
    }

    private deleteFacilitatorMissingTime() {
        return from(API.del('portal', 'programs/' + this.programId + '/courses/' + this.classId + "/facilitators", { body: this.data })).pipe(
            map(res => { })
        );
    }

    getConfirmation(programId, classId) {
        return from(API.get('portal', 'programs/' + programId + "/courses/" + classId + "/confirmation", {})).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map((data:IConfirmation) => {
                return data as IConfirmation;
            })
        );
    }

    postConfirmation(confimrtionData: IConfirmation, programId, classId) {
        return from(API.post('portal', 'programs/' + programId + '/courses/' + classId + "/confirmation", { body: confimrtionData })).pipe(
            map(res => {
                return res as any;
            })
        );
    }

    getPersonDetail(email): Observable<any> {
        return from(API.get('portal', 'persons/' + email, {})).pipe(
            map(data => {
                return data as any;
            })
        );
    }

    private facilitationResponse() {
        if (this.data.facilitationResponse.id) {
            return from(API.patch('portal', 'programs/' + this.programId + '/courses/' + this.classId + "/facilitators?setting=true", { body: this.data })).pipe(
                map(res => { })
            );
        } else {
            return from(API.post('portal', 'programs/' + this.programId + '/courses/' + this.classId + "/facilitators?setting=true", { body: this.data })).pipe(
                map(res => { })
            );
        }
    }

    private insertFacilitation() {
        return from(API.post('portal', 'programs/' + this.programId + '/courses/' + this.classId + "/facilitators?setting=true", { body: this.data })).pipe(
            map(res => {
            })
        );
    }

    private updateFacilitation() {
        return from(API.patch('portal', 'programs/' + this.programId + '/courses/' + this.classId + "/facilitators?setting=true", { body: this.data })).pipe(
            map(res => { })
        );
    }

    private deleteFacilitation() {
        return from(API.del('portal', 'programs/' + this.programId + '/courses/' + this.classId + "/facilitators?setting=true", { body: this.data })).pipe(
            map(res => { })
        );
    }

    private insertParticipation() {
        return from(API.post('portal', 'programs/' + this.programId + '/courses/' + this.classId + "/facilitators?setting=true", { body: this.data })).pipe(
            map(res => {
            })
        );
    }

    private updateParticipation() {
        return from(API.patch('portal', 'programs/' + this.programId + '/courses/' + this.classId + "/facilitators?setting=true", { body: this.data })).pipe(
            map(res => { })
        );
    }

    private deleteParticipation() {
        return from(API.del('portal', 'programs/' + this.programId + '/courses/' + this.classId + "/facilitators?setting=true", { body: this.data })).pipe(
            map(res => { })
        );
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
