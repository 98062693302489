import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({ name: 'programs' })
export class ProgramPipe implements PipeTransform {
    modifiedrecords: any[];
    transform(records: any[], schedule: string): any {
        if (records != undefined) {
            return records.filter(x => {
                if (schedule == 'morning') {
                    if (x.startDate != undefined && Number(moment(x.startDate).format('HHmm')) < 1200)
                        return x.startDate;
                }
                else if (schedule.toLowerCase() == 'afternoon') {
                    if (x.startDate != undefined && Number(moment(x.startDate).format('HHmm')) >= 1200 && Number(moment(x.startDate).format('HHmm')) < 1700)
                        return x.startDate;
                }
                else if (schedule.toLowerCase() == 'evening') {
                    if (x.startDate != undefined && Number(moment(x.startDate).format('HHmm')) >= 1700)
                        return x.startDate;
                }
            });
        }
    }
}
