<div panel-list *ngIf="sessions;else loading">
    <div>
        <div class-roster-body>
            <div class-roster-title>
                <h1>All Sessions</h1>
                <div (click)="downloadReport()" class="export-button">Export to Excel
                    <img src="assets/icons/excel.svg" alt="" ml10>
                </div>
            </div>
        </div>
    </div>
    <div Class-list>
        <div head [attr.mode]="searchmode">
            <div left>
            </div>
            <div right>
                <span><i class="material-icons">settings</i></span>
                <span>Event Setting Mode</span>
                <span [ngClass]="{'disable':!isDisplay}" (click)="togglesession()"><i
                        class="material-icons">clear</i></span>
                <span [ngClass]="{'disable':isDisplay}">
                    <button (click)="cancel()">Cancel</button>
                    <button (click)="save()">Save</button>
                </span>
                <div close class="undo">
                    <span save *ngIf="isSaved">Saved successfully</span>
                    <span *ngIf="isSaved">
                        <i class="material-icons"> check_circle_outline</i>
                    </span>
                </div>
            </div>
            <span><i class="material-icons">local_library</i></span>
            <div>Sessions ( {{sessions.length}} )</div>
            <div right-head>
            </div>
        </div>
         <table [attr.mode]="mode" class="table-fixedheader" *ngIf="sessions?.length; else displayErrorMessage">
            <thead>
                <th class="edit" (click)="sort('startDate','status', $event)">
                    <span>Status</span>
                    <i class="material-icons" [ngClass]="{'active':isStatusAsc}"> arrow_drop_down</i>
                </th>
                <th class="edit" (click)="sort('checkInCode','', $event)">
                    <span>Check-in Code</span>
                    <i class="material-icons" [ngClass]="{'active':isCheckInCodeAsc}"> arrow_drop_down</i>
                </th>
                <th class="edit" (click)="sort('title','', $event)">
                    <span>Session Name</span>
                    <i class="material-icons" [ngClass]="{'active':isTitleAsc}">arrow_drop_down</i>
                </th>
                <th class="edit" (click)="sort('classId','', $event)"><span>Session ID</span>
                    <i class="material-icons" [ngClass]="{'active':isIdAsc}"> arrow_drop_down</i>
                </th>
                <th class="view">
                    <div>
                        <div (click)="sort('title','', $event)">
                            <span>Session Name</span>
                            <i class="material-icons" [ngClass]="{'active':isTitleAsc}"> arrow_drop_down</i>
                        </div>
                        <div (click)="sort('classId','', $event)">
                            <span>Session ID</span>
                            <i class="material-icons" [ngClass]="{'active':isIdAsc}"> arrow_drop_down</i>
                        </div>
                    </div>
                </th>
                <th class="edit" (click)="sort('startDate','startdate', $event)">
                    <span>Date</span>
                    <i class="material-icons" [ngClass]="{'active':isDateAsc}">arrow_drop_down</i>
                </th>
                <th class="edit" (click)="sort('startDate','starttime', $event)">
                    <span>Start / end time</span>
                    <i class="material-icons" [ngClass]="{'active':isTimeAsc}">arrow_drop_down</i>
                </th>
                <th class="edit" (click)="sort('location','', $event)">
                    <span>Location</span>
                    <i class="material-icons" [ngClass]="{'active':isLocationAsc}">arrow_drop_down</i>
                </th>
                <th class="edit" (click)="$event?.stopPropagation()">
                    <span>Attendees</span>
                </th>
                <th class="last-view">
                    <div>
                        <div>
                            <span>
                                <input type="checkbox" class="example-margin" [(ngModel)]="isQaSelected" value="m1"
                                    name="qa" (change)="checkUncheckAll()" />
                            </span>
                            <span>Session QA</span>
                            <i class="material-icons">arrow_drop_down</i>
                        </div>
                    </div>
                </th>
            </thead>
            <tbody>
                <tr *ngFor="let session of sessions | orderBy: {property: column, direction: direction}">
                    <td class="disable edit">
                        <div>
                            <span></span>
                            <span class="{{session?.startDate |classcolor : session?.endDate }}">
                                {{session?.startDate |classstatus : session?.endDate }}
                            </span>
                        </div>
                    </td>
                    <td class="edit">
                        <div class="normal">
                            {{session?.isCpeEligible===true ? session?.checkInCode:''}}
                        </div>
                    </td>
                    <td class="edit">
                        <div class="anchor">
                            <span (click)='openDialog(session)'>{{session?.title}}</span>
                        </div>
                    </td>
                    <td class="edit">
                        <div>
                            <div>
                                <span>{{session?.classId}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="view">
                        <div>
                            <span>{{session?.title}}</span>
                            <span>{{session?.classId}}</span>
                        </div>
                    </td>
                    <td class="default">
                        <div>
                            <span>
                                <input type="checkbox" class="example-margin" value="{{session?.eventSetting?.qa}}"
                                    name="qa" (change)="toggleEventChange($event, session?.eventSetting)" />
                            </span>
                        </div>
                    </td>
                    <td class="edit">
                        <div>
                            <span><i class="material-icons">date_range</i></span>
                            <div>
                                <span>{{session?.startDate | dateFormat : 'mmddyyyy'}}</span>
                                <span>{{session?.startDate | dateFormat : 'fulldayname'}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="edit">
                        <div>
                            <span><i class="material-icons">access_time</i></span>
                            <div>
                                <span>{{session?.startDate | timeFormat}} - </span>
                                <span>{{session?.endDate | timeFormat}} ET</span>
                            </div>
                        </div>
                    </td>
                    <td class="edit">
                        <div>
                            <span><img src="assets/icons/location-table.svg" alt=""></span>
                            <span>{{session?.location}} </span>
                        </div>
                    </td>
                    <td class="edit">
                        <div>
                            <div>
                                <span>Participants</span>
                            </div>
                            <div>
                                <span>{{session?.participants || 0}}</span>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<ng-template #loading>
    <div class="loading" *ngIf="showLoading; else displayErrorMessage">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>

<ng-template #displayErrorMessage>
    <p class="background-white m-t-xs noResults">
        <span class="m-l-lg">
            <error-message [errorMessage]="errorMessage"></error-message>
        </span>
    </p>
</ng-template>

<div *ngIf="showLoading">
    <div class="loading">
        <mat-spinner></mat-spinner>
    </div>
</div>