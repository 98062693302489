<div panel-list *ngIf="classes; else loading">
    <div *ngIf=isAdminRole>
        <div class-roster-body>
            <div class-roster-title>
                <h1>All Classes</h1>
                <div (click)="downloadReport()" class="export-button">Export to Excel
                    <img src="assets/icons/excel.svg" alt="" ml10>
                </div>
            </div>
        </div>
    </div>
    <div Class-list>
        <div head [attr.mode]="searchmode">
            <div left>
                <div class="m-l-xs"><img src="assets/icons/courses-title.svg" alt="">
                    <span>
                        Classes ({{classes.length}})
                    </span>
                    </div>
            </div>
            <div right>
                <span><img src="assets/icons/settings-table-white.svg" alt=""></span>
                <span>Class Setting Mode</span>
                <section cancel-message *ngIf="popover">
                    <div>
                        <span>Are you sure you want to cancel and lose changes?</span>
                        <article>
                            <button (click)="cancel(false)">Cancel</button>
                            <button (click)="keepediting()">Keep Editing</button>
                        </article>
                    </div>
                </section>
                <span [ngClass]="{'disable':!isDisplay}" (click)="toggleclass()"><i class="material-icons">clear</i></span>
                <span [ngClass]="{'disable':isDisplay}">
                    <button (click)="cancel(true)">Cancel</button>
                    <button (click)="saveClass()" [disabled]="popover">Save</button>
                </span>
            </div>
            <span><img src="assets/icons/courses-title.svg" alt=""></span>
            <div>Classes ({{classes.length}})</div>
            <div right-head></div>
            <div close>
                <span (click)="toggleclass()"><i class="material-icons"> navigate_before</i></span>
                <span><img src="assets/icons/settings-table.svg" alt=""></span>
            </div>
            <div close class="undo" *ngIf="isSaved">
                <span save *ngIf="isSaved">Saved successfully</span>
                <span *ngIf="isSaved">
                    <i class="material-icons"> check_circle_outline</i>
                </span>
            </div>
        </div>
        <table tblock [attr.mode]="mode" class="table-fixedheader" *ngIf="classes?.length; else displayErrorMessage">
            <thead>
                <th class="edit" (click)="sort('startDate','status', $event)"  *ngIf="mode === 'view'">
                    <span>Status</span>
                    <img [ngClass]="{'active':isStatusAsc}" src="assets/icons/sort-by-down.svg" alt="">
                </th>
                <th class="edit"  *ngIf="mode === 'view'">

                </th>
                <th class="edit" (click)="sort('checkInCode','', $event)">
                    <span>CHECK-IN CODE</span>
                    <img [ngClass]="{'active':checkInCode}" src="assets/icons/sort-by-down.svg" alt="">
                </th>
                
                <th class="edit" (click)="sort('title','', $event)">
                    <span>Class Name</span>
                    <img [ngClass]="{'active':isTitleAsc}" src="assets/icons/sort-by-down.svg" alt="">
                </th>
                
                <th class="edit" (click)="sort('classId','', $event)">
                    <span>Class ID</span>
                    <img [ngClass]="{'active':isIdAsc}" src="assets/icons/sort-by-down.svg" alt="">
                </th>
                <th class="edit" (click)="sort('startDate','startdate', $event)">
                    <span>Date</span>
                    <img [ngClass]="{'active':isDateAsc}" src="assets/icons/sort-by-down.svg" alt="">
                </th>
                <th class="edit" (click)="sort('startDate','starttime', $event)">
                    <span>Start / end time</span>
                    <img [ngClass]="{'active':isTimeAsc}" src="assets/icons/sort-by-down.svg" alt="">
                </th>
                <th class="edit" (click)="sort('location', '', $event)">
                    <span>Location</span>
                    <img [ngClass]="{'active':isLocationAsc}" src="assets/icons/sort-by-down.svg" alt="">
                </th>
                <th class="edit" *ngIf="mode === 'view'" (click)="$event?.stopPropagation()">
                    <span>Attendees</span>
                </th>
                <th class="last-view">
                    <div>
                        <div>
                            <span>
                                <input type="checkbox" class="example-margin" value="m1" name="participant" [checked]="checkAll" (change)="checkUncheckAll($event)"/>
                            </span>
                            <span>Facilitator Attendance</span>
                        </div>
                    </div>
                </th>

            </thead>
            <tbody>
                <tr *ngFor="let classInfo of classes | orderBy: {property: column, direction: direction}; let i = index">
                    <td class="disable edit"  *ngIf="mode === 'view'">
                        <div>
                            <span></span>
                            <span class="{{classInfo?.startDate |classcolor : classInfo?.endDate }}"> {{classInfo?.startDate |classstatus : classInfo?.endDate }}</span>
                        </div>
                    </td>
                    <td class="edit"  *ngIf="mode === 'view'">
                        <div  *ngIf="classInfo?.classStatus === 1">
                              <span></span>
                        </div>
                         <div  *ngIf="classInfo?.classStatus === 2" >
                            <span class="jp-timer"> <img src="assets/icons/confirmation-pending.svg" alt=""></span>
                           <span  class='jp-tooltip'><div class='jp-arrow'></div>Attendance Confirmation: <strong class="jp-pending">Pending</strong></span>
                        </div>
                        <div  *ngIf="classInfo?.classStatus === 3" >
                            <span class="jp-timer"> <img src="assets/icons/confirmation-overdue.svg" alt=""></span>
                          <span  class='jp-tooltip'><div class='jp-arrow'></div>Attendance Confirmation: <strong class="jp-overdue">Overdue</strong></span>
                        </div>
                        <div *ngIf="classInfo?.classStatus === 4" >
                           <span  class="jp-timer"> <img src="assets/icons/confirmation-confirmed.svg" alt=""></span>
                            <span  class='jp-tooltip'><div class='jp-arrow'></div>Attendance Confirmation:<strong class="jp-confirmed">Confirmed</strong></span>
                        </div>
                    </td>
                    <td class="edit">
                        <div>
                            <div>
                                <span>{{classInfo?.checkInCode}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="edit">
                        <div>
                            <span (click)="openDialog('classroster',classInfo.id,classInfo.startDate,classInfo?.classSetting?.facilitatorAttendance)">{{classInfo.title}}</span>
                        </div>
                    </td>
                    <td class="edit">
                        <div>
                            <div>
                                <span>{{classInfo?.classId}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="edit">
                        <div>
                            <span><img src="assets/icons/date-table.svg" alt=""></span>
                            <div>
                                <span>{{classInfo?.startDate | dateFormat : 'mmddyyyy'}}</span>
                                <span>{{classInfo?.startDate | dateFormat : 'fulldayname'}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="edit">
                        <div>
                            <span><img src="assets/icons/time-table.svg" alt=""></span>
                            <div>
                                <span>{{classInfo?.startDate | timeFormat}}  - </span>
                                <span>{{classInfo?.endDate | timeFormat}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="edit">
                        <div>
                            <span><img src="assets/icons/location-table.svg" alt=""></span>
                            <span>{{classInfo?.location}}</span>
                        </div>
                    </td>
                    <td class="edit" *ngIf="mode === 'view'">
                        <div>
                            <div>
                                <span>Facilitators</span>
                                <span>Participants</span>
                            </div>
                            <div>
                                <span>{{classInfo?.facilitators}} </span>
                                <span>{{classInfo?.participants}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="last-view">
                        <div>
                            <span>
                                <input type="checkbox" id="{{classInfo?.classSetting?.id}}" class="example-margin" [checked]="classInfo?.classSetting?.facilitatorAttendance" value="{{classInfo?.id}}" name="facilitatorAttendance" (change)="toggleAttendance(classInfo, i)"/>
                            </span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ng-template #displayErrorMessage>
        <p class="background-white m-t-xs">
            <span class="m-l-lg">
                <error-message [errorMessage]="errorMessage"></error-message>
            </span>
        </p>
    </ng-template>
</div>
<ng-template #loading>
    <div class="loading" *ngIf="!showLoading">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>

<div *ngIf="showLoading">
    <div class="loading">
        <mat-spinner></mat-spinner>
    </div>
</div>