import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { RouterEvent, Router, NavigationEnd } from '@angular/router';
import { Auth } from 'aws-amplify';
import { filter } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { IUser } from 'src/app/models/IUser';
import { UserState } from 'src/app/store/user.state';
import { NavMenuService } from 'src/app/services/nav-menu/nav-menu.service';

@Component({
    selector: 'admin',
    templateUrl: './admin.component.html',
    styles: []
})

export class AdminComponent implements OnInit, OnDestroy {
    @Select(UserState.user) user$: Observable<IUser>;
    private userSubscription: Subscription;
    isUserShow = false;
    isDisabled: string;
    currDate = new Date();
    private roles: Array<string> = [];
    isActive = label => (label == 'reporting') ? this.router.url.includes('reports') : this.router.url.includes(label);
    isFacilitator: boolean;
    isAdmin: boolean;
    event$;
    displayAppeVersion: boolean = false;

    constructor(private auth: AuthenticationService, private router: Router, private navMenuService: NavMenuService,
        private store: Store
    ) {
        this.assignRole();
        this.isDisabled = (this.router.url === '/admin/programs-external' ? 'facilitator' : 'admin');
    }

    disabledRoleNav() {
        this.event$ = this.router.events.pipe(
            filter((e: any): e is RouterEvent => e instanceof NavigationEnd))
            .subscribe((evt: NavigationEnd) => {
                this.isDisabled = (evt.url.startsWith('/admin/programs-external') ? 'facilitator' : 'admin');
                this.navMenuService.isFacilitatorView(this.isDisabled === 'facilitator');
            });
    }

    ngOnInit() {
        this.userSubscription = this.store?.select(UserState.user)
            .subscribe(
                (user) => {
                    this.displayAppeVersion = user?.operator;
                }); // Get operator state.
        this.isFacilitator = this.auth.getUserRoles().includes('facilitator') || this.auth.getUserRoles().includes('admin');
        this.isAdmin = this.auth.getUserRoles().includes('admin');
        this.disabledRoleNav();
        this.navMenuService.disableDropdownMenu$.subscribe((response: string) => {
            this.isDisabled = response;
        });
    }

    toggleUserRole() {
        this.isUserShow = !this.isUserShow;
    }

    private assignRole(): void {
        Auth.currentAuthenticatedUser().then(
            user => {
                if (user.signInUserSession) {
                    this.roles = user.signInUserSession.idToken.payload["cognito:groups"];
                } else {
                    this.roles = [localStorage.getItem("role")];
                }
            }
        )
            .catch((error) => {
                console.log('Something went wrong while signing in.', JSON.stringify(error));
            });
    }

    private routAdmin(value: string): void {
        if (value.includes('admin')) {
            this.router.navigate(['admin']);
            this.navMenuService.isFacilitatorView(false);
            this.isUserShow = false;
            this.isDisabled = 'admin';
        }
        else if (value.includes('journey')) {
            this.router.navigate(['journey/home']);
            this.isUserShow = false;
            this.navMenuService.isFacilitatorView(false);
        }
        else if (value.includes('facilitator')) {
            this.navMenuService.isFacilitatorView(true);
            this.router.navigate(['admin/programs-external']);
            this.isUserShow = false;
            this.isDisabled = 'facilitator';
        }
        else if (value.includes('signOut')) {
            localStorage.clear();
            sessionStorage.clear();
            this.auth.signOut();
            this.router.navigate(['logout']);
        }
    }

    private showHideAdmin(): boolean {
        let result = true;
        if (this.roles.includes('admin') && this.router.url.includes('admin')) {
            result = false;
        }
        return result;
    }

    private showHideFacilitator(): boolean {
        let result = false;   //In case of showing facilitator in future, change result=true
        if (this.roles.includes('admin') && this.router.url.includes('facilitator')) {
            result = false;
        }
        return result;
    }

    private showHideParticipant(): boolean {
        let result = true;
        if (this.roles.includes('admin') && this.router.url.includes('journey')) {
            result = false;
        }
        return result;
    }

    ngOnDestroy() {
        this.event$?.unsubscribe();
        this.userSubscription.unsubscribe();
    }
}
