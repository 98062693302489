import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IParticipant } from '../../../models/IParticipant';
import { API } from 'aws-amplify';
import { IProgram, IClass } from '../../../models/IProgram';


@Injectable({
    providedIn: 'root'
})
export class ParticipantService {
    getList(): Observable<IParticipant> {
        return from(API.get('portal', 'persons', {})).pipe(
            map((data: IParticipant) => {
                return data as IParticipant;
            })
        );
    }

    getExternalFacilitatorCourses(programId): Observable<IClass[]> {
        return from(API.get('portal', 'persons/facilitator/' + programId + '/courses', {})).pipe(
            map(data => {
                data = data.map(c => {
                    c.location = c.location || 'TBD';
                    return c;
                });
                return data as IClass[];
            })
        );
    }

    getExternalFacilitatorPrograms(): Observable<IProgram | any> {
        return from(API.get('portal', 'persons/facilitator/programs', {})).pipe(
            catchError(() => of([])),
            map(data => {
                return data as IProgram[];
            })
        );
    }
}
