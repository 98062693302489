import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { IItineraryProgram } from "src/app/models/IProgram";
import { JourneyActions } from "./journey.actions";
import { IProgram } from 'src/app/models/IProgram';

export interface JourneyStateModel {
    itineraryProgram: IItineraryProgram;
    currentItineraryPrograms: IProgram[];
    upcomingItineraryPrograms: IProgram[];
    selectedProgram: {
        program: IProgram,
        travel: any,
        room: any
    };
}

@State<JourneyStateModel>({
    name: 'journey',
    defaults: {
        itineraryProgram: {
            programId: undefined,
            state: ""
        },
        currentItineraryPrograms:  [],
        upcomingItineraryPrograms: [],
        selectedProgram: {
            program: <IProgram>{},
            travel: {},
            room: {}
        }
    }
})
@Injectable()
export class JourneyState {

    @Selector() 
    static itineraryProgram(state: JourneyStateModel) {
      return state.itineraryProgram;
    }

    @Selector() 
    static itineraryProgramId(state: JourneyStateModel) {
      return state.itineraryProgram.programId;
    }

    @Selector() 
    static getSelectedProgram(state: JourneyStateModel): IProgram {
      return state.selectedProgram.program;
    }

    @Selector() 
    static getCurrentPrograms(state: JourneyStateModel): IProgram[] {
      return state.currentItineraryPrograms;
    }

    @Selector() 
    static getUpcomingPrograms(state: JourneyStateModel): IProgram[] {
      return state.upcomingItineraryPrograms;
    }


    @Action(JourneyActions.SetItineraryProgram)
    setItineraryProgram(ctx: StateContext<JourneyStateModel>, action: JourneyActions.SetItineraryProgram) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            itineraryProgram: action.itineraryProgram
        });
    }

    @Action(JourneyActions.SetCurrentItineraryPrograms)
    setCurrrentItineraryProgram(ctx: StateContext<JourneyStateModel>, action: JourneyActions.SetCurrentItineraryPrograms) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            currentItineraryPrograms: action.currentItineraryProgram
        });
    }

    @Action(JourneyActions.SetUpcomingItineraryPrograms)
    setUpcomingItineraryProgram(ctx: StateContext<JourneyStateModel>, action: JourneyActions.SetUpcomingItineraryPrograms) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            upcomingItineraryPrograms: action.upcomingItineraryProgram
        });
    }
    
    @Action(JourneyActions.SetSelecedProgram)
    setSelectedProgram(ctx: StateContext<JourneyStateModel>, action: JourneyActions.SetSelecedProgram) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            selectedProgram: {
                program: action.selectedProgram,
                room: state.selectedProgram.room,
                travel: state.selectedProgram.travel
            }
        });
    }
        
    @Action(JourneyActions.SetSelecedProgram)
    setSelectedProgramTravelInfo(ctx: StateContext<JourneyStateModel>, action: JourneyActions.SetSelecedProgramTravelInfo) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            selectedProgram: {
                program: state.selectedProgram.program,
                room: state.selectedProgram.room,
                travel:action.travelInfo
            }
        });
    }
            
    @Action(JourneyActions.SetSelecedProgram)
    setSelectedProgramRoomInfo(ctx: StateContext<JourneyStateModel>, action: JourneyActions.SetSelecedProgramRoomInfo) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            selectedProgram: {
                program: state.selectedProgram.program,
                room: action.roomInfo,
                travel: state.selectedProgram.travel
            }
        });
    }
}