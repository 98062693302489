import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ClassRosterService } from 'src/app/services/aws/class/class-roster.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { ElementRef } from '@angular/core';

@Component({
    selector: 'save-bar',
    templateUrl: './save-bar.component.html',
    styles: [],
})
export class SaveBarComponent {
    @Input() isAdded: boolean;
    @Input() isSuccess: boolean;
    @Input() isShowSave: boolean;
    @Input() showReason: boolean;
    @Input() index: number;
    @Input() data: any;
    @Output() toggle = new EventEmitter();
    @ViewChild('menu') menu: MatMenuTrigger;
    addReasonItems = [
        { commentReasonId: 0, label: 'Client Responsibilities', selected: false },
        { commentReasonId: 1, label: 'Personal/Sickness', selected: false },
        { commentReasonId: 2, label: 'Technology Issue', selected: false },
        { commentReasonId: 3, label: 'Other', selected: false }
    ];

    @Input() updatedDetailCache: any = [];
    addnewReasonItems: string;
    reasonItems: string;
    @Input() newAddedDetails: any;
    @Input() isDeleted: boolean;
    @Input() listText: string;
    @Input() type: string;
    @Output() toggleCancelWidget = new EventEmitter();
    @Output() toggleSave = new EventEmitter();
    @Output() updateIndex: EventEmitter<number> = new EventEmitter();
    @Output() updateComment: EventEmitter<object> = new EventEmitter();
    checkboxes: any;
    isCommentMenu: boolean = false;
    otherCommentText: string;
    isOther: boolean = true;
    allCheckboxes: any;

    constructor(private roster: ClassRosterService, private _eref: ElementRef) {
    }

    insertReason(element, id, e) {
        this.addReasonItems.forEach(item => item.selected = false);
        this.addReasonItems[element.commentReasonId].selected = e.source.checked;
        if (element.label == 'Other') {
            if (e.source.checked == true)
                this.isOther = false;
            else this.isOther = true;
            if (id == 0) {
                this.addnewReasonItems = this.otherCommentText;
                this.newAddedDetails.comments = this.otherCommentText;
            } else {
                this.reasonItems = this.otherCommentText;
            }
        } else {
            this.isOther = true;
            if (id == 0) {
                this.addnewReasonItems = element.label;
                this.newAddedDetails.comments = element.label;

            } else {
                this.reasonItems = element.label;
            }
            this.isCommentMenu = false;
        }
        if (element.label != 'Other') {
            let miss = this.roster.update();
            miss.totalMissed[0].comments = element.label;
            this.roster.update(miss);
            this.updateComment.emit({comments: element.label, type: this.type});
        }
        // e.stopPropagation();
    }

    removeReason(type) {
        this.addReasonItems.forEach(item => item.selected = false);
        this.otherCommentText = undefined;
        if (type == 'add') {
            this.addnewReasonItems = undefined;
        } else if (type == 'update') {
            this.reasonItems = undefined;
        }
        this.checkUncheckAll();
        let miss = this.roster.update();
        miss.totalMissed[0].comments = "";
        this.roster.update(miss);
        this.updateComment.emit({comments: undefined, type: this.type});
    }
    
    getTimeDiff(startTime: any, endTime: any): number {
        if (startTime != undefined && endTime != undefined) {
            var sTime = new Date('1901/01/01 ' + startTime);
            var eTime = new Date('1901/01/01 ' + endTime);
            var difference = eTime.getTime() - sTime.getTime(); // This will give difference in milliseconds
            var resultInMinutes = Math.round(difference / 60000);
            return Math.floor(resultInMinutes);
        } else return 0;
    }

    async save() {
        const payload: any = {};
        let savedToBakend = false;
        if (this.data?.facilitation) {
            payload.facilitationTime = this.data?.facilitation?.facilitationTime;
            payload.participantId =  this.data?.facilitation?.participantId;
        }
        if (this.data?.participation) {
            payload.participationTime = this.data?.participation?.participationTime;
            payload.participantId =  this.data?.participation?.participantId;
        }
        if (payload?.participantId) {
            savedToBakend = true;
            this.roster.target = 'facilitation';
            this.roster.update(payload);
            try {
            await this.roster.save().toPromise();
            } catch(error) {
                this.isShowSave = true;
            }
        }
        
        if (this.data?.miss) {
            savedToBakend = true;
            try {
                this.roster.target = 'facilitator';
                this.roster.update(this.data?.miss);
                await this.roster.save().toPromise();
            } catch(error) {
                this.isSuccess = false;
            }
        }
        if (!savedToBakend) {
            try {
                await this.roster.save().toPromise();
            } catch(error) {
                this.isSuccess = false;
            }
        }

        this.isShowSave = false;
        this.isSuccess = true;
        this.clear();
    }

    clear() {
        setTimeout(() => {
            this.isSuccess = false;
            this.toggle.emit();
            this.toggleSave.emit();
        }, 2000);
    }

    checkUncheckAll() {
        this.checkboxes = document.getElementsByName('reasonCheckbox');
        for (var i = 0, n = this.checkboxes.length; i < n; i++) {
            this.checkboxes[i].checked = false;
        }
    }

    cancelTimeMissed() {
        this.toggleCancelWidget.emit();
    }

    shoCommentsMenu() {
        this.isCommentMenu = !this.isCommentMenu;
    }

    mouseleave(type) {
        if (this.isCommentMenu) {
            if (type == 'addTime') {
                this.addnewReasonItems = this.otherCommentText;
            } else {
                this.reasonItems = this.otherCommentText;
            }

            this.isCommentMenu = false;
            let miss = this.roster.update();
            miss.totalMissed[0].comments = this.otherCommentText;
            this.updateComment.emit({comments: this.otherCommentText, type: this.type});
            this.roster.update(miss);
        }
    }
}
