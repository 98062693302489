import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { IDropdown } from "./dropdown.interface";


@Component({
    selector: 'dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DropdownComponent {

    @Input() public labelCssClass: string = 'font-size-16';

    @Input() public lablel: string;

    @Input() public value: any;

    @Input() public disable: boolean;

    @Input() public dropdownValues: IDropdown[];

    @Output() public valueChage: EventEmitter<any> = new EventEmitter<any>();

    public eventChange($event): void {
        this.valueChage.emit($event);
    }
}