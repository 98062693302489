<div breadcrumbs [option]='breadcrumbOptions'></div>
<div report-arrival>
    <div flight-report-header>
        <h3>Flight Arrival Reports</h3>
        <button class="btn-primary" (click)="download()">Export to Excel <img src="assets/icons/excel.svg" alt="" ml10></button>

    </div>
    <!-- Participant Attendance Start -->
    <div class="panel">
        <div class="panel-header">
            <div class="panel-title">
                <!-- <span><i class="material-icons">flight</i></span> -->
                <span><img src="assets/icons/travel-title.svg" alt=""></span>
                <p>All Arrival Flights</p>
            </div>


            <!--  <table>
                <tr *ngFor="let report of arrivalReport">
                    <td>{{ report.name }}</td>
                    <td>{{ report.status }}</td>
                </tr>
            </table> -->
            <div class="panel-search">
                <!-- <form search>
                    <input type="text" name="search" value="" placeholder="Search participants by name or email">
                </form>
                <span><i class="material-icons"> search</i></span>
                <span><i class="material-icons"> filter_list </i></span> -->
            </div>
        </div>

        <div class="panel-body" *ngIf="reports; else loading ">
            <table class="table participant-table"  *ngIf="reports?.length; else displayErrorMessage">
                <thead class="sticky">
                    <th (click)="sort('lastName')">
                        <span>Name</span>
                        <!-- <i class="material-icons" [ngClass]="{'active':isNameAsc}"> arrow_drop_down</i> -->
                        <img [ngClass]="{'active':isNameAsc}" src="assets/icons/sort-by-down.svg" alt="">
                    </th>
                    <th (click)="sort('mobileNumber')">
                        <span>Mobile No.</span>
                        <!-- <i class="material-icons" [ngClass]="{'active':isMobileAsc}">arrow_drop_down</i> -->
                        <img [ngClass]="{'active':isMobileAsc}" src="assets/icons/sort-by-down.svg" alt="">
                    </th>
                    <th (click)="sort('status')">
                        <span>Status</span>
                        <!-- <i class="material-icons" [ngClass]="{'active':isStatusAsc}">  arrow_drop_down</i> -->
                        <img [ngClass]="{'active':isStatusAsc}" src="assets/icons/sort-by-down.svg" alt="">
                    </th>
                    <th (click)="sort('airline')">
                        <span>Airline</span>
                        <!-- <i class="material-icons" [ngClass]="{'active':isAirLineAsc}"> arrow_drop_down</i> -->
                        <img [ngClass]="{'active':isAirLineAsc}" src="assets/icons/sort-by-down.svg" alt="">
                    </th>
                    <th (click)="sort('flightNumber')">
                        <span>Flight No.</span>
                        <!-- <i class="material-icons" [ngClass]="{'active':isFlightNoAsc}">arrow_drop_down</i> -->
                        <img [ngClass]="{'active':isFlightNoAsc}" src="assets/icons/sort-by-down.svg" alt="">
                    </th>
                    <th (click)="sort('arrivalDate')">
                        <span>Arriv. Date</span>
                        <!-- <i class="material-icons" [ngClass]="{'active':isArriveDateAsc}">arrow_drop_down</i> -->
                        <img [ngClass]="{'active':isArriveDateAsc}" src="assets/icons/sort-by-down.svg" alt="">
                    </th>
                    <th (click)="sort('arrivalTime')">
                        <span>ARRIV. Time</span>
                        <!-- <i class="material-icons" [ngClass]="{'active':isArriveTimeAsc}">arrow_drop_down</i> -->
                        <img [ngClass]="{'active':isArriveTimeAsc}" src="assets/icons/sort-by-down.svg" alt="">
                    </th>
                </thead>
                <tbody>
                    <tr *ngFor="let item of reports | orderBy: {property: column, direction: direction}">
                        <td>
                            <div class="user-profile">
                                <!-- <img src="assets/icon/deede-33x33.png" alt=""> -->
                                <div>
                                    <span name>{{ item?.lastName }} , {{item?.firstName}}</span>
                                    <span email>{{ item?.email }}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>{{ item?.mobileNumber }}</span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>{{ item?.status }}</span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>{{ item?.airline }}</span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>{{ item?.flightNumber }}</span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>{{ item?.arrivalDate | dateFormat : 'mmddyy' }}</span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>{{ item?.arrivalTime }}</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-template #displayErrorMessage>
            <p class="background-white m-t-xs">
                <span class="m-l-lg">
                    <error-message [errorMessage]="errorMessage"></error-message>
                </span>
            </p>
        </ng-template>
    </div>
    <!-- Participant Attendance End -->
</div>
<ng-template #loading>
    <div class="loading" *ngIf="!showLoading">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>