<div >
    <span class="dialog-close" (click)="dialogclose()">
        <img src="assets/icons/close-drawer.svg" alt="" />
    </span>
    <div class-roster-header  *ngIf="sessionMetaData; else loading">
        <div breadcrumb>
            <span>
                <img src="assets/icons/programs-drawer-title.svg" alt="">
            </span>
            <p>{{programInfo?.name  || 'N/A'}} • {{ programInfo?.startDate | dateFormat :'mm/dd/yy'}} - {{programInfo ?.endDate | dateFormat :'mm/dd/yy'}}</p>
        </div>
        <div title>
            <h1>{{ sessionMetaData?.title }} </h1>
            <span class="{{sessionMetaData?.startDate |classcolor : sessionMetaData?.endDate }}">{{sessionMetaData?.startDate |classstatus : sessionMetaData?.endDate}}</span>
        </div>
        <div items>
            <div content>
                <p>SESSION ID
                    <span dblock>{{ sessionMetaData?.sessionId }}</span>
                </p>
            </div>
            <div date >
                <div>
                    <div>
                        <span>{{sessionMetaData?.startDate | dateFormat : 'mmddyyyy'}}</span>
                        <span>{{sessionMetaData?.startDate | dateFormat :'fulldayname'}}</span>
                    </div>
                </div>
            </div>
            <div time>
                <div>
                    <span>
                        <img src="assets/icons/time-table.svg" alt="">
                    </span>
                    <div>
                        <div>
                            <span>{{sessionMetaData?.startDate |dateFormat : 'coursetime'}} -</span>
                            <span>{{sessionMetaData?.endDate |dateFormat : 'coursetime'}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div place>
                <span>
                    <i>
                        <img src="assets/icons/location-table.svg" alt="">
                    </i>
                    {{sessionMetaData?.location}}</span>
            </div>
        </div>
    </div>
    <div cr-popup-body>

<div class-roster *ngIf="participantsList">
    <div class-roster-body>
        <div class-roster-title>
            <h1>Session Attendance</h1>
            <div (click)="downloadReport()" class="export-button">Export to Excel
                <img src="assets/icons/excel.svg" alt="" ml10>
            </div>
        </div>
    </div>
</div>

<div class="panel" *ngIf="hideSpinner;  else loading">
    <ng-container  *ngIf="!fetchingParticipantsFailed; else showErrorMessage">
        <div class="panel-header">
            <div class="panel-title">
                <span>
                    <img src="assets/icons/expand-open-down.svg" alt="">
                </span>
                <p>Participants ({{ participantsList?.length }})</p>
            </div>

        </div>
        <div class="panel-body">
            <table class="table participants-table">
                <thead>
                    <th (click)="sort('fullName')" class="cursor-pointer">
                        <span>Name</span>
                        <img src="assets/icons/sort-by-down.svg"  [ngClass]="{'active': sortProp === 'fullName' && sortDirection === 1}"  alt="">
                    </th>
                    <th (click)="sort('email')"  class="cursor-pointer">
                        <span>Email</span>
                        <img src="assets/icons/sort-by-down.svg"   [ngClass]="{'active': sortProp === 'email' && sortDirection === 1 }"  alt="">
                    </th>

                </thead>
                <tbody>
                    <ng-container  *ngFor='let partcipant of participantsList | orderBy: {property: sortProp, direction: sortDirection }; let i = index' >
                        <tr>
                            <td>
                                <div>
                                    <span>{{ partcipant?.fullName }}</span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span>{{ partcipant?.email }}</span>
                                </div>
                            </td>
                        </tr>

                    </ng-container>
                </tbody>
            </table>
        </div>
    </ng-container>
    <ng-template #showErrorMessage>
        <p class="background-white m-t-xs">
            <span class="m-l-lg">
                <error-message></error-message>
            </span>
        </p>
    </ng-template>
</div>

    </div>
</div>
<ng-template #loading>
    <div class="loading">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
