export const environment = {
    production: false,
    aws: {
        region: "us-east-1",
        identityPoolId: "us-east-1:880ec778-d9da-404e-bb30-61911e7cb39e",
        samlProviderId: "arn:aws:iam::872950281716:saml-provider/LakeNonaSAML",
        userPoolId: "us-east-1_rkfFi94Q6",
        userPoolWebClientId: "67lrd13b37sacfi7d3ueidod23",
        apiEndpoint: "https://lakehouseapiqa.us.kpmg.com/journey/",
        oauth: {
            domain: 'lakehouseqa.auth.us-east-1.amazoncognito.com',
            redirectUrl:'https://lakehouseportalqa.us.kpmg.com',
            redirectLogoutUrl: 'https://lakehouseportalqa.us.kpmg.com/logout'
        }
    },
    idp: "https://lakenonaqa.us.kworld.kpmg.com",
    cvent: "https://TEST-STAGE-events-meetings.kpmg.com/"
};
