import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProgramDropdownService } from '../../../services/dropdown/dropdown.service';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { ProgramService } from '../../../services/aws/program/program.service';
import { ParticipantService } from '../../../services/aws/participant/participant.service';
import { IProgram } from '../../../models/IProgram';
import { ActivatedRoute, Router } from '@angular/router';
import { mergeMap, take, map } from 'rxjs/operators';
import { ProgramActions } from './store/program.action';
import { ProgramState } from './store/program.state';
import { Store } from '@ngxs/store';
import { IErrorMessage } from 'src/app/widgets/error-message/error-message.interface';
import { API_USER_DEFINED_MESSAGE } from 'src/app/widgets/error-message/error-message.const';
import { FILTER_TYPE, IFilterResponse } from 'src/app/shared/filter/filter.interface';
import { NavMenuService } from 'src/app/services/nav-menu/nav-menu.service';

@Component({
    selector: 'admin-programs',
    templateUrl: './programs.component.html',
    styleUrls: ['./programs.component.scss']
})
export class ProgramsComponent implements OnInit, OnDestroy {
    email: string = '';
    breadcrumbdropdowndata: any;
    tablemode: string;
    headmode: string;
    popover: boolean = false;
    isChecked: boolean = true;
    programs: IProgram[];
    public errorMessage: IErrorMessage = {};
    programId: any;
    isCourseSelected: false;
    isCventSelected: false;
    isDisplay: boolean = true;
    isSaved: boolean = false;
    isDesc: boolean = true;
    column: string = 'name';
    direction: number;
    isNameAsc: boolean = false;
    isDateAsc: boolean = false;
    programTypeAsc: boolean = false;
    public clearPrograms: boolean = false;
    role: string;
    searchTerm: string;
    breadcrumbOptions;
    filterInput: any;
    courseUrlErrors: string[] = [];
    cventUrlErrors: string[] = [];
    isFilterRes: boolean = false;
    public showFilter: boolean = false;
    private _programs: IProgram[] = [];
    filterRes: string = API_USER_DEFINED_MESSAGE.EMPTY_FILTER_RESPONSE;

    constructor(private auth: AuthenticationService, private participantService: ParticipantService, private programservice: ProgramService, private dropdownService: ProgramDropdownService, private route: ActivatedRoute, private router: Router,
        private store: Store, private navMenuService: NavMenuService) {
        this.tablemode = 'view';
        this.headmode = 'open';
        this.programId = this.route.params
            .pipe(take(1))
            .subscribe(params => params["Id"]);
    }


    ngOnInit() {
        this.filterInput = {};
        this.role = (this.router.url === '/admin/programs-external' ? 'facilitator' : 'admin');
        this.breadcrumbOptions = {
            breadcrumb: [
                {
                    label: 'Dashboard',
                    itemclass: 'Breadcrumb-item',
                    linkclass: 'Breadcrumb-link',
                    link: this.role === "admin" ? '/admin/dashboard' : '#'
                },
                {
                    label: 'Programs',
                    itemclass: 'Breadcrumb-item',
                    linkclass: 'Breadcrumb-link',
                    link: this.role === "admin" ? '/admin/programs' : '/admin/programs-external'
                }
            ],
            dropdowns: []
        };
        if (this.role === "admin") {
            this.getList();
        }
        else {
            this.navMenuService.isFacilitatorView(true);
            this.auth.getUserEmail().pipe(take(1)).subscribe(e => {
                this.email = e.toString();
                this.getList();
            });
        }

    }

    public resetToDefault(): void {
        this.isFilterRes = false;
        const tempFilter = this.getDefaultFilterDate();
        this.programs = this.programservice.filterProgramsBySelectedDate(this.programs, this.getDeepCopyOfPrograms(), tempFilter);
    }

    private getDefaultFilterDate(): any {
        const myFutureDate = new Date();
        const myPastDate = new Date();
        myPastDate.setDate(myPastDate.getDate() - 180);
        myFutureDate.setDate(myFutureDate.getDate() + 180);
        return {
            filterType: FILTER_TYPE.FROM,
            startDate: myPastDate,
            endDate: myFutureDate
        };

    }

    private getDeepCopyOfPrograms(): IProgram[] {
        return JSON.parse(JSON.stringify(this._programs));
    }

    private getList() {
        this.errorMessage = {};
        if (this.role === "admin") {
            this.store.dispatch(new ProgramActions.GetPrograms())
                .pipe(mergeMap(() => this.store.select(ProgramState.getPrograms)))
                .subscribe((response: IProgram[]) => {
                    this.errorMessage.text = response?.length ? '' : API_USER_DEFINED_MESSAGE.EMPTY_RESPONSE;
                    this._programs = JSON.parse(JSON.stringify(response));
                    this.resetToDefault();
                },
                    () => {
                        this.programs = [];
                        this.errorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
                    }
                );
            // this.sort('name');
        }
        else {
            this.participantService.getExternalFacilitatorPrograms().pipe(map(data => data))
                .subscribe((response: IProgram[]) => {
                    let sortResponse = this.programservice?.programSort(response);
                    this.errorMessage.text = response?.length ? '' : API_USER_DEFINED_MESSAGE.EMPTY_RESPONSE;
                    this.programs = sortResponse;
                    this._programs = JSON.parse(JSON.stringify(sortResponse));
                },
                    () => {
                        this.programs = [];
                        this.errorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
                    });
        }
    }

    toggleheader() {
        if (this.tablemode == 'view') this.tablemode = 'edit'; else this.tablemode = 'view';
        if (this.headmode == 'open') this.headmode = 'close'; else this.headmode = 'open';
    }

    cancel(value) {
        this.popover = value;
        if (value == false) {
            const _filterInput = this.filterInput?.filterType ? this.filterInput : this.getDefaultFilterDate();
            this.programs = this.role === "admin" ? this.programservice.filterProgramsBySelectedDate([], this.getDeepCopyOfPrograms(), _filterInput) : this.getDeepCopyOfPrograms();
            this.courseUrlErrors = [];
            this.cventUrlErrors = [];
            this.isDisplay = true;
            this.programservice.cancelUpdate('program');
        }
    }

    keepediting() {
        this.popover = false;
    }

    valuechange($event, i) {
        const program = {
            id: $event.srcElement.id,
            courseRegistrationUrl: ($event.srcElement.getAttribute("data-urlName") == 'courseUrl') ? $event.srcElement.value : $event.srcElement.getAttribute("data-ceventRegistrationUrl"),
            ceventRegistrationUrl: ($event.srcElement.getAttribute("data-urlName") == 'cventUrl') ? $event.srcElement.value : $event.srcElement.getAttribute("data-courseRegistrationUrl"),
            urlTypeName: $event.srcElement.getAttribute("data-urlName")
        } as IProgram;
        this.programs[i].programSetting = this.programs?.[i]?.programSetting ? this.programs[i].programSetting : {};
        if (program.courseRegistrationUrl) {
            this.programs[i].programSetting.courseRegistrationUrl = program.courseRegistrationUrl;
        }
        if (program.ceventRegistrationUrl) {
            this.programs[i].programSetting.ceventRegistrationUrl = program.ceventRegistrationUrl;
        }
        this.programservice.updateProgram(program);
        this.isDisplay = false;
    }

    validateUrl(index: number, type: string) {
        const courseInput = this.programs[index]?.programSetting?.courseRegistrationUrl?.trim();
        const cventInput = this.programs[index]?.programSetting?.ceventRegistrationUrl?.trim();

        const urlPattern = type === "course" ? this.isValidURL(courseInput) : this.isValidURL(cventInput);
        const value = type === "course" ? courseInput : cventInput;

        if (value && !urlPattern) {
            this[`${type}UrlErrors`][index] = "Enter a valid URL (e.g., http://example.com).";
        } else {
            this[`${type}UrlErrors`][index] = "";
        }
    }

    isValidURL(url: string) {
        try {
            new URL(url)
        } catch (err) {
            return false;
        }
        const pattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
            '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+!#;=,:]*)*' + // validate port and path
            '(\\?[;&a-zA_Z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-zA-Z\\d_]*)?$', 'i'); // validate fragment locator

        return pattern.test(url);
    }

    isFormInvalid() {
        return this.courseUrlErrors.some((error, index) => error !== "") || this.cventUrlErrors.some((error, index) => error !== "");
    }

    save() {
        if (this.programservice.isDirty('program'))
            this.programservice.saveUpdate()
                .pipe(take(1))
                .subscribe(() => {
                    this._programs = JSON.parse(JSON.stringify(this.programs));
                    this.popOverMessage();
                    this.clearPrograms = true;
                });
        this.programservice.cancelUpdate('program');
        //Below line is temprary will remove once implement subject
        //this.getList();
    }

    private popOverMessage() {
        this.isDisplay = true;
        this.isSaved = true;
        this.toggleheader();
        setTimeout(() => {
            this.isSaved = false,
                this.toggleheader();
        }, 2000);
    }

    sort(property) {
        this.isDesc = !this.isDesc;
        this.column = property;
        this.direction = this.isDesc ? 1 : -1;
        switch (property) {
            case 'name':
                this.isNameAsc = !this.isNameAsc;
                break;
            case 'startDate':
                this.isDateAsc = !this.isDateAsc;
                break;
            case 'programType':
                this.programTypeAsc = !this.programTypeAsc;
                break;
            default:
        }
    }

    public ngOnDestroy(): void {
        if (this.clearPrograms) {
            this.store.dispatch(new ProgramActions.ClearPrograms());
        }
    }

    public appliedDateFilter(appliedFilter: IFilterResponse): void {
        this.filterInput = appliedFilter;
        this.programs = this.programservice.filterProgramsBySelectedDate(this.programs, this.getDeepCopyOfPrograms(), this.filterInput);
        this.isFilterRes = this.programs.length ? false : true;
    }
}
