import { Component, Input } from '@angular/core';

@Component({
    selector: '[breadcrumbs]',
    templateUrl: './breadcrumbs.component.html',
    styles: []
})
export class BreadCrumbsComponent {
    selected: string;
    hash = '#';

    @Input() option: any = {
        dropdowns: [
       ],
       breadcrumb: [],
       toggle: {
       }
   };
    constructor() {

     }

    itemchange($event) {
        this.selected = $event.value;
    }
}
