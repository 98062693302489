<div pre-register *ngIf="preRegister">
    <div flight-head>
        <span> <img src="assets/images/travel-title.svg"></span>
        <span>My Travel</span>
        <button onclick="window.open('https://concur.us.kworld.kpmg.com','_blank')">Go to Concur</button>
    </div>
    <div>
        <span><p> <b>Note:</b> Changes may take some time to reflect on your timeline.</p></span>
    </div>
    <div>
        <div register-flight>
            <img src="assets/images/flight-pre.svg">
            <span>Arrange your travel</span>
            <span>Click <a href="https://concur.us.kworld.kpmg.com" target="_blank">“Go to Concur”</a> to begin.</span>
            <span>All travel to Lakehouse events should be booked through <b>Concur.</b></span>
            <span><b>Important:</b> When prompted, be sure to <b>select the name of your program</b> from</span>
            <span>the list of Meeting options. (There may be an option for KPMG travelers and an </span>
            <span>option for non-KPMG travelers: Select the option that applies to you.)</span>
            <section>
                <span><b>Quick Links:</b></span> <!--<a>Travel charge codes and other program information</a><span>&#8226;</span>-->&nbsp;<a href="https://kpmgus.sharepoint.com/sites/US-OI-BUS-Travel/SitePages/Vendor-Benefits-Promos.aspx#lakehouse" target="_blank"><span>Travel Benefits for Lakehouse</span></a>
            </section>
        </div>
    </div>
</div>

<div registered *ngIf="displayFlightInformation">
    <div flight-head>
        <span> <img src="assets/images/travel-title.svg"></span>
        <span>My Travel </span>
        <div>
            <button onclick="window.open('https://concur.us.kworld.kpmg.com','_blank')">Manage Flight Reservation</button>
        </div>
    </div>
    <div note>
        <span></span>
        <span>Click <a href="https://concur.us.kworld.kpmg.com" target="_blank">“Manage Flight Reservation”</a> to update or cancel. <b>Note:</b> Changes may take some time to reflect on your timeline.</span>
    </div>
    <div tips>
        <div><img src="assets/images/information.svg" alt=""><span> Travel Tips & Support</span><img src="assets/images/expand-open-down.svg" alt="" [class.active]="!showTravelTips" (click)="showTravelTips=!showTravelTips"></div>
        <div *ngIf="!showTravelTips">
            <p>Please give yourself enough time before and after your scheduled events to travel to the airport, go through security, and board your flight.</p>
            <p>After exiting aircraft, please proceed to the main terminal using the tram and then follow signs to Level 2/Baggage Claim associated with your airline.</p>
            <p>Please check in with the Mears/Lakehouse representative who will provide additional instruction or contact <b>Operator Support</b> at 1-407-254-0692.</p>
            <p>For Air Travel Support, please contact <b>AmexGBT Lakehouse Support</b> at 1-669-272-1087.</p>
        </div>
    </div>
    <div flight-container>
        <div left *ngIf="flightOneLegs">
            <h3>{{flightOneLegs.departureTime | dateFormat : 'ddd, ll'}}</h3>
            <h4>Departure / Arrival</h4>
            <div flight>
                <div>
                    <span><img src="assets/images/flight-departure.svg" alt=""></span>
                    <div>
                        <span>{{flightOneLegs.startFullNameAirport}}</span>
                        <span>{{flightOneLegs.startAirport}}</span>
                        <span>{{flightOneLegs.departureTime | dateFormat : 'coursetime'}} {{flightOneLegs.startTimeZone | timeZoneAbbreviations}}</span>
                    </div>
                </div>
                <div flight-detail>
                    <div>
                        <span></span>
                        <span><img src="assets/images/flight-x-large.svg" alt=""> </span>
                        <span></span>
                    </div>
                    <span>{{flightOneLegs.departureTime | duration : flightOneLegs.endTime}}</span>
                </div>
                <div>
                    <div>
                        <span>{{flightOneLegs.endFullNameAirport}}</span>
                        <span>{{flightOneLegs.endAirport}}</span>
                        <span>{{flightOneLegs.endTime | dateFormat : 'coursetime'}} {{flightOneLegs.endTimeZone | timeZoneAbbreviations}}</span>
                    </div>
                    <span><img src="assets/images/flight-arrival.svg" alt=""></span>
                </div>
            </div>
            <h4>Flight Details</h4>
            <div carrier>
                <div>Carrier</div>
                <div>{{flightOneLegs.carrier}}</div>
            </div>
            <div flight-ref>
                <!--<div>
                    <span>Carrier</span>
                    <span>{{legs[0].carrier}}</span>
                </div>-->
                <div>
                    <span>Flight No.</span>
                    <span>{{flightOneLegs.flightNo}}</span>
                </div>
                <div>
                    <span>Booking Reference Number</span>
                    <span>{{flightOneLegs?.bookingId}}&nbsp;</span>
                </div>
                <!-- <div>
                    <span>Airline Reference Number</span>
                    <span>{{legs[0]?.airlineReferenceNumber}}&nbsp;</span>
                </div> -->
                <div>
                    <span>E-Ticket Number</span>
                    <span>{{flightOneLegs?.ticketNumber}}&nbsp;</span>
                </div>
            </div>
        </div>
        <div right *ngIf="flightTwoLegs">
            <h3>{{flightTwoLegs.departureTime | dateFormat : 'ddd, ll'}}</h3>
            <h4>Departure / Arrival</h4>
            <div flight>
                <div>
                    <span><img src="assets/images/flight-departure.svg" alt=""></span>
                    <div>
                        <span>{{flightTwoLegs.startFullNameAirport}}</span>
                        <span>{{flightTwoLegs.startAirport}}</span>
                        <span>{{flightTwoLegs.departureTime | dateFormat : 'coursetime'}} {{flightTwoLegs.startTimeZone | timeZoneAbbreviations}}</span>
                    </div>
                </div>
                <div flight-detail>
                    <div>
                        <span></span>
                        <span><img src="assets/images/flight-x-large.svg" alt=""> </span>
                        <span></span>
                    </div>
                    <span>{{flightTwoLegs.departureTime | duration : flightTwoLegs.endTime}}</span>
                </div>
                <div>
                    <div>
                        <span>{{flightTwoLegs.endFullNameAirport}}</span>
                        <span>{{flightTwoLegs.endAirport}}</span>
                        <span>{{flightTwoLegs.endTime | dateFormat : 'coursetime'}} {{flightTwoLegs.endTimeZone | timeZoneAbbreviations}}</span>
                    </div>
                    <span><img src="assets/images/flight-arrival.svg" alt=""></span>
                </div>
            </div>
            <h4>Flight Details</h4>
            <div carrier>
                <div>Carrier</div>
                <div>{{flightTwoLegs.carrier}}</div>
            </div>
            <div flight-ref>
                <div>
                    <span>Flight No.</span>
                    <span>{{flightTwoLegs.flightNo}}</span>
                </div>
                <div>
                    <span>Booking Reference Number</span>
                    <span>{{flightTwoLegs?.bookingId}}&nbsp;</span>
                </div>
                <!-- <div>
                    <span>Airline Reference Number</span>
                    <span>{{legs[0]?.airlineReferenceNumber}}&nbsp;</span>
                </div> -->
                <div>
                    <span>E-Ticket Number</span>
                    <span>{{flightTwoLegs?.ticketNumber}}&nbsp;</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="loading" *ngIf="showGlobalSpinner">
    <mat-spinner></mat-spinner>
</div>
