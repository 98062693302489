import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { NotificationHistoryResponse } from './notification-history/interface/notification-history.interface';
import { NotificationsComponent } from './notifications.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationGuard implements CanDeactivate<NotificationsComponent>{

  constructor() { }

  canDeactivate(component: NotificationsComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean {
    let inEditMode = false;
    component?.notificationHistory?.filteredNotificationsResponse?.forEach((value: NotificationHistoryResponse) => {
      if (value?.editMode) {
        inEditMode = true;
      }
    });
    if( component?.isSelectCategory || component?.isCategorySelected || inEditMode) {
      const nextUrl = nextState?.url;
      component?.confirmCancel(nextUrl);
      return false;
    }
    return true;
  }
}
