import { Injectable } from "@angular/core";
import { API } from "aws-amplify";
import { Observable, of, throwError, from } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { IDownloadExcel, IParticipants, ISessionsMetaData } from "../interface/sessions.interface";

API.configure({
    endpoints: [
        {
            name: "portal",
            endpoint: environment.aws.apiEndpoint
        }
    ]
});

@Injectable()
export class SessionsService {
    
    public getSessionMetadata(programId: string, sessionId: string): Observable<ISessionsMetaData> {
        return from(API.get('portal', `programs/${programId}/sessions/${sessionId}`, {})).pipe(
            catchError(() => of([])),
            map((data: ISessionsMetaData) => {
                return data as ISessionsMetaData;
            })
        );
    }

    public getSessionParticipants(programId: string, sessionId: string): Observable<IParticipants> {
        return from(API.get('portal', `programs/${programId}/sessions/${sessionId}/participants`, {})).pipe(
            catchError((error) => throwError(error)),
            map((data: IParticipants) => {
                return data as IParticipants;
            })
        );
    }
  
    public downloadSessionsReport(programId: string, sessionId: string): Observable<IDownloadExcel> {
        return from(API.get('portal', `programs/${programId}/sessions/${sessionId}/download` , {})).pipe(
            catchError(() => of({data: ''})),
            map((data: IDownloadExcel) => {
                return data as IDownloadExcel;
            })
        );
    } 
} 