import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication/authentication.service';
import { API } from 'aws-amplify';
import { environment } from '../../../../environments/environment';
import { ICommunications } from '../../../models/ICommunications';

API.configure({
    endpoints: [
        {
            name: "portal",
            endpoint: environment.aws.apiEndpoint
        }
    ]
});

@Injectable({
    providedIn: 'root'
})
export class CommunicationsService {
    stayData: any;
    constructor(private auth: AuthenticationService) { }

    getCommunicationstest(): Observable<ICommunications> {
        return from(API.get('portal', 'persons/communications/', {})).pipe(
            map((data: ICommunications) => {
                return data as ICommunications;
            })
        )

    }
}
