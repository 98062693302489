import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction, Observable, pipe, throwError, of, from } from 'rxjs';
import { catchError, delay, map, mergeMap, retryWhen, timeout } from 'rxjs/operators';
import { API, Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';

API.configure({
    endpoints: [
        {
            name: "portal",
            endpoint: environment.aws.apiEndpoint
        }
    ]
});

@Injectable({
    providedIn: 'root'
})
export class ApiConfigurationsService {

    private userName: string = '';

    constructor() {
        Auth.currentAuthenticatedUser().then((user) => {
            this.userName = `${user?.username?.split('@')[0]}_` || '';
        });
    }

    public generateRequestResponsePayload(api: IAPI_CONFIGURATIONS, queueId: string): IAPI_INFO {
        return {
            path: api.PATH,
            method: api.METHOD,
            uniqueIdentifier:queueId
        };
    }

    public reTry<T>(api: IRequest): MonoTypeOperatorFunction<T>  {
        return pipe(
            retryWhen(err => {
                let retries = 0;
                return err
                    .pipe(
                        map(error => {
                            if (retries++ === api.apiConfigurations.RETRY_COUNT) {
                                throw error;
                              }
                            return error;
                        }),
                        delay(api.apiConfigurations.RETRY_DELAY)
                    );
              })
        );
    }

    public get<T>(api: IRequest): Observable<T> {
        api.apiConfigurations.METHOD = 'GET';
        let requestPath = API.get('portal', `${api?.apiConfigurations?.PATH}`, {});
        return from(requestPath)
        .pipe(
            timeout(API_CONFIGURATIONS.LAMBDA_TIMEOUT),
            catchError((error) => {
                if (error?.name?.toLowerCase() === 'timeouterror') {
                    return this.postAndGetFromQueue(api);
                }
                return throwError(error);
            }));
    }

    public post<T>(api: IRequest): Observable<T> {
        api.apiConfigurations.METHOD = 'POST';
        let requestPath = API.get('portal', `${api?.apiConfigurations?.PATH}`, { body: api?.payload });
        return from(requestPath)
        .pipe(
            timeout(API_CONFIGURATIONS.LAMBDA_TIMEOUT),
            catchError((error) => {
                if (error?.name?.toLowerCase() === 'timeouterror') {
                    return this.postAndGetFromQueue(api);
                }
                return throwError(error);
            }));
    }

    public postAndGetFromQueue<T>(api: IRequest): Observable<T> {
        let requestPath = API.get('portal', `request/${api?.apiConfigurations?.PATH}`, {});
        if (api?.apiConfigurations?.METHOD === 'POST') {
            requestPath = API.post('portal', `request/${api?.apiConfigurations?.PATH}`, { body: api?.payload });
        }
        return from(requestPath).pipe(
            catchError(() => {
                requestPath = API.get('portal', `${api?.apiConfigurations?.PATH}`, {});
                return from(requestPath);
            }),
            delay(api.apiConfigurations.RETRY_DELAY),
            mergeMap((response: any) => {
                if (!response?.queueId) {
                    return of(response);
                }
                const body: IAPI_INFO = this.generateRequestResponsePayload(api.apiConfigurations, response?.queueId);
                return from(API.post('portal', 'response/response',  { body })).pipe(
                    catchError((error) => {
                        return throwError(error);
                    }),
                    map((data: T) => {
                        return data;
                    })
                );
            })
            ).pipe(this.reTry(api));
    }

    public getConfigurations(api: string): IAPI_CONFIGURATIONS {
        return JSON.parse(JSON.stringify(API_CONFIGURATIONS[api]));
    }
}

export interface IAPI_CONFIGURATIONS {
    RETRY_COUNT: number;
    RETRY_DELAY: number;
    PATH: string;
    METHOD: string;
}

export interface IAPI_INFO {
    path: string;
    method: string;
    uniqueIdentifier: string;
}

export interface IRequest {
    payload?: any;
    apiConfigurations: IAPI_CONFIGURATIONS;
}

export const API_CONFIGURATIONS = {
    LAMBDA_TIMEOUT: 29000,
    GET_PROGRAMS: {
        RETRY_COUNT: 30,
        RETRY_DELAY: 2000,
        PATH: 'programs',
        METHOD: 'GET'
    },
    GET_CLASS_ROASTER_PARTICIPANTS: {
        RETRY_COUNT: 30,
        RETRY_DELAY: 2000,
        PATH: 'programs/${programId}/courses/${courseId}/participants',
        METHOD: 'GET'
    }
};
