import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { UtilsService } from 'src/app/services/shared/utils.service';
@Pipe({ name: 'classstatus' })
export class ClassStatusPipe implements PipeTransform {
    
    timeZone =  'America/New_York';
    curdate: any;
    
    constructor(private utilService: UtilsService) {
        this.curdate = this.utilService.changeTimeZone(new Date(), this.timeZone);
    }

    format: string;
    minsValue: number;
    transform(value: any, enddate?: any): any {
        if (value != undefined) {
            if ((moment(this.curdate).format('YYYY-MM-DD HH:mm')) > (moment(enddate).format('YYYY-MM-DD HH:mm')))
                value = 'COMPLETE';
            else if (((moment(this.curdate).format('YYYY-MM-DD HH:mm')) > (moment(value).format('YYYY-MM-DD HH:mm'))) && ((moment(this.curdate).format('YYYY-MM-DD HH:mm')) < (moment(enddate).format('YYYY-MM-DD HH:mm')))) {
                // var cValue = new Date(value.replace(" ", "T"));
                // this.minsValue = Math.abs(+cValue - +new Date()) / 1000;
                // var hours = Math.floor(this.minsValue / 3600);
                // if (hours > 1)
                //     return value = 'IN ' + hours + ' Hours ';
                // var minutes = Math.floor(this.minsValue / 60);
                // if (minutes > 1)
                //     return value = 'IN ' + minutes + ' MINS ';
                return value = 'IN PROGRESS';
            }
            else {
               // var endDateTime = new Date(enddate.replace(" ", "T"));
               var startDateTime = new Date(value.replace(" ", "T"));
                var delta = Math.abs(+startDateTime - +this.curdate) / 1000;
                var hours = Math.floor(delta / 3600);
                if (hours > 1)
                    return value = hours + ' Hours ';

                var minutes = Math.floor(delta / 60);
                if (minutes > 1)
                    return value = minutes + ' MINS ';
            }

            return value;
        }
    }
}