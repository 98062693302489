import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { UtilsService } from 'src/app/services/shared/utils.service';
@Pipe({ name: 'status' })
export class StatusPipe implements PipeTransform {
    constructor(private utilService: UtilsService) {}
    transform(endDate: any): any {
        if (endDate && moment(endDate).isSameOrAfter(moment(this.utilService.changeTimeZone(new Date(), 'America/New_York')))) {
            return true;
        }
        return false;
    }
}