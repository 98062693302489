import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, Subscription } from 'rxjs';
import { from } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { Router, NavigationEnd, NavigationSkipped } from '@angular/router';
import { Amplify } from 'aws-amplify';
import { Auth, Hub, API } from 'aws-amplify';
import { IUser } from '../../models/IUser';
import { UserActions } from 'src/app/store/user.actions';
import { Store } from '@ngxs/store';
const axios = require('axios').default;

// Amplify.Logger.LOG_LEVEL = 'DEBUG';

Amplify.configure({
    Auth: {
        identityPoolId: environment.aws.identityPoolId,
        region: environment.aws.region,
        userPoolId: environment.aws.userPoolId,
        userPoolWebClientId: environment.aws.userPoolWebClientId,
        oauth: {
            domain: environment.aws.oauth.domain,
            scope: ['profile', 'email', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: `${environment.aws.oauth.redirectUrl}`,
            redirectSignOut: `${environment.aws.oauth.redirectLogoutUrl}`,
            responseType: 'code',
        }
    }
});

API.configure({
    endpoints: [
        {
            name: "portal",
            endpoint: environment.aws.apiEndpoint
        }
    ]
});

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {
    private login: boolean = false;
    private roles: Array<string> = [];
    paramObsRoute: Subscription;

    constructor(private route: Router, private zone: NgZone, private store: Store) {
        this.start();
        this.requestInterceptors();
        if (window.location.href == `${environment.aws.oauth.redirectUrl}/` 
             && !window?.location?.href.split('code=')[1]
             && !localStorage.hasOwnProperty(`CognitoIdentityServiceProvider.${environment.aws.userPoolWebClientId}.LastAuthUser`)) {
             this.socialLogin('datapower');
        }
        Hub.listen('auth', (data) => {
            switch (data.payload.event) {
                case 'signIn':
                    this.login = true;
                    this.handleUserRedirect();
                    break;
                case 'signOut':
                    this.login = false;
                    this.roles = [];
                    this.route.navigate(['logout']);
                    break;
                case 'signIn_failure':
                    this.login = false;
                    this.roles = [];
                    this.route.navigate(['error/401']);
                    break;
            }
        });
        this.handleUserRedirect();
    }

    public socialLogin(provider: string): void {
        Auth.federatedSignIn(<any>{
            provider
        })
            .catch((error) => {
                this.signOut();
            });
    }

    handleUserAuth(): void {
        if (this.login) {
            this.handleUserRedirect();
        }
    }

    getUserRoles(): Array<String> {
        return this.roles;
    }

    getUserEmail(): Observable<String> {
        return from(
            Auth.currentAuthenticatedUser().then(
                user => {
                    return (user.signInUserSession) ? user.signInUserSession.idToken.payload["email"] : user.email;
                }
            )
        );
    }

    private setUserState(): void {
        this.getUser().pipe(take(1)).subscribe(user => {
            this.store.dispatch(new UserActions.SetUser(user));
        });
    }

    getUser(): Observable<IUser> {
        return this.getUserEmail().pipe(
            switchMap((email) => {
                const payload = {
                    email
                };
                return from(API.post('portal', 'persons', { body: payload }));
            })
        );
    }

    signOut() {
        Auth.signOut();
    }

    private handleUserRedirect(): void {
        Auth.currentAuthenticatedUser().then(
            user => {
                this.setUserState();
                if (user.signInUserSession) {
                    const idpRole = user.signInUserSession?.idToken?.payload["custom:Role"];
                    const cognitoGroup = user.signInUserSession?.idToken?.payload["cognito:groups"];

                    this.roles = idpRole ? idpRole : cognitoGroup;
                } else {
                    this.roles = [localStorage.getItem("role")];
                }
                // aws-amplify handles this auth promise outside of the angular zone
                // which prevents the router-outlet from rendering the component we
                // want to navigate to. So we explicitly reenter the angular zone
                this.routeObs();
                let routeNav = sessionStorage?.getItem('previousUrl')?.substring(1);

                this.zone.run(() => {
                    if (routeNav) {
                        this.route.navigate([`${decodeURIComponent(routeNav)}`]);
                    }
                    else {
                        if (this.roles.includes('participant')) {
                            this.route.navigate(['journey/home']);
                        } else if (this.roles.includes('facilitator')) {
                            this.route.navigate(['admin/programs-external']);
                        } else if (this.roles.includes('flightReport')) {
                            this.route.navigate(['admin/reports/arrival']);
                        } else if (this.roles.includes('admin')) {
                            this.route.navigate(['admin']);
                        } else {
                            this.route.navigate(['error']);
                        }
                    }
                });
            }
        )
            .catch((error) => {
                console.log('Something went wrong during handleUserRedirect', JSON.stringify(error));
            });
    }

    // private handleUserLogin() {
    //     const resp = window.location.search.split('SAMLResponse=')[1];
    //     if (resp != undefined) {
    //         const token = resp.replace(/%2B/g, '+').replace(/%3D/g, '');
    //         const parser = new DOMParser();
    //         const xmlDoc = parser.parseFromString(atob(token), "text/xml");
    //         const domain = environment.aws.samlProviderId;
    //         const expiresAt = 3600 * 1000 + new Date().getTime(); // 1 hour expire time for session
    //         // localStorage.setItem("expiresAt", expiresAt.toString());
    //         const user = {
    //             name: xmlDoc.querySelector("NameID").textContent,
    //             email: xmlDoc.querySelector("Attribute[Name='emailAddress'] > AttributeValue").textContent
    //         };
    //         localStorage.setItem("role", xmlDoc.querySelector("Attribute[Name='Role'] > AttributeValue").textContent);

    //         Auth.federatedSignIn(domain, { token, "expires_at": expiresAt }, user)
    //             .catch((error) => {
    //                 console.log('Something went wrong while SSO login. Signing out!', JSON.stringify(error));
    //                 this.signOut();
    //             });
    //     } else {
    //         this.route.navigate(['login']);
    //     }
    // }

    private requestInterceptors() {
        axios.interceptors.request.use(function (config) {
            if (!config?.headers?.Authorization || !config?.headers['X-Amz-Security-Token']) {
                console.log('Token does not exist. Logging out');
                Auth.signOut();
                this.route.navigate(['logout']);
            }
            return config;
        }, function (error) {
            // Do something with request error
            return Promise.reject(error);
        });
    }

    // Sign Out all tabs/windows when logged out of any tab.
    public signIn = () => {
        this.login = true;
        this.route.navigate([''])
    }

    public logOut = () => {
        this.login = false;
        this.route.navigate(['logout']);
    }

    private start(): void {
        window.addEventListener("storage", this.storageEventListener.bind(this));
    }

    private storageEventListener(event: StorageEvent) {
        if (event.storageArea == localStorage) {
            if (event?.key && event.key == 'logout-event') {
                this.logOut()
            }
        }
    }

    private stop(): void {
        window.removeEventListener("storage", this.storageEventListener.bind(this));
    }

    public routeObs() {
        this.paramObsRoute = this.route.events.subscribe((event) => {
            if ((event instanceof NavigationEnd || event instanceof NavigationSkipped)
                && event.url !== '/logout' && !event.url.includes('/error')) {
                window.sessionStorage.setItem('previousUrl', event.url);
            }
        });
    }

    ngOnDestroy() {
        this.stop()
        this.paramObsRoute.unsubscribe();
    }
}
