import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { interval, Subscription } from 'rxjs';
import { IProgram } from '../../../models/IProgram';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ZoneService } from '../../../services/zone/zone.service';
import { Select, Store } from '@ngxs/store';
import { ProgramActions } from '../programs/store/program.action';
import { ProgramState } from '../programs/store/program.state';
import { Router} from '@angular/router';
import { IUser } from 'src/app/models/IUser';
import { UserState } from 'src/app/store/user.state';
import { IErrorMessage } from 'src/app/widgets/error-message/error-message.interface';
import { API_USER_DEFINED_MESSAGE } from 'src/app/widgets/error-message/error-message.const';
import { FILTER_TYPE, IFilterRequest, IFilterResponse } from 'src/app/shared/filter/filter.interface';
import { ProgramService } from 'src/app/services/aws/program/program.service';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styles: []
})
export class DashboardComponent implements OnInit, OnDestroy {
    _programs:  IProgram[] = [];
    currDate: any;
    currDateSubscription: Subscription;
    format: string;
    dayWrapper: any;
    programs: IProgram[];
    isDesc: boolean = true;
    column: string = 'startDate';
    direction: number;
    isNameAsc: boolean = false;
    isDateAsc: boolean = false;
    programTypeAsc: boolean = false;
    showLoading: boolean = false;
    @Select(UserState.user) user$: Observable<IUser>;
    zones: any;
    role: string;
    filterInput: IFilterRequest;
    isFilterRes: boolean = false;
    public errorMessage: IErrorMessage = {};
    filterRes: string =API_USER_DEFINED_MESSAGE.EMPTY_FILTER_RESPONSE;

    public showFilter: boolean = false;

    constructor(private store: Store, private zoneService: ZoneService,private router: Router, private programservice: ProgramService) {
        this.currDate = moment();
        this.currDateSubscription = interval(10000).subscribe(() => {
            this.currDate = moment();
        });
    }

    ngOnInit() {
        this.filterInput = {};
        this.errorMessage = {};
        this.role = (this.router.url === '/admin/programs-external' ? 'facilitator' : 'admin');
         this.store.dispatch(new ProgramActions.GetPrograms()).pipe(
            switchMap(() => this.store.select(ProgramState.getPrograms)),
            map((data: IProgram[]) => {                
                return data.filter(x => x.endDate && moment(x.endDate).isSameOrAfter(this.currDate, 'day'));
            }))
            .subscribe((data: IProgram[]) => {
                this.errorMessage.text = data?.length ? '' : API_USER_DEFINED_MESSAGE.EMPTY_RESPONSE;
                this._programs = data;
                const myCurrentDate = new Date();
                const myFutureDate = new Date();
                myCurrentDate.toUTCString();
                myFutureDate.toUTCString();
                myFutureDate.setHours(0, 0, 0, 0);
                myCurrentDate.setHours(0, 0, 0, 0);
                myCurrentDate.setDate(myCurrentDate.getDate());
                myFutureDate.setDate(myFutureDate.getDate() + 180);
                const defaultFilterInput = {
                    filterType: FILTER_TYPE.FROM,
                    startDate: myCurrentDate,
                    endDate: myFutureDate
                };
                this.programs = this.programservice.filterProgramsBySelectedDate(this.programs, this._programs, defaultFilterInput);
            },
            () => {
                this._programs = [];
                this.programs = [];
                this.errorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
            });
    }

    dateformat(param: string): string {
        this.dayWrapper = moment(this.currDate).format('llll');
        var splitted = this.dayWrapper.split(" ");

        if (param.toLowerCase() == "monthwithdate")
            return splitted[1] + ' ' + splitted[2].replace(/,/g, "");
        else if (param.toLowerCase() == 'day') {
            return splitted[0].replace(/,/g, "");
        }
        else if (param.toLowerCase() == "year")
            return splitted[3];
        else if (param.toLowerCase() == "time")
            return splitted[4];
        else if (param.toLowerCase() == "part")
            return splitted[5];
        else if (param.toLowerCase() == "standard")
            return this.getTimeZone();
        else
            return '';
    }

    getTimeZone(): string {
        var offset = new Date().getTimezoneOffset(),
            o = Math.abs(offset);
        var offsetValue = (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
        if (offsetValue != undefined && offsetValue != '') {
            this.zones = this.zoneService.getZones();
            if (this.zones) {
                var zone = this.zones.filter(x => x.Offset == offsetValue);
                if (zone.length > 0) return zone[0].Abbreviation;
            }

        }
        return '';
    }

    sort(property) {
        this.isDesc = !this.isDesc;
        this.column = property;
        this.direction = this.isDesc ? 1 : -1;
        switch (property) {
            case 'name':
                this.isNameAsc = !this.isNameAsc;
                break;
            case 'startDate':
                this.isDateAsc = !this.isDateAsc;
                break;
            case 'programType':
                this.programTypeAsc = !this.programTypeAsc;
                break;
            default:
        }
    }

    ngOnDestroy() {
        this.currDateSubscription.unsubscribe();
    }

    public appliedDateFilter(appliedFilter: IFilterResponse): void {
        this.filterInput = appliedFilter;
        this.programs = this.programservice.filterProgramsBySelectedDate(this.programs, this._programs, this.filterInput);
        this.isFilterRes = this.programs.length ? false : true;
    }

    public resetToDefault(): void {
        this.isFilterRes =false;
        const myCurrentDate = new Date();
        const myFutureDate = new Date();
        myCurrentDate.toUTCString();
        myFutureDate.toUTCString();
        myCurrentDate.setDate(myCurrentDate.getDate());
        myFutureDate.setDate(myFutureDate.getDate() + 180);
        myFutureDate.setHours(0, 0, 0, 0);
        myCurrentDate.setHours(0, 0, 0, 0);
        const defaultFilterInput = {
            filterType: FILTER_TYPE.FROM,
            startDate: myCurrentDate,
            endDate: myFutureDate
        };
        this.programs = this.programservice.filterProgramsBySelectedDate(this.programs, this._programs, defaultFilterInput);
    }
}
