import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Menu } from '../../models/Menu';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class NavMenuService {
    menuItems: Menu[];

    public disableDropdownMenu$: Subject<string> = new Subject();

    constructor(private auth: AuthenticationService) {
        this.prepareNavbar();
    }

    public isFacilitatorView(isFacilitator: boolean = false) {
        if(isFacilitator) {
            if (this.menuItems[1]?.link) {
                this.menuItems[1].link = '/admin/programs-external';
            }
            this.menuItems[0].disable = true;
            if (this.menuItems[2]) {
                this.menuItems[2].disable = true;
            }
            if (this.menuItems[3]) {
                this.menuItems[3].disable = true;
            }
        } else {
            if (this.menuItems[1]?.link) {
                this.menuItems[1].link = '/admin/programs';
            }
            this.menuItems[0].disable = false;
            if (this.menuItems[2]) {
                this.menuItems[2].disable = false;
            }
            if (this.menuItems[3]) {
                this.menuItems[3].disable = false;
            }
        }
    }

    private prepareNavbar() {
        let roles = this.auth.getUserRoles();

        if (roles.includes('facilitator')) {
            this.menuItems = [
                new Menu('', 'programs-nav', 'Programs', '/admin/programs-external', false)
            ];
        } else if (roles.includes('flightReport')) {
            this.menuItems = [
                new Menu('', 'reporting-nav', 'Reporting', '', true)
            ];
        } else {
            // If we change the order, make sure to update function isFacilitatorView()
            this.menuItems = [
                new Menu('', 'dashboard-nav', 'Dashboard', '/admin/dashboard', false),
               // new Menu('', 'programs-nav', 'Programs', '', true),
               new Menu('', 'programs-nav', 'Programs', '/admin/programs', false),
                // new Menu('', 'group', 'Participants', '/admin/participants', false),
                new Menu('', 'reporting-nav', 'Reporting', '', true),
                // new Menu('', 'settings-nav', 'Settings', '', true)
                new Menu('','settings-nav','Settings','',true),
            ];
        }
    }
}
