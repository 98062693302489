import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { map, Observable, take } from 'rxjs';
import { AppVersionService } from 'src/app/services/aws/app-version/app-version.service';
import { IAppVersion } from '../../../models/IAppVersion';
import { IErrorMessage } from 'src/app/widgets/error-message/error-message.interface';
import { API_USER_DEFINED_MESSAGE } from 'src/app/widgets/error-message/error-message.const';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { canComponentExit } from 'src/app/services/guard/un-saved-changes.guard';
import { ConfirmDialogComponent } from 'src/app/widgets/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NavMenuService } from 'src/app/services/nav-menu/nav-menu.service';

@Component({
  selector: 'mobile-version.component',
  templateUrl: './mobile-version.component.html',
  styleUrls: ['./mobile-version.component.scss']
})
export class MobileVersionComponent implements OnInit, canComponentExit {
  public showGlobalSpinner: boolean = false;
  mobileVersionForm: FormGroup;
  public errorMessage: IErrorMessage = {};
  appPlatforms: IAppVersion[];
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  existingVersion: string;
  selectedPlatform: string | null = null;

  breadcrumbOptions = {
    breadcrumb: [
      {
        label: 'Dashboard',
        itemclass: 'Breadcrumb-item',
        linkclass: 'Breadcrumb-link',
        link: '/admin/dashboard'
      },
      {
        label: 'System Settings',
        itemclass: 'Breadcrumb-item',
        linkclass: 'Breadcrumb-link',
        link: '/admin/settings/systemSettings'
      }
    ],
  };


  constructor(private fb: FormBuilder, private appVersion: AppVersionService, private snackBar: MatSnackBar, private dialog: MatDialog,
    private navMenuService: NavMenuService
  ) {

  }

  ngOnInit(): void {
    this.getAppVersions();
    this.mobileVersionForm = this.fb.group({
      platform: ['', Validators.required],
      currentVersion: ['', [Validators.required, Validators.pattern(/^\d+\.\d+\.\d+$/)]],
      forceUpgrade: [false]

    });
  }

  canLeaveComponent(nextState: string): boolean | Observable<boolean> {
    if (this.mobileVersionForm.dirty) {
      return this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: "",
          body: 'Are you sure you want to exit and lose changes?',
          buttonTextNo: "Keep Editing",
          buttonTextYes: "Exit"
        },
        panelClass: 'notification-submit-dialog'
      }).afterClosed()
        .pipe(map(choice => {
          if (choice) {
            return true;
          }
          else if (!choice && nextState === '/admin/programs-external') {
            this.navMenuService.isFacilitatorView();
            this.navMenuService.disableDropdownMenu$.next('admin');
          }
          return false;
        }));
    }
    else {
      return true;
    }
  };

  getAppVersions() {
    this.showGlobalSpinner = true;
    this.appVersion.getAppVsersin().pipe(
      map((data: IAppVersion[]) => data))
      .subscribe((response: IAppVersion[]) => {
        this.appPlatforms = response;
        this.showGlobalSpinner = false;
      },
        () => {
          this.appPlatforms = [];
          this.showGlobalSpinner = false;
          this.errorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
          this.snackBar.open('Something went wrong, please try again later.', 'OK', {
            duration: 8000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['error-snackbar']
          });
        });
  }

  private updateAppVersion(formData: any) {

    this.showGlobalSpinner = true;
    this.appVersion.updateAppVsersin(formData)
      .pipe(take(1))
      .subscribe(() => {
        this.showGlobalSpinner = false;
        this.getAppVersions();
        this.snackBar.open('App Version Updated successfully.', 'OK', {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          panelClass: ['success-snackbar']
        });
      },
        () => {
          this.showGlobalSpinner = false;
          this.snackBar.open('Something went wrong, please try again later.', 'OK', {
            duration: 8000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['error-snackbar']
          });
        });

  }

  platformCode(postFormData: { platform: string; currentVersion: string; forceUpgrade?: boolean }
    = { platform: '', currentVersion: '', forceUpgrade: false }
  ): any {
    const { platform, currentVersion, forceUpgrade = false } = postFormData;
    postFormData?.platform?.toUpperCase();

    return {
      platformId: { "IOS MANAGED": 1, "IOS UNMANAGED": 2, "ANDROID": 3 }[platform] || 0,
      currentVersion,
      forceUpgrade: forceUpgrade ?? false
    };
  }


  onSubmit() {
    if (this.mobileVersionForm.valid) {
      const formData = this.mobileVersionForm.value;
      const postFormData = this.platformCode(formData);
      this.updateAppVersion(postFormData);

      this.existingVersion = '';
      window.location.reload();

    }

  }


  onPlatformChange(selectedPlatformName: string): void {
    const selectedPlatform = this.appPlatforms.find(platform => platform.platformName === selectedPlatformName);
    if (selectedPlatform) {
      this.existingVersion = selectedPlatform.currentVersion;
    }
  }

}