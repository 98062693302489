import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HouseAndWellBeingEventsComponent } from '../house-and-well-being-events.component';

@Injectable({
  providedIn: 'root'
})
export class HouseAndWellBeingGaurdService implements CanDeactivate<HouseAndWellBeingEventsComponent>{

  canDeactivate(component: HouseAndWellBeingEventsComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot,  nextState?: RouterStateSnapshot):  Observable<boolean> | Promise<boolean> | boolean {
      return component.canDeactivate(nextState?.url);
    }
}
