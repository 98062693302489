import { Injectable } from '@angular/core';
import { Observable, throwError, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { API } from 'aws-amplify';
import { IAppVersion } from 'src/app/models/IAppVersion';

API.configure({
    endpoints: [
        {
            name: "portal",
            endpoint: environment.aws.apiEndpoint
        }
    ]
});

@Injectable({
    providedIn: 'root'
})
export class AppVersionService {

    constructor() { }

    public getAppVsersin(): Observable<IAppVersion[]> {
        return from(API.get('portal', 'appVersion', {})).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map((data: IAppVersion[]) => {
                return data as IAppVersion[];
            })
        );
    }


    public updateAppVsersin(data: IAppVersion): Observable<IAppVersion> {
        return from(API.put('portal', 'appVersion', { body: data })).pipe(
            catchError((error) => {
                return throwError(error);
            }),
            map((res: string) => {
                return res as any;
            })
        )
    }
}