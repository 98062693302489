import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { IProgram } from 'src/app/models/IProgram';
import { IErrorMessage } from 'src/app/widgets/error-message/error-message.interface';
import { API_USER_DEFINED_MESSAGE } from 'src/app/widgets/error-message/error-message.const';
import { ActivatedRoute } from '@angular/router';
import { ProgramActions } from '../store/program.action';
import { ProgramService } from '../../../../services/aws/program/program.service';

@Component({
    selector: 'program-overview',
    templateUrl: './overview.component.html',
    styles: []
})

export class OverviewComponent implements OnInit {

    @Input()
    parentSpinner: boolean;

    programId: any;
    count: number;
    program: IProgram;
    events$: Observable<any>;
    public programList: any[];
    classCounts: Observable<any>;
    daysCounts$: Observable<any>;
    showLoading: boolean = false;
    column: string = 'key';
    direction: number =- 1;
    private dateFormat: string = 'YYYY-MM-DD';
    private timeFormat: string = 'hh:mm A';

    public programOverviewErrorMessage: IErrorMessage = {};

    constructor(private store: Store, private route: ActivatedRoute, private programService: ProgramService) {
     }
     

    ngOnInit() {
        this.route.params.pipe(take(1)).subscribe(params => {
            this.getProgramOverview(params["id"]);
            this.getProgramEvents(params['id']);
        });
    }

    
    public getProgramOverview(id: string): void {
        this.showLoading = this.parentSpinner;
        this.programService.get(id)
        .subscribe((progrms) => {
            this.program = progrms;
            this.showLoading =false;
        },
        () => {
            this.program = <IProgram>{};
            this.showLoading =false;
            this.programOverviewErrorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
        });
    }

    public getProgramEvents(id: string): void {
        this.showLoading = this.parentSpinner;
        combineLatest([this.getClasses(id),  this.getSessions(id)])
        .pipe(take(1))
        .subscribe((response) => {
            this.showLoading =false;
            const classes = response[0]?.programs?.classes?.length ?  response[0]?.programs?.classes :  response[1]?.programs?.classes || [];
            const sessions = response[0]?.programs?.sessions?.length ?  response[0]?.programs?.sessions :  response[1]?.programs?.sessions || [];
            // Make deep copy so we don't mutate state
            let classesCopy = JSON.parse(JSON.stringify(classes));
            let sessionsCopy = JSON.parse(JSON.stringify(sessions));
            let events = [];
            events = events.concat(classesCopy.map((x: any) => {
                const startDate = moment(x.startDate);
                x.type = "import_contacts";
                x.sDate = startDate.format(this.dateFormat);
                x.startTime = startDate.format(this.timeFormat);
                x.endTime = moment(x.endDate).format(this.timeFormat);
                return x;
            }));
            events = events.concat(sessionsCopy.map((x: any) => {
                const startDate = moment(x.startDate);
                x.type = "local_play";
                x.sDate = startDate.format(this.dateFormat);
                x.startTime = startDate.format(this.timeFormat);
                x.endTime = moment(x.endDate).format(this.timeFormat);
                return x;
            }));
            const eventObj = events.reduce((r, a) => {
                r[a.sDate] = r[a.sDate] || [];
                r[a.sDate].push(a);
                return r;
            }, Object.create(null));
            const eventMap = new Map(Object.entries(eventObj));
            this.programList = Array.from(eventMap, item => {
                return { key: item[0], value: item[1] };
            });
        },
        () => {
            this.showLoading =false;
            this.programOverviewErrorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
        });
    }

    private getSessions(id): Observable<any> {
        return this.store.dispatch(new ProgramActions.GetSessionsByProgramId(id)).pipe(
            catchError(() => {
                this.programOverviewErrorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
                return of([]);
            })
        );
    }

    private getClasses(id): Observable<any> {
        return this.store.dispatch(new ProgramActions.GetClassesByProgramId({programId: id})).pipe(
            catchError(() => {
                this.programOverviewErrorMessage.text = API_USER_DEFINED_MESSAGE.FAILED;
                return of([]);
            })
        );
    }

}
