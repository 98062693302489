<div *ngIf="programs; else loading">
    <div summary>
        <div class="overlay">
            <div user-caption>
                <!-- <span FL>Today in Lake Nona, FL</span> -->
                <span FL>TODAY AT LAKEHOUSE</span>
                <span>Welcome</span>
                <div user>{{(user$ | async)?.firstName}}</div>
            </div>
            <span spacer></span>
            <div class="card">
                <span><img src="assets/images/date-banner-white.svg"></span>
                <h4>{{dateformat('monthwithdate')}} </h4>
                <div>
                    {{dateformat('day')}}
                    <span>&#8226;</span> {{dateformat('year')}}
                </div>
            </div>
            <div class="card">
                <span><img src="assets/images/time-banner-white.svg"></span>

                <h4>{{dateformat('time')}}</h4>
                <div>{{dateformat('part')}}</div>
            </div>
        </div>
    </div>
    <div program-list>
        <div head class="dashboard-head">
            <div class="name">
                <span class="m-r-md"><img src="assets/icons/programs-title.svg" alt=""></span>
                <div>Upcoming Lakehouse Programs </div>
            </div>
            <div *ngIf="role === 'admin'">
                <app-filter [filterInput]="filterInput" (appliedDateFilter)="appliedDateFilter($event)"
                    [displayFilter]="showFilter" (clearFilter)="resetToDefault()"></app-filter>
            </div>
        </div>
        <div class="no-results-filter" *ngIf="isFilterRes; else displayTable">{{filterRes}}</div>
        <ng-template #displayTable>
            <table sticky scroll-directive class="table-fixedheader" *ngIf="programs?.length; else displayErrorMessage">
                <thead>
                    <th (click)="sort('name')" [ngClass]="role==='admin' ? 'width-20' : 'width-25' ">
                        <span>Name</span>
                        <img [ngClass]="{'active':isNameAsc}" src="assets/icons/sort-by-down.svg" alt="">
                        <!-- <i class="material-icons" [ngClass]="{'active':isNameAsc}"> arrow_drop_down</i> -->
                    </th>
                    <th (click)="sort('startDate')" [ngClass]="role==='admin' ? 'width-20' : 'width-25' ">
                        <span>Date</span>
                        <img [ngClass]="{'active':isDateAsc}" src="assets/icons/sort-by-down.svg" alt="">
                        <!-- <i class="material-icons" [ngClass]="{'active':isDateAsc}">arrow_drop_down</i> -->
                    </th>
                    <th (click)="sort('programType')" [ngClass]="role==='admin' ? 'width-20' : 'width-25' ">
                        <span>Program Type</span>
                        <img [ngClass]="{'active':programTypeAsc}" src="assets/icons/sort-by-down.svg" alt="">
                        <!-- <i class="material-icons" [ngClass]="{'active':isDateAsc}">arrow_drop_down</i> -->
                    </th>
                    <th [ngClass]="role==='admin' ? 'width-20' : 'width-25' ">
                        <span>{{(role==='admin') ? 'Sessions' : 'Events'}}</span>
                    </th>
                    <th>
                        <span>Participants</span>
                    </th>
                </thead>
            </table>
            <table>
                <tbody>
                    <tr *ngFor="let item of programs | orderBy: {property: column, direction: direction}">
                        <td [ngClass]="role==='admin' ? 'width-20' : 'width-25' ">
                            <div>
                                <span><a
                                        [routerLink]="'/admin/programs/'+ item.id + '/program-overview'">{{item?.name}}</a></span>
                            </div>
                        </td>
                        <td [ngClass]="role==='admin' ? 'width-20' : 'width-25' ">
                            <div>
                                <span>{{item.startDate | dateFormat : 'mmddyy'}} - {{item.endDate | dateFormat :
                                    'mmddyy'}}</span>
                                <span>{{item.startDate | getdays : item.endDate}} days </span>
                            </div>
                        </td>
                        <td [ngClass]="role==='admin' ? 'width-20' : 'width-25' ">
                            <div>
                                <span>{{ item?.programType }}</span>
                            </div>
                        </td>
                        <td [ngClass]="role==='admin' ? 'width-20' : 'width-25' ">
                            <div>
                                <div>
                                    <span>{{(item.counts)?.classes || 0}}</span>
                                    <span>Classes</span>
                                </div>
                                <div>
                                    <span>{{(item.counts)?.events || 0}}</span>
                                    <span>Sessions</span>
                                </div>
                                <!-- <div>
                                <span>{{(item.counts)?.activities || 0}}</span>
                                <span>Activities</span>
                            </div> -->
                            </div>
                        </td>
                        <td>
                            <div>
                                <div>
                                    <span>{{(item.counts)?.participants || 0}}</span>
                                    <span>Attendees</span>
                                </div>
                                <div>
                                    <span>{{(item.counts)?.facilitators || 0}}</span>
                                    <span>Instructors</span>
                                </div>
                                <!-- <div>
                                <span>{{(item.counts)?.speakers || 0}}</span>
                                <span>Speakers</span>
                            </div> -->
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
    </div>
    <ng-template #displayErrorMessage>
        <p class="background-white m-t-xs">
            <span class="m-l-lg">
                <error-message [errorMessage]="errorMessage"></error-message>
            </span>
        </p>
    </ng-template>
</div>
<ng-template #loading>
    <div class="loading" *ngIf="!showLoading">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>