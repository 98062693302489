import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/widgets/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'dailog',
    templateUrl: './class-detail-dialog.component.html',
    styles: []
})
export class ClassDetailDialogComponent implements OnInit {
    componentName: string;
    programId: number;
    classId: number;
    classDate: string;
    facilitatorAttendance: boolean;
    isEdited: boolean;
    constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) private data: any) {
    }

    ngOnInit() {
        this.componentName = this.data.componentname;
        this.programId = this.data.programId;
        this.classId = this.data.classId;
        this.facilitatorAttendance = this.data.facilitatorAttendance;
    }

    onIndexSelected(selectedIndex) {
        this.isEdited = selectedIndex;
    }

    dialogclose() {
        if (this.isEdited !== null && this.isEdited !== undefined)
        {
            this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: "",
                    body: "Are you sure you want to exit and lose changes?",
                    buttonTextNo: "Keep Editing",
                    buttonTextYes: "Exit"
                },
                panelClass: 'notification-cancel-dialog'
            }).afterClosed()
                .pipe(take(1))
                .subscribe(result => {
                    if (result) {
                        this.dialog.closeAll();
                    }
                });
        }
        else {
            this.dialog.closeAll();
        }
    }


}
