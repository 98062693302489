import { Directive, Input, EventEmitter, Output, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[scrollSpy]'
})
export class ScrollSpyDirective {
    @Input() public spiedTags = [];
    @Output() public sectionChange = new EventEmitter<string>();
    private currentSection: string;
    element: any;
    section5: any;
    constructor(private _el: ElementRef) { }
    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        let currentSection: string;
        const childrens = this._el.nativeElement.children;
        let scrollTop = event.target.scrollTop + 10;
        const parentOffset = event.target.offsetTop;
       
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            scrollTop = scrollTop + 270; 
            this.sectionChange.emit(childrens[childrens.length-1].id);
        }

        for (let i = 0; i < childrens.length; i++) {
        
            scrollTop = (i === childrens.length-1) ? scrollTop + 250 : scrollTop;
            const element = childrens[i];
           
            if (i == 2) {
              
                for (let j = 0; j < element.children.length; j++)
                {
                    this.element = childrens[i].children[j];
                    if (this.spiedTags.some(spiedTag => spiedTag === this.element.tagName)) {
                        if ((this.element.offsetTop - parentOffset) <= scrollTop) {
                            currentSection = this.element.id;
                        }
                    }
                }
            } else {

                this.element = childrens[i];
                if (this.spiedTags.some(spiedTag => spiedTag === this.element.tagName)) {
                    if ((this.element.offsetTop - parentOffset) <= scrollTop) {
                        currentSection = this.element.id;
                    }

                }
            }
           
        }
        if (currentSection !== this.currentSection) {
            this.currentSection = currentSection;
            this.sectionChange.emit(this.currentSection);
        }
    }
}