import { Injectable } from '@angular/core';
import { Dropdown } from '../../models/Dropdown';

@Injectable({
    providedIn: 'root'
})
export class ProgramDropdownService {
   dropdown: any;
    constructor()
    {
    }
    getDropdownList(name:string): any {
        if( name==='breadcrumb')
        {
             this.dropdown= [ 
                    new Dropdown("Innovative Creative Learning(11/04/2019 - 11/06/2019)","Innovative Creative Learning"),
                    new Dropdown("Business Entertprose Training(11/04/2019 - 11/06/2019)","Business Entertprose Training"),
                    new Dropdown("SAU Live(11/04/2019 - 11/06/2019)","SAU Live"),
                    new Dropdown("Partner Meeting(11/04/2019 - 11/06/2019)","Partner Meeting"),
            ];
        }
        else  if( name==='tab')
        {
             this.dropdown= [ 
                    new Dropdown("Program Details","Program Details"),
                    new Dropdown("User Access","User Access"),
                    new Dropdown("Content Management","Content Management"),
                    new Dropdown("Notification","Notification"),
                    new Dropdown("Settings","Settings"),
            ];
        }
        return this.dropdown;
    }
}
