export enum FILTER_TYPE {
    TODAY = 'TODAY',
    ON = 'ON',
    FROM = 'FROM',
    CREATED_BY = 'CREATED_BY'
}

export interface IFilterResponse {
    filterType?: FILTER_TYPE
    startDate?: Date
    endDate?: Date
    selectedAutoCompleteValue?: string;
}

export interface IFilterRequest extends IFilterResponse {
    minDate?: Date
    maxDate?: Date
}