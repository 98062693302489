import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ParticipantGuard } from '../../services/guard/participant.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WidgetsModule } from '../../widgets/widgets.module';
import { JourneyComponent } from './journey.component';
import { HomeComponent } from './home/home.component';
import { CoursesComponent } from './courses/courses.component';
import { FlightComponent } from './flight/flight.component';
import { StayComponent } from './stay/stay.component';
import {ScrollSpyDirective} from '../../widgets/scroll-spy/scroll-spy.directive';
import { NgxsModule } from '@ngxs/store';
import { JourneyState } from './journey.state';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ProgramsListComponent } from './programs-list/programs-list.component';

const routes: Routes = [
    {
        path: 'journey',
        canActivate: [ParticipantGuard],
        component: JourneyComponent,
        children: [
            {
                path: '',
                children: [
                    {
                        path: 'home',
                        component: HomeComponent
                    },
                    {
                        path: 'courses',
                        component: CoursesComponent
                    },
                    {
                        path: 'flight',
                        component: FlightComponent
                    },
                    {
                        path: 'stay',
                        component: StayComponent
                    },
                    {
                        path: '',
                        redirectTo: 'home',
                        pathMatch: 'full'
                    },
                ]
            }
        ]
    },
    {
        path: '',
        redirectTo: 'journey',
        pathMatch: 'full'
    }
];

@NgModule({
    declarations: [
        JourneyComponent,
        HomeComponent,
        CoursesComponent,
        FlightComponent,
        StayComponent,
        ScrollSpyDirective,
        HeaderComponent,
        FooterComponent,
        ProgramsListComponent
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        WidgetsModule,
        RouterModule.forChild(routes),
        NgxsModule.forFeature([JourneyState])
    ]
})
export class JourneyModule { }